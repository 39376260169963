// import { ReactComponent as DashBoardcon } from 'assets/icons/house-chimney-2.svg';
import { ReactComponent as RatioIcon } from 'assets/icons/performance-increase-2.svg';
import { ReactComponent as RatioNavIcon } from 'assets/icons/ratio-Icon.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/task-checklist.svg';
import { ReactComponent as PeersIcon } from 'assets/icons/multiple-circle.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow-button-left-1.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow-button-right-1.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/chevron-down-regular.svg';
import { ReactComponent as UpArrowIcon } from 'assets/icons/arrow-button-down-1.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/fa-print.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/file-pdf.svg';
import { ReactComponent as PowerpointIcon } from 'assets/icons/file-powerpoint.svg';
import { ReactComponent as CogIcon } from 'assets/icons/cog.svg';
import { ReactComponent as QuestionCircleIcon } from 'assets/icons/question-circle.svg';
import { ReactComponent as UserIcon } from 'assets/icons/single-neutral-circle.svg';
import { ReactComponent as ArrowdownSmallIcon } from 'assets/icons/arrow-down-1.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as UnPinIcon } from 'assets/icons/unpin_icon.svg';
import { ReactComponent as PinIcon } from 'assets/icons/pinned_icon.svg';
import { ReactComponent as UpIcon } from 'assets/icons/Arrow-up.svg';
import { ReactComponent as DownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as TaskTextIcon } from 'assets/icons/task-text.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { ReactComponent as BAMarketCircleActiveIcon } from 'assets/icons/Market.svg';
import { ReactComponent as BAFinanceCircleActiveIcon } from 'assets/icons/Financial.svg';
import { ReactComponent as BARiskCircleIcon } from 'assets/icons/Risk.svg';
import { ReactComponent as CUAnalystIcon } from 'assets/icons/CU_Analyst.svg';
import { ReactComponent as DarkIcon } from 'assets/icons/dark.svg';
import { ReactComponent as DottedIcon } from 'assets/icons/dotted.svg';
import { ReactComponent as EmptyCircleIcon } from 'assets/icons/empty-circle.svg';
import { ReactComponent as ExceptionalIcon } from 'assets/icons/exceptional.svg';
import { ReactComponent as NotClassifiedIcon } from 'assets/icons/not-classified.svg';
import { ReactComponent as StrongIcon } from 'assets/icons/strong.svg';
import { ReactComponent as HierarchyIcon } from 'assets/icons/hierarchy.svg';
import { ReactComponent as TrendArrowGreenIcon } from 'assets/icons/triangle.svg';
import { ReactComponent as TrendArrowRedIcon } from 'assets/icons/triangle-red.svg';
import { ReactComponent as TrendArrowOrangeIcon } from 'assets/icons/triangle-orange.svg';
import { ReactComponent as performanceDashIcon } from 'assets/icons/Pie-Line-Graph.svg';
import { ReactComponent as loginLogoIcon } from 'assets/icons/icon-external-app-fiserv.svg';
import { ReactComponent as loginImage } from 'assets/icons/login-cover.svg';
import { ReactComponent as AssetQualityIcon } from 'assets/icons/AssetQuality.svg';
import { ReactComponent as LiquidityRiskIcon } from 'assets/icons/LiquidityRisk.svg';
import { ReactComponent as CapitalManagementIcon } from 'assets/icons/CapitalManagement.svg';
import { ReactComponent as VelocityRiskIcon } from 'assets/icons/VelocityRisk.svg';
import { ReactComponent as EarningEnhancementPlansIcon } from 'assets/icons/EarningEnhancementPlans.svg';
import { ReactComponent as IncentiveCompensationIcon } from 'assets/icons/IncentiveCompensation.svg';
import { ReactComponent as PerformancePeerAnalysisIcon } from 'assets/icons/PerformancePeerAnalysis.svg';
import { ReactComponent as BudgetAndResourceAllocationsIcon } from 'assets/icons/BudgetAndResourceAllocations.svg';
import { ReactComponent as RetailAndBusinessSegmentationIcon } from 'assets/icons/RetailAndBusinessSegmentation.svg';
import { ReactComponent as CoreDepositStrategiesIcon } from 'assets/icons/CoreDepositStrategies.svg';
import { ReactComponent as BranchRationalizationIcon } from 'assets/icons/BranchRationalization.svg';
import { ReactComponent as NicheBankingStrategiesIcon } from 'assets/icons/NicheBankingStrategies.svg';
import { ReactComponent as ShareIcon } from "assets/icons/share_icon.svg";
import { ReactComponent as EnvelopeIcon } from "assets/icons/icon-envelope.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/icon-phone.svg";
import { ReactComponent as FAQIcon } from "assets/icons/faqs.svg";
import { ReactComponent as OwlIcon } from "assets/icons/icon-owl.svg";
import { ReactComponent as VideoTutorials } from "assets/icons/VideoTutorials.svg";
import { ReactComponent as WebConferencesIcon } from "assets/icons/WebConferences.svg";

const ICONS = {
    // Dashboard: DashBoardcon,
    Ratio: RatioIcon,
    Report: ReportIcon,
    Peers: PeersIcon,
    LeftArrow: LeftArrowIcon,
    RightArrow: RightArrowIcon,
    DownArrow: DownArrowIcon,
    UpArrow: UpArrowIcon,
    Download: DownloadIcon,
    Print: PrintIcon,
    Pdf: PdfIcon,
    Powerpoint: PowerpointIcon,
    Cog: CogIcon,
    QuestionCircle: QuestionCircleIcon,
    Info: InfoIcon,
    User: UserIcon,
    Search: SearchIcon,
    ArrowdownSmall: ArrowdownSmallIcon,
    Pin: PinIcon,
    UnPin: UnPinIcon,
    Up: UpIcon,
    Down: DownIcon,
    RatioNav: RatioNavIcon,
    Close: CloseIcon,
    Bin: BinIcon,
    TaskText: TaskTextIcon,
    Pencil: PencilIcon,
    BAMarketCircleActive: BAMarketCircleActiveIcon,
    BAFinanceCircleActive: BAFinanceCircleActiveIcon,
    BARiskCircle: BARiskCircleIcon,
    CUAnalyst: CUAnalystIcon,
    Dark: DarkIcon,
    Dotted: DottedIcon,
    EmptyCircle: EmptyCircleIcon,
    Exceptional: ExceptionalIcon,
    NotClassified: NotClassifiedIcon,
    Strong: StrongIcon,
    TreeView: HierarchyIcon,
    GreenTrend: TrendArrowGreenIcon,
    RedTrend: TrendArrowRedIcon,
    OrangeTrend: TrendArrowOrangeIcon,
    Dashboard: performanceDashIcon,
    LoginLogo: loginLogoIcon,
    LoginImage: loginImage,
    AssetQuality: AssetQualityIcon,
    LiquidityRisk: LiquidityRiskIcon,
    CapitalManagement: CapitalManagementIcon,
    VelocityRisk: VelocityRiskIcon,
    EarningEnhancementPlans: EarningEnhancementPlansIcon,
    IncentiveCompensation: IncentiveCompensationIcon,
    PerformancePeerAnalysis: PerformancePeerAnalysisIcon,
    BudgetAndResourceAllocations: BudgetAndResourceAllocationsIcon,
    RetailAndBusinessSegmentation: RetailAndBusinessSegmentationIcon,
    CoreDepositStrategies: CoreDepositStrategiesIcon,
    BranchRationalization: BranchRationalizationIcon,
    NicheBankingStrategies: NicheBankingStrategiesIcon,
    ShareIcon:ShareIcon,
    Envelope: EnvelopeIcon,
    Phone: PhoneIcon,
    FAQ: FAQIcon,
    Owl: OwlIcon,
    VideoTutorials: VideoTutorials,
    WebConferences: WebConferencesIcon
};

export default ICONS;
