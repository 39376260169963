import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { transformListTypes } from "routes/MarketDataReviewTable/marketDataReviewTableSlice";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "routes/MarketRoute/selectors";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import { transformShape } from "./utils/transformShape";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    filters: {
        branches: true,
        blockGroups: false,
        radiusDMA: false,
        competitors: false,
    },
    selected: {
        listType: 1,
    },
    listTypes: [],
    mapBranches: [],
    mapBlockGroupsZip: {},
    mapRadiusDMA: {},
    mapCompetitors: [],
    dataLayers: []
};

const marketDataReviewMapSlice = createSlice({
    name: "marketDataReviewMap",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setFilters(state, { payload }) {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.api]: payload.value,
                },
            };
        },
        setListTypes(state, { payload }) {
            return {
                ...state,
                listTypes: payload,
            };
        },
        setMapBranches(state, { payload }) {
            return {
                ...state,
                mapBranches: payload,
            };
        },
        setMapBlockGroupsZip(state, { payload }) {
            return {
                ...state,
                mapBlockGroupsZip: payload,
            };
        },
        setMapRadiusDMA(state, { payload }) {
            return {
                ...state,
                mapRadiusDMA: payload,
            };
        },
        setMapCompetitors(state, { payload }) {
            return {
                ...state,
                mapCompetitors: payload,
            };
        },
        setDataLayers(state, { payload }) {
            return {
                ...state,
                dataLayers: payload,
            };
        },
        resetMarketDataReviewMap() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setFilters,
    setListTypes,
    setMapBranches,
    setMapBlockGroupsZip,
    setMapRadiusDMA,
    setMapCompetitors,
    setDataLayers,
    resetMarketDataReviewMap,
} = marketDataReviewMapSlice.actions;

export function getMarketDataReviewMapBranches() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        await dispatch(setMapBranches([]));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            listType,
            ratioId,
        } = getMarketSelectedFilters(getState());

        const params = {
            dma: reportDMA,
            listType,
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}/map/branches`,
                { params }
            )
            .then(({ data }) => {
                const transformedData = _.map(data, (dataItem) => {
                    return _.assign(
                        {},
                        {
                            type: "Feature",
                            name: "branches",
                            properties: { ...dataItem },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    dataItem.latitude,
                                    dataItem.longitude,
                                ],
                            },
                        }
                    );
                });
                dispatch(setMapBranches([...transformedData]));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function getMarketDataReviewMapBlockGroupsZip() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        await dispatch(
            setMapBlockGroupsZip({
                blocks: [],
                zips: [],
            })
        );

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            ratioId,
            listType,
            reportDMA
        } = getMarketSelectedFilters(getState());

        const params = {
            dma:reportDMA,
            listType,
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}/map/blockGroupsAndZip`,
                { params }
            )
            .then(({ data }) => {
                const transformedBlocks = _.map(data, (dataItem) => {
                    return _.assign(
                        {},
                        {
                            type: "Feature",
                            name: "blockGroups",
                            properties: { ...dataItem },
                            geometry: { ...transformShape(dataItem.shape) },
                        }
                    );
                });

                const transformedZips = _.map(data, (dataItem) => {
                    return _.assign(
                        {},
                        {
                            type: "Feature",
                            name: "blockGroups",
                            properties: { ...dataItem },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    dataItem.latitude,
                                    dataItem.longitude,
                                ],
                            },
                        }
                    );
                });

                dispatch(
                    setMapBlockGroupsZip({
                        blocks: [...transformedBlocks],
                        zips: [...transformedZips],
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function getMarketDataReviewMapRadiusDMA() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        await dispatch(setMapRadiusDMA([]));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            ratioId,
            listType,
        } = getMarketSelectedFilters(getState());

        const params = {
            dma:reportDMA,
            listType,
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}/map/radiusAndDMA`,
                { params }
            )
            .then(({ data }) => {
                const transformedRadius = _.map(
                    _.filter(data, (item) => !!item.radius),
                    (dataItem) => {
                        if (dataItem.radius) {
                            return _.assign(
                                {},
                                {
                                    type: "Feature",
                                    name: "radiusDMA",
                                    properties: { ...dataItem },
                                    geometry: {
                                        type: "Point",
                                        coordinates: [
                                            dataItem.latitude,
                                            dataItem.longitude,
                                        ],
                                    },
                                }
                            );
                        }
                    }
                );

                const transformedBlocks = _.map(
                    _.filter(data, (item) => !!item.shape),
                    (dataItem) => {
                        if (dataItem.shape != null ) { 
                            let modificationCounter = 3;
                            if(dataItem.shape.includes('MULTIPOLYGON')){
                                modificationCounter = 2;
                            }
                                return _.assign(
                                    {},
                                    {
                                        type: "Feature",
                                        name: "radiusBlockGroups",
                                        properties: { ...dataItem },
                                        geometry: { ...transformShape(dataItem.shape.slice(0, dataItem.shape.length-modificationCounter) + "))") },
                                    }
                                );                            
                        }                    
                    }
                );
                
                dispatch(setMapRadiusDMA({
                    blocks: [...transformedBlocks],
                    radius: [...transformedRadius],
                }));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function getMarketDataReviewMapCompetitors() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        await dispatch(setMapCompetitors([]));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            ratioId,
            listType,
        } = getMarketSelectedFilters(getState());

        const params = {
            dma:reportDMA,
            listType,
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}/map/competitors`,
                { params }
            )
            .then(({ data }) => {
                const transformedData = _.map(data, (dataItem) => {
                    return _.assign(
                        {},
                        {
                            type: "Feature",
                            name: "competitors",
                            properties: { ...dataItem },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    dataItem.latitude,
                                    dataItem.longitude,
                                ],
                            },
                        }
                    );
                });
                dispatch(setMapCompetitors([...transformedData]));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function fetchMarketListTypes(selectedRatioId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        await dispatch(setListTypes([]));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            listType,
            ratioId,
            region,
        } = getMarketSelectedFilters(getState());

        const params = {
            dma: reportDMA,
            region,
            sortColumnNumber: 2,
            columnSortOrder: "ASCENDING",
        };

        var properRatioId = !selectedRatioId ? ratioId : selectedRatioId;
        if (!selectedRatioId) params.listType = listType;

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${properRatioId}`,
                { params }
            )
            .then(({ data }) => {
                dispatch(setListTypes([...transformListTypes(data.listTypes)]));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function fetchMarketReviewMapDataLayers(sortData) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            listType,
            ratioId,
            region,
            sort
        } = getMarketSelectedFilters(getState());

        const params = {
                    dma: reportDMA, 
                    region, 
                    listType 
                };

        if(sortData) { 
            params.sortColumnNumber = sort.sortColumnNumber; 
            params.columnSortOrder  = sort.columnSortOrder;
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}/map/dataLayers`,
                { params }
            )
            .then(({ data }) => {
                dispatch(setDataLayers([...data]));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export default marketDataReviewMapSlice.reducer;
