/* eslint-disable no-useless-escape */
import _ from "lodash";
import { shapes } from "constants/marketDataReviewMap";

export function transformShape(shapeStr) {
    let string = shapeStr.replace(/\(/g, "[");
    string = string.replace(/\)\s/g, "], ");
    string = string.replace(/\)/g, "]");
    string = string.replace(/\s+/, ", ");
    string = "[" + string + "]";

    // remove any \n characters
    // string = string.replace(/(\r\n|\n|\r)/gm, "");

    string = string.replace(/[^\[\]\,\s]+/g, '"$&"');
    string = string.replace(/" /g, '", ');
    string = string.replace(/"POLYGON"\[/g, '"POLYGON", [');

    string = JSON.parse(string);

    let coords = [];

    if (string[0] === "POLYGON") {
        const formatted = _.map(string[1][0], Number);
        coords.push(_.chunk(formatted, 2));
    }

    if (string[0] === "MULTIPOLYGON") {
        _.forEach(string[1], (item) => {
            const formatted = _.map(item[0], Number);
            coords.push([_.chunk(formatted, 2)]);
        });
        // This line assumed to be updating coords array from stratch that leads deleting previous polygon items calculated.
        // Commented out just in case if any unexpected error occurs related to this one, we can easyly identify the issue.
        // coords = [].concat(coords);
    }

    return {
        type: shapes[string[0].toLowerCase()],
        coordinates: coords,
    };
}
