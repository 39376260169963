/**
 * Utility to handle errors in redux axios calls
 * Displays toast for any error.
 */

import _ from "lodash";
import toast from "components/@bank-ui/core/Toast/Toast";

function isNetworkError(err) {
    return !!err.isAxiosError && !err.response;
  }

export function errorHandler({ status, message }) {
    toast.error({
        header: "Error",
        message: message,
    });
    return { status, message };
}

export function getErrorMessage(error) {

    const status = _.get(error, "response.status", 400);
    
    if(isNetworkError(error)){
        const networkErrorMessage = "Please check your network connectivity."
        return { status, message: networkErrorMessage}
    }

    const message = _.get(
        error,
        "response.data.message",
        _.get(error, "message", "")
    );
    
    // const path = _.get(error, "response.data.path", _.get(error, "path", ""));
        
    if(error.response && error.response.status === 500 && (typeof error.response.data === "string") && (error.response.data?.includes("ETIMEDOUT" || "ECONNRESET" || "ECONNABORTED" || "ECONNREFUSED" || "ERR_CONNECTION_RESET") || error.response.data?.includes("ECONNRESET"))) return {status, message: "Please check your network connectivity."}

    const errorMessage = error.response && error.response.status >= 500 ? 'There was an internal processing error. Please try again after sometime. If issue persists, contact support team at BI.Clientrelations@fiserv.com' : message;
    return { status, message: errorMessage };
}
