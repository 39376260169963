import _ from 'lodash';

export function refitKeys(originalObject, callback) {
  if (typeof originalObject !== 'object') {
    return originalObject;
  }

  return _.reduce(
    _.keys(originalObject || {}),
    (newObject, key) => {
      const newKey = callback(key);
      const originalValue = originalObject[key];
      let newValue = originalValue;
      if (Array.isArray(originalValue)) {
        newValue = _.map(originalValue, (item) => refitKeys(item, callback));
      } else if (originalValue && typeof originalValue === 'object') {
        newValue = refitKeys(originalValue, callback);
      }
      return {
        ...newObject,
        [newKey]: newValue
      };
    },
    {}
  );
}
