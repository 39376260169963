import { createSlice } from "@reduxjs/toolkit";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
    getMarketFilters,
} from "routes/MarketRoute/selectors";
import toast from "components/@bank-ui/core/Toast/Toast";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    marketTypes: [],
    budgetList: [],
    budget: {},
};

const marketBudgetSlice = createSlice({
    name: "marketBudget",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setMarketTypes(state, { payload }) {
            return {
                ...state,
                marketTypes: payload,
            };
        },
        setBudgetList(state, { payload }) {
            return {
                ...state,
                budgetList: payload.budgetList,
            };
        },
        setBudget(state, { payload }) {
            return {
                ...state,
                budget: payload,
            };
        },
        setBudgetData(state, { payload }) {
            return {
                ...state,
                budget: {
                    ...state.budget,
                    [payload.api]: payload.value,
                },
            };
        },
        setBudgetMemberData(state, { payload }) {
            return {
                ...state,
                budget: {
                    ...state.budget,
                    budgetMembers: state.budget.budgetMembers.map((member) => {
                        if (member.branchId === payload.branchId) {
                            return {
                                ...member,
                                [payload.api]: payload.value,
                                IsDirty: true,
                            };
                        }
                        return member;
                    }),
                },
            };
        },
    },
});

export const {
    setLoading,
    setMarketTypes,
    setBudgetList,
    setBudget,
    setBudgetData,
    setBudgetMemberData,
    setBudgetFocus,
} = marketBudgetSlice.actions;

function getLeafNodes(nodes, result = []) {
    for (var i = 0, length = nodes.length; i < length; i++) {
        if (!nodes[i].children || nodes[i].children.length === 0) {
            result.push(nodes[i]);
        } else {
            result = getLeafNodes(nodes[i].children, result);
        }
    }
    return result;
}

export function initializeNewBudget() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const { reportBranches } = getMarketFilters(getState());

        let branches = getLeafNodes(reportBranches);

        let budgetBranches = branches.map((branch) => {
            return {
                branchId: branch.id,
                branchName: branch.label,
                value: 0.0,
                adjustementFactor: 1.0,
            };
        });

        let budget = {
            description: "New Budget",
            growthGoal: 3,
            budgetType: "1213000",
            dma: "CUSTOM",
            shared: false,
            budgetMembers: budgetBranches,
        };

        dispatch(
            setBudget({
                ...budget,
            })
        );

        dispatch(setLoading(false));
    };
}

export function fetchMarketTypes() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, reportBranches } = getMarketSelectedFilters(
            getState()
        );

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/budgets/marketTypes`
            )
            .then(({ data }) => {
                const modData = data.map((dataItem) => {
                    return {
                        ...dataItem,
                        id: dataItem.marketTypeId,
                        label: dataItem.name,
                        value: dataItem.marketTypeId,
                    };
                });

                dispatch(
                    setMarketTypes(modData)
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function fetchBudgetList() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, reportBranches } = getMarketSelectedFilters(
            getState()
        );

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/budgets`
            )
            .then(({ data }) => {
                dispatch(
                    setBudgetList({
                        budgetList: data,
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function fetchBudget(budgetId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, reportBranches } = getMarketSelectedFilters(
            getState()
        );

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/budgets/${budgetId}`
            )
            .then(({ data }) => {
                data.budgetMembers.map((member) => (member.IsDirty = false));
                dispatch(
                    setBudget({
                        ...data,
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
        dispatch(setLoading(false));
    };
}

export function createBudget() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());

        const { budget } = getState()?.market?.budgeting;

        let budgetBranches = budget.budgetMembers.map((branch) => {            
            return {
                branchId: branch.branchId,                
                value: parseFloat(branch.value.toString().replace(/,/g,"")),
                adjustementFactor: parseFloat(branch.adjustementFactor),
            };
        });

        let budgetUpdated = {
            description: budget.description,
            growthGoal: budget.growthGoal,
            budgetType: budget.budgetType,
            dma: budget.dma,
            shared: budget.shared,
            budgetMembers: budgetBranches,
        };

        const payload = JSON.stringify(budgetUpdated);

        await https
            .post(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/budgets`,
                payload
            )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Budget created successfully",
                });

                dispatch(
                    setBudget({
                        ...response.data,
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function updateBudget(budgetId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());

        const { budget } = getState()?.market?.budgeting;

        let budgetBranches = budget.budgetMembers.map((branch) => {
            return {
                branchId: branch.branchId,                
                value: parseFloat(branch.value.toString().replace(/,/g,"")),
                adjustementFactor: parseFloat(branch.adjustementFactor),
            };
        });

        let budgetUpdated = {
            description: budget.description,
            growthGoal: budget.growthGoal,
            budgetType: budget.budgetType,
            dma: budget.dma,
            shared: budget.shared,
            budgetMembers: budgetBranches,
        };

        const payload = JSON.stringify(budgetUpdated);

        await https
            .put(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/budgets/${budgetId}`,
                payload
            )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Budget saved successfully",
                });

                dispatch(
                    setBudget({
                        ...response.data,
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function deleteBudget(budgetId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());

        await https
            .delete(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/budgets/${budgetId}`
            )
            .then((response) => {
                toast.success({
                    header: "Delete successful",
                    message: "Budget successfully deleted.",
                });
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));

        dispatch(fetchBudgetList());
    };
}

export default marketBudgetSlice.reducer;
