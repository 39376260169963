import LatoThin from "../fonts/lato/Lato-Thin.ttf";
import LatoThinItalic from "../fonts/lato/Lato-ThinItalic.ttf";
import LatoLight from "../fonts/lato/Lato-Light.ttf";
import LatoLightItalic from "../fonts/lato/Lato-LightItalic.ttf";
import Lato from "../fonts/lato/Lato-Regular.ttf";
import LatoItalic from "../fonts/lato/Lato-Italic.ttf";
import LatoBold from "../fonts/lato/Lato-Bold.ttf";
import LatoBoldItalic from "../fonts/lato/Lato-BoldItalic.ttf";
import LatoBlack from "../fonts/lato/Lato-Black.ttf";
import LatoBlackItalic from "../fonts/lato/Lato-BlackItalic.ttf";

const unicodeRange =
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF";

const latoThin = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 100,
    src: `local('Lato Thin Normal'),
        url(${LatoThin}) format('truetype')
    `,
    unicodeRange,
};

const latoThinItalic = {
    fontFamily: "Lato",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 100,
    src: `local('Lato Thin Italic'),
      url(${LatoThinItalic}) format('truetype')
      `,
    unicodeRange,
};

const latoLight = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 300,
    src: `local('Lato Light Normal'),
        url(${LatoLight}) format('truetype')
        `,
    unicodeRange,
};

const latoLightItalic = {
    fontFamily: "Lato",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 300,
    src: `local('Lato Light Italic'),
      url(${LatoLightItalic}) format('truetype')
      `,
    unicodeRange,
};

const latoRegular = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `local('Lato Regular'),
    url(${Lato}) format('truetype')
    `,
    unicodeRange,
};

const latoItalic = {
    fontFamily: "Lato",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `local('Lato Italic'),
      url(${LatoItalic}) format('truetype')
      `,
    unicodeRange,
};

const latoBold = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 700,
    src: `local('Lato Bold Normal'),
    url(${LatoBold}) format('truetype')
    `,
    unicodeRange,
};

const latoBoldItalic = {
    fontFamily: "Lato",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 700,
    src: `local('Lato Bold Italic'),
      url(${LatoBoldItalic}) format('truetype')
      `,
    unicodeRange,
};

const latoBlack = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 900,
    src: `local('Lato Black Normal'),
    url(${LatoBlack}) format('truetype')
    `,
    unicodeRange,
};

const latoBlackItalic = {
    fontFamily: "Lato",
    fontStyle: "italic",
    fontDisplay: "swap",
    fontWeight: 900,
    src: `local('Lato Black Italic'),
      url(${LatoBlackItalic}) format('truetype')
      `,
    unicodeRange,
};

export const fonts = [
    latoThin,
    latoThinItalic,
    latoLight,
    latoLightItalic,
    latoRegular,
    latoItalic,
    latoBold,
    latoBoldItalic,
    latoBlack,
    latoBlackItalic,
];

export const fontFaces = [
    {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontDisplay: "swap",
        fontWeight: 100,
        src: `local('Lato Thin Normal'),
            url(${LatoThin}) format('truetype')
        `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "italic",
        fontDisplay: "swap",
        fontWeight: 100,
        src: `local('Lato Thin Italic'),
          url(${LatoThinItalic}) format('truetype')
          `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontDisplay: "swap",
        fontWeight: 300,
        src: `local('Lato Light Normal'),
            url(${LatoLight}) format('truetype')
            `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "italic",
        fontDisplay: "swap",
        fontWeight: 300,
        src: `local('Lato Light Italic'),
          url(${LatoLightItalic}) format('truetype')
          `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontDisplay: "swap",
        fontWeight: 400,
        src: `local('Lato Regular'),
        url(${Lato}) format('truetype')
        `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "italic",
        fontDisplay: "swap",
        fontWeight: 400,
        src: `local('Lato Italic'),
          url(${LatoItalic}) format('truetype')
          `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontDisplay: "swap",
        fontWeight: 700,
        src: `local('Lato Bold Normal'),
        url(${LatoBold}) format('truetype')
        `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "italic",
        fontDisplay: "swap",
        fontWeight: 700,
        src: `local('Lato Bold Italic'),
          url(${LatoBoldItalic}) format('truetype')
          `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontDisplay: "swap",
        fontWeight: 900,
        src: `local('Lato Black Normal'),
        url(${LatoBlack}) format('truetype')
        `,
        unicodeRange,
    },
    {
        fontFamily: "Lato",
        fontStyle: "italic",
        fontDisplay: "swap",
        fontWeight: 900,
        src: `local('Lato Black Italic'),
          url(${LatoBlackItalic}) format('truetype')
          `,
        unicodeRange,
    },
];
