/* eslint-disable eqeqeq */
import {
    CssBaseline,
    ThemeProvider as MuiThemeProvider,
    StyledEngineProvider,
} from "@mui/material";
import { ThemeProvider } from "styled-components";
import { primaryRoutes } from "constants/routes";
import PrimaryLayout from "components/layout/PrimaryLayout/PrimaryLayout";
import { muiTheme } from "./theme/theme";
import ToastContainer from "components/@bank-ui/core/Toast/ToastContainer";
import { GlobalStyles } from "./theme/globalStyle";
import { useLocation, useHistory } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import IdleTimeout from "components/IdleTimeout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { refreshToken } from "routes/AuthenticationRoute/AuthenticationSlice";
import redirectTPeersDashboard from "utils/redirecttoPeerListDashboard";

function App() {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        let userObject = JSON.parse(sessionStorage.getItem("userObject"));
        if (userObject != undefined)
            dispatch(refreshToken(userObject.userName, userObject.token));
        redirectTPeersDashboard(location, history);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                <ThemeProvider theme={muiTheme}>
                    <GlobalStyles />
                    <ErrorBoundary>
                        <PrimaryLayout>{primaryRoutes}</PrimaryLayout>
                    </ErrorBoundary>
                    <IdleTimeout
                        logout={() => {
                            sessionStorage.clear();
                            window.location.reload();
                        }}
                        idleTime={27}
                        notificationTime={2}
                    />
                    <ToastContainer />
                </ThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
