const findCriteria = (filters, criteria) => filters.find((filter) => filter.filter === criteria);

const addRow = (rows, label, value, index) => {
  rows.push({ "": index === 0 ? label : " ", " ": value });
};

const addRowOption = (rows, label, options) => {
  options.forEach((item, index) => {
    addRow(rows, label, item, index);
  });
};

const addEmptyRow = (rows) => {
  rows.push({ "": "", " ": "" });
};

const addToData = (data, pageName, headers, rows) => {
  data.push({
    pageName,
    output: [
      {
        dataPathRow: { 1: undefined, "": "" },
        pageName,
        xls: { headers, rows },
      },
    ],
  });
};

export function prospectReportBuilder(dataToManipulate, businessEstimates, selectedFilters, institutionSelections = [], industrySelections = [], titleNameSelections = [], prospectLeadOptions = []) {
  const dataToPush = [];

  const basicCriteriaRows = [];
  basicCriteriaRows.push({ "": "Report Name:", " ": `${dataToManipulate?.reportName || "N/A"}` });

  const branchId = findCriteria(selectedFilters.filters, 'BranchId');
  if(branchId){
      addRowOption(basicCriteriaRows, "Market Focus:", institutionSelections);
      addEmptyRow(basicCriteriaRows);
  };

  const zip = findCriteria(selectedFilters.filters, 'zip_code');
  if(zip) addRow(basicCriteriaRows, "Zip Codes:", `[${zip.options}]`, 0);

  const dma = findCriteria(selectedFilters.filters, 'DMA');
  if(dma) addRow(basicCriteriaRows, "Defined Market Area:", `${dma?.options[0] || "N/A"}`, 0);

  addEmptyRow(basicCriteriaRows);
  addToData(dataToPush, "Business Prospect List Report", [""], basicCriteriaRows);

  const rowsToPush = [];
  businessEstimates.forEach((estimate)=> {
      rowsToPush.push({ Range: `[${estimate.range}]`, 'Business Estimates': estimate.value });
  });

  addEmptyRow(rowsToPush);
  addToData(dataToPush, "Business Estimates", [" ", "Range", "Business Estimates"], rowsToPush);

  const additionalRows = [];

  const employeeLocal = findCriteria(selectedFilters.filters, 'Employees_Local_Numeric');
  if(employeeLocal) addRow(additionalRows, "Local Employees:", `[${employeeLocal.options}]`, 0);

  const employeeTotal = findCriteria(selectedFilters.filters, 'Employees_Total_Numeric');
  if(employeeTotal) addRow(additionalRows, "Total Employees:", `[${employeeTotal.options}]`, 0);

  const localSales = findCriteria(selectedFilters.filters, 'SALES_LOCAL_NUMERIC');
  if(localSales) addRow(additionalRows, "Local Sales:", `[${localSales.options}]`, 0);

  const totalSales = findCriteria(selectedFilters.filters, 'SALES_TOTAL_NUMERIC');
  if(totalSales) addRow(additionalRows, "Total Sales:", `[${totalSales.options}]`, 0);

  const yellowPages = findCriteria(selectedFilters.filters, 'YEAR_OF_1ST_APPEARANCE');
  if(yellowPages) addRowOption(additionalRows, "First Year In Yellow Pages:", yellowPages.options);

  const address = findCriteria(selectedFilters.filters, 'SPATIAL_ADDRESS');
  if(address) addRow(additionalRows, "Eliminate blank street address records:", "Yes", 0);

  const phone = findCriteria(selectedFilters.filters, 'BFNE_PHONE_NUMBER');
  if(phone) addRow(additionalRows, "Eliminate blank phone number records:", "Yes", 0);

  const contact = findCriteria(selectedFilters.filters, 'CONTACT_1_NAME');
  if(contact) addRow(additionalRows, "Contact Name:", "Contact Name Must Be Present", 0);

  const creditCode = findCriteria(selectedFilters.filters, "BUSINESS_CREDIT_GRADE");
  if(creditCode){
      const creditCodeOptionsList = prospectLeadOptions?.categories?.find((categoryName) => categoryName.categoryName === "Details")?.filters?.find((relevantCriteria) => relevantCriteria.value === "BUSINESS_CREDIT_GRADE")?.options?.filter((option) => typeof option === "object" && !Array.isArray(option) && option !== null);

      creditCodeOptionsList && creditCodeOptionsList.forEach((leadOption, index) => {
          if(index === 0 || index === "0") addRow(additionalRows, "Credit Code:", " ", 0);
          if(creditCode.options.includes(leadOption.value)){
                  addRow(additionalRows, " ", leadOption.name, index);
          }
      })
  };

  const homeBased = findCriteria(selectedFilters.filters, "WORK_AT_HOME_COTTAGE_CODE");
  if(homeBased) addRow(additionalRows, "Home Based Business:", homeBased.options[0] === "null" ? "No" : "Yes", 0);

  const titlePicks = findCriteria(selectedFilters.filters, "CONTACT_1_TITLE");
  if(titlePicks){
      addRowOption(additionalRows, "Contact Title Selections:", titleNameSelections);
      addEmptyRow(additionalRows);
  };

  const industryPicks = findCriteria(selectedFilters.filters, "MarketAffiliation");
  if(industryPicks){
      addRowOption(additionalRows, "Industry Hierarchy Selections:", industrySelections);
      addEmptyRow(additionalRows); 
  };

  addToData(dataToPush, "Additional Criteria:", [""], additionalRows);

  return dataToPush;
}
