import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { generateClassNames } from "utils/generateClassNames";

const useStyles = createUseStyles((theme) => ({
    typography: {
        lineHeight: (props) => theme.typography[props.variant].lineHeight,
        fontSize: (props) => theme.typography[props.variant].fontSize,
        color: (props) => theme.colors.text[props.color],
    },
}));

export default function Typography({
    component: Component,
    children,
    color,
    variant,
    className: classNameProp,
    ...props
}) {
    const classes = useStyles({ variant, color });
    const typographyClasses = generateClassNames(
        classes.typography,
        classNameProp
    );

    return (
        <Component className={typographyClasses} {...props}>
            {children}
        </Component>
    );
}

Typography.defaultProps = {
    component: "span",
    color: "primary",
    variant: "body",
    className: undefined,
};

Typography.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "body",
        "small",
        "xSmall",
        "tiny",
        "uber",
        "hero",
        "caption",
    ]),
    component: PropTypes.oneOf([
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "div",
        "span",
        "caption",
        "small",
        "sub",
        "sup",
        "dt",
        "dd",
    ]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "success",
        "warning",
        "error",
        "white",
        "disabled",
        "brandPrimary",
        "brandSecondary",
    ]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
};
