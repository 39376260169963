export function getColor({ color, error, theme }) {
    return error
        ? theme.palette.error.main
        : color === "default"
        ? theme.palette.text.primary
        : color === "primary"
        ? theme.palette.primary.main
        : color === "primaryDark"
        ? theme.palette.accent.blueDark
        : color === "secondary"
        ? theme.palette.secondary.main
        : color === "inverted"
        ? theme.palette.common.white
        : color === "brand"
        ? theme.palette.accent.orange
        : theme.palette.text.primary;
}

export function pxToRem(px) {
    const onePxToRem = 0.0625;
    return `${onePxToRem * px}rem`;
}

export function getInverseColor(paletteType) {
    if (paletteType === "light") return "white";
    else return "black";
}
