import { createTheme, adaptV4Theme } from "@mui/material/styles";
import throwErrorOnNull from "utils/throwErrorOnNull";
import { fonts } from "./fonts";

const theme = {
    "@font-face": [...fonts],
    colors: {
        primary: {
            lightest: "#FFC299",
            lighter: "#FFA366",
            light: "#FF8533",
            dark: "#CC5200",
            darker: "#993D00",
            darkest: "#662900",
            default: "#3675D0",
        },
        secondary: {
            lightest: "#ACD2EC",
            lighter: "#82BCE3",
            light: "#59A6D9",
            dark: "#2673A6",
            darker: "#1C567D",
            darkest: "#133953",
            default: "#2E8BC9",
        },
        success: {
            lightest: "#02ED50",
            lighter: "#00C341",
            light: "#009E35",
            dark: "#007025",
            darker: "#006020",
            darkest: "#004C19",
            default: "#00812B",
        },
        warning: {
            lightest: "#FFE5B3",
            lighter: "#FFD380",
            light: "#FFCC6B",
            dark: "#FFAA05",
            darker: "#E69700",
            darkest: "#B87900",
            default: "#FFC626",
        },
        error: {
            lightest: "#FF4747",
            lighter: "#FF1A1A",
            light: "#DB0000",
            dark: "#990000",
            darker: "#800000",
            darkest: "#4D0000",
            default: "#C00000",
        },
        white: "#FFFFFF",
        greys: {
            light: "#EEEEEE",
            disabled: "#cccccc",
            neutral: "#666666",
            dark: "#333333",
        },
        text: {
            primary: "#212121",
            secondary: "#666666",
            warning: "#FFC626",
            success: "#00812B",
            error: "#C00000",
            disabled: "#cccccc",
            white: "#fff",
            brandPrimary: "#ff6600",
            brandSecondary: "#2E8BC9",
        },
        graph: {
            exceptional: "#398d5b",
            strong: "#47c07e",
            success: "#9ee79e",
            moderate: "#59A6D9",
            subOptimal: "#fde16c",
            notClassified: "#BEBEBE",
            weak: "#ff7b7b",
            weaker: "#ff2e2e",
            weakest: "#fa0000",
        },
    },
    shadows: {
        elevation: {
            1: ["0 0 1px 0", "0 1px 3px 0"],
            2: ["0 0 1px 0", "0 2px 4px 0"],
            3: ["0 0 1px 0", "0 4px 8px 0"],
            4: ["0 0 1px 0", "0 8px 16px 0"],
            5: ["0 0 1px 0", "0 16px 24px 0"],
        },
        color: {
            light: "#303133",
            dark: "#0d0d0d",
            primary: "#3675D0",
            secondary: "#2e8bc9",
        },
    },
    typography: {
        uber: {
            fontSize: 112,
            lineHeight: "120px",
        },
        hero: {
            fontSize: 80,
            lineHeight: "96px",
        },
        h1: {
            fontSize: 48,
            lineHeight: "60px",
        },
        h2: {
            fontSize: 37,
            lineHeight: "52px",
        },
        h3: {
            fontSize: 31,
            lineHeight: "48px",
        },
        h4: {
            fontSize: 26,
            lineHeight: "40px",
        },
        h5: {
            fontSize: 24,
            lineHeight: "36px",
        },
        h6: {
            fontSize: 21,
            lineHeight: "32px",
        },
        body: {
            fontSize: 14,
            lineHeight: "32px",
            backgroundColor: "#fff !important",
        },
        small: {
            fontSize: 16,
            lineHeight: "28px",
        },
        caption: {
            fontSize: 14,
            lineHeight: "20px",
        },
        xSmall: {
            fontSize: 12,
            lineHeight: "20px",
        },
        tiny: {
            fontSize: 10,
            lineHeight: "16px",
        },
        input1: {
            fontSize: 16,
            lineHeight: "28px",
        },
        input2: {
            fontSize: 14,
            lineHeight: "24px",
        },
        input3: {
            fontSize: 12,
            lineHeight: "20px",
        },
        button1: {
            fontSize: 18,
            lineHeight: "24px",
        },
        button2: {
            fontSize: 16,
            lineHeight: "22px",
        },
        button3: {
            fontSize: 14,
            lineHeight: "19px",
        },
    },
    spacing: 8,
    shape: { borderRadius: 4 },
    getShadow: function (
        elevationLevel = throwErrorOnNull("elevationLevel"),
        colorKey = "light"
    ) {
        switch (colorKey) {
            case "primary":
                return `${this.shadows.elevation[elevationLevel][0]} ${this.shadows.color[colorKey]}0d, ${this.shadows.elevation[elevationLevel][1]} ${this.shadows.color[colorKey]}1a`;
            case "secondary":
                return `${this.shadows.elevation[elevationLevel][0]} ${this.shadows.color[colorKey]}0d, ${this.shadows.elevation[elevationLevel][1]} ${this.shadows.color[colorKey]}1a`;
            case "dark":
                return `${this.shadows.elevation[elevationLevel][0]} ${this.shadows.color[colorKey]}e6, ${this.shadows.elevation[elevationLevel][1]} ${this.shadows.color[colorKey]}`;
            default:
                // light;
                return `${this.shadows.elevation[elevationLevel][0]} ${this.shadows.color[colorKey]}0d, ${this.shadows.elevation[elevationLevel][1]} ${theme.shadows.color[colorKey]}1a`;
        }
    },
    calculateSpacing: function (multiplier) {
        if (multiplier) {
            return this.spacing * multiplier;
        }
        return multiplier;
    },
};

export const muiTheme = createTheme(adaptV4Theme({
    typography: {
        fontFamily: [
            "Lato",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        ...theme.typography,
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": [...fonts],
            },
        },
    },
    palette: {
        primary: {
            main: "#3675D0",
        },
        secondary: {
            main: "#00812B",
        },
        accent: {
            blueDark: "#133953",
            blue: "#3675D0",
            green: "#00812B",
            orange: "#FF6600",
            buttonSecondaryBorder: "#CCCCCC",
        },
        background: {
            hover: "#e0e0e0",
            level1: "#767676",
            selected:"#e3f2fd",
        },
        text: {
            accent: {
                blueDark: "#133953",
                blue: "#3675D0",
                green: "#00812B",
                orange: "#FF6600",
                disabled: "#D3D3D3"
            },
        },
    },
    zIndex: {
        navigation: 1050,
        dropdown: 1100,
        appBar: 99,
        drawer: 1300,
        mobileStepper: 1049,
        modal: 1400,
        snackbar: 1500,
        speedDial: 1050,
        tooltip: 1600,
    },
    colors: { ...theme.colors },
    shape: { ...theme.shape },
    getShadow: theme.getShadow,
}));

export default theme;
