/* eslint-disable no-loop-func */
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "routes/MarketRoute/selectors";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import { getIdentifyHeaders } from "utils/getMarketHeaders";
import https from "utils/http";
import { transformTableData } from "utils/transformations";
import { getSortColumnDetailsIdentify } from "./selectors";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    reportId: null,
    branchId: null,
    ratioId: null,
    name: null,
    property: null,
    shortDescription: null,
    longDescription: null,
    dataSortedBy:null,
    listTypes: [],
    marketData: [],
    impactSummary: {},
    marketTree: null,
    marketMapTree: null,
    transformed: {
        listTyps: [],
        headers: [],
    },
    dataLayerForTree: [],
    sortedForFirstTime: true,
    sortColumnDetailsIdentify: {},
    dataSortedByHeader: null
};

const marketDataReviewTableSlice = createSlice({
    name: "marketDataReviewTable",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setSortedForFirstTime(state, { payload }) {
            return {
                ...state,
                sortedForFirstTime: payload
            }
        },
        setSortColumnDetailsIdentify(state, { payload }) {
            return {
                ...state,
                sortColumnDetailsIdentify: payload
            }
        },
        setDataSortedByHeader(state, { payload }) {
            return {
                ...state,
                dataSortedByHeader: payload
            }
        },
        setMarketDataReviewTreeData(state, { payload }) {
            return {
                ...state,
                marketTree: payload,
            };
        },
        setMarketDataReviewMapTreeData(state, { payload }) {
            return {
                ...state,
                marketMapTree: payload,
            };
        },
        setMarketDataReviewTableData(state, { payload }) {
            return {
                ...state,
                ...payload.data,
                transformed: {
                    ...state.transformed,
                    ...payload.transformed,
                },
            };
        },
        resetMarketDataReviewTable() {
            return {
                ...initialState,
            };
        },
        setDataLayerForTree(state, { payload }) {
            return {
                ...state,
                dataLayerForTree: payload,
            }
        }
    },
});

export const {
    setLoading,
    setMarketDataReviewTreeData,
    setMarketDataReviewMapTreeData,
    setMarketDataReviewTableData,
    resetMarketDataReviewTable,
    setDataLayerForTree,
    setDataSortedByHeader,
    setSortedForFirstTime,
    setSortColumnDetailsIdentify
} = marketDataReviewTableSlice.actions;

export function transformListTypes(listTypes) {
    let newListTypes = [];
    _.forEach(listTypes, function (listTypeItem) {
        const { listType, label } = listTypeItem;
        newListTypes = _.concat(newListTypes, {
            id: listType,
            label: label,
            value: listType,
            listType,
        });
    });
    return newListTypes;
}

export function fetchMarketReviewTreeData(sortData) {
    return async (dispatch, getState) => {
        const currentModule = getMarketCurrentModule(getState());
       
        let dataLayerObject;

        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            listType,
            ratioId,
            region,
            sort
        } = getMarketSelectedFilters(getState());

        const params = {
                    dma: reportDMA, 
                    region, 
                    listType 
                };

        if(sortData) { 
            params.sortColumnNumber = sort.sortColumnNumber; 
            params.columnSortOrder  = sort.columnSortOrder;
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}/map/dataLayers`,
                { params }
            )
            .then(({ data }) => {
                dataLayerObject = data;
                dispatch(setDataLayerForTree([...data]));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy`,
                { params }
            )
            .then(({ data }) => {
                dispatch(
                    setMarketDataReviewTreeData({
                        ...data,
                    })
                );

                let mapTree = JSON.parse(JSON.stringify(data));                               
                PruneMarketTree(mapTree.children, dataLayerObject);

                dispatch(
                    setMarketDataReviewMapTreeData({
                        ...mapTree,
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
    };
}

function PruneMarketTree(treeCollection, dataLayer) {
    
    const treeNavData = [...treeCollection];
    for (var treeItem of treeNavData) {
        if (
            dataLayer.find((item) => {
                return parseInt(item.ratioId) === parseInt(treeItem.ratioId);
            })
        ) {
            if (treeItem.children.length > 0)
                PruneMarketTree(
                    treeCollection.find(
                        (item) =>
                            parseInt(item.ratioId) ===
                            parseInt(treeItem.ratioId)
                    ).children,
                    dataLayer
                );
        } else {
            _.remove(treeCollection, function (item) {
                return item.ratioId === treeItem.ratioId;
            });
        }
    }
}

export function fetchMarketReviewTableData(sortData) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            listType,
            ratioId,
            region,
            sort,
            showBranchByName
        } = getMarketSelectedFilters(getState());

        const api = 
            `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}`;

        const params = {
            dma: reportDMA, 
            region, 
            listType,
            showBranchBy: showBranchByName ? "NAME": "ADDRESS"  
        };

        if(sortData){
            dispatch(setSortedForFirstTime(false))
            if (sort.sortColumnNumber >= 0) {
                const sortColumnDetailsIdentify = getSortColumnDetailsIdentify(getState());
                if(sortColumnDetailsIdentify){
                    params.sortColumnNumber = sortColumnDetailsIdentify.sortNumber;
                    params.columnSortOrder = sortColumnDetailsIdentify.sortOrder;
                }
            }
        }

        await https
            .get(api, { params })
            .then(({ data }) => {
        
                const listTypes = transformListTypes(data.listTypes);
                
                let transformedMarketData = transformTableData(data.marketData);

                let rowIndex = 0;
                transformedMarketData = transformedMarketData.map((item) => {
                    rowIndex++;
                    return {
                            No: rowIndex,
                            ...item,
                            sequence: ratioId === "995000"?(item["value5"] !== null && item["value5"] === "N"? 0:1) : item.sequence 
                    }

                  
                });

                if(ratioId === "995000") transformedMarketData.sort((item, item1) => (item.sequence + 1) - (item1.sequence + 1));

                const headers = getIdentifyHeaders(data.marketData)
                //const dataSortedBy = headers.find((eachHeader) => eachHeader.id === `value${params.sortColumnNumber}`)?.label;
               
                if(!sortData){
                    const sortNumberFind = headers.find((eachHeader) => eachHeader.label === data?.defaultSortColumn)?.id?.slice(-1);
                    dispatch(setSortColumnDetailsIdentify({
                              sortNumber: Number(sortNumberFind),
                              sortOrder: data?.dataSortingOrder || "DESCENDING"
                       }))
                }
                
                dispatch(
                    setDataSortedByHeader(
                        data.dataSortedBy ? data.dataSortedBy : data?.defaultSortColumn
                    )
                )

                dispatch(
                    setMarketDataReviewTableData({
                        data: {
                            ...data,
                            marketData: transformedMarketData,
                        },
                        transformed: {
                            listTypes: listTypes,
                            headers: [{id:"No", label:"No"}, ...getIdentifyHeaders(data.marketData)],
                        },
                    })
                );

            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export default marketDataReviewTableSlice.reducer;
