import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    *::-webkit-scrollbar {
        width: 12px;
	    background-color: #F5F5F5;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 10px;
	    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	    background-color: #aaa;
        border: 2px solid #F5F5F5;

        :hover {
            background-color: #555;
        }
    }

    *::-webkit-scrollbar-track {
        background-color: #F5F5F5;
        border-radius: 0;
    }

    body {
        margin: 0;
        font-family: Lato, -apple-system,
            BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
            "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
    }
    
    .link {
        fill: none;
        stroke: #d8d8d8;
        stroke-width: 5px;
    }
    
    @media print {
        body {
            -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
            color-adjust: exact !important; /*Firefox*/
            zoom: 60%;
        }
    
        .primary__side__nav {
            visibility: hidden;
        }
    }

    input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
`;
