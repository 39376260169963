import toast from "components/@bank-ui/core/Toast/Toast";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorHandler, getErrorMessage } from "../../utils/errorHandler";
import https from "../../utils/http";
import {
    getMarketCurrentModule,
    getMarketReportName,
    getMarketReportPages,
} from "components/addToReportMenu/selectors";

const { VITE_API_BASE_URL } = import.meta.env;

let sessionReportName = JSON.parse(sessionStorage.getItem("reportName"));

const initialState = {
    reportName: sessionReportName !== undefined? sessionReportName : null,
    reportId: null,
    peerGroupId: null,
    pages: [],
    ratioTab: null,
};

const addToReportsSlice = createSlice({
    name: "addToReports",
    initialState,
    reducers: {
        setReportName(state, { payload }) {
            return {
                ...state,
                reportName: payload.reportName,
                reportId: payload.reportId,
                peerGroupId : payload.peerGroupId,
                ratioTab: payload.ratioTab ? payload.ratioTab : null,
            };
        },
        setReportPages(state, { payload }) {
            return {
                ...state,
                pages: payload,
            };
        },
        resetMarketAddToReports() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setReportName,
    setReportPages,
    resetMarketAddToReports,
} = addToReportsSlice.actions;

export function addPageToReport({ page }) {
    return async (dispatch, getState) => {
        const pages = getMarketReportPages(getState());
        dispatch(setReportPages(_.concat(pages, page)));
    };
}

export function saveNewReport() {
    return async (dispatch, getState) => {
        const { peerGroupId, reportId } = getState().reports;
        const currentModule = getMarketCurrentModule(getState());
        const reportName = getMarketReportName(getState());
        const pages = getMarketReportPages(getState());

        const payload = {
            templateName: reportName,
            templateDescription: reportName,
            pageCount: pages.length,
            templateValue: JSON.stringify({
                templateName: reportName,
                pages,
            }),
        };

        https
            .post(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/downloadTemplates`,
                payload
            )
            .then((response) => {
                dispatch(resetMarketAddToReports());
                toast.success({
                    header: "Save successful",
                    message: "Template is saved successfully",
                });
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
    };
}

export const custom_pages = ({ addToReports }) => addToReports.pages;
export const custom_reports_id = ({ addToReports }) => addToReports.reportId;
export const custom_peer_Group_Id = ({ addToReports }) => addToReports.peerGroupId;


export default addToReportsSlice.reducer;
