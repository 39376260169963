import { createSlice } from "@reduxjs/toolkit";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import toast from "components/@bank-ui/core/Toast/Toast";
import { getMEAHeaders } from "utils/getMarketHeaders";
import { transformTableData } from "utils/transformations";
import {
    getMarketCurrentModule,
} from "routes/MarketRoute/selectors";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,   
    overAllRankList: null,    
    driverRankList:null,
    weights: { 
            weights:null,
            summary:null
    },
    weightsToUpdate:[],
    totalWeights: null,
    activeTab:0,
    activeAdjustWeightTab: 0
}; 

const marketMEASlice = createSlice({
    name: "marketMEAList",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },

        setOverAllRankList(state, { payload }) {
            return {
                ...state,
                overAllRankList: payload,
            };
        },        

        setDriverRankList(state, { payload }) {
            return {
                ...state,
                driverRankList: payload,
            };
        }, 

        setWeights(state, { payload }) {
            return {
                ...state,
                weights: payload,
            };
        },      

        setWeightsToUpdate(state, { payload }) {
            return {
                ...state,
                weightsToUpdate: payload,
            };
        },  

        setTotalWeights(state, { payload }) {
            return {
                ...state,
                totalWeights: payload,
            };
        },

        setActiveMEATab(state, { payload }) {
            return {
                ...state,
                activeTab: payload,
            };
        },
        setActiveAdjustWeightingTab(state, { payload }) {
            return {
                ...state,
                activeAdjustWeightTab: payload,
            };
        },

        resetMarketMEA() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setConsumerRankList,
    setBusinessRankList,
    setMarketRankList,
    setWealthRankList,
    resetMarketMEA,
    setOverAllRankList,
    setDriverRankList,
    setWeights,     
    setWeightsToUpdate,
    setTotalWeights,
    setActiveMEATab,
    setActiveAdjustWeightingTab
} = marketMEASlice.actions;

export function fetchOverAllRankList() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const currentModule = getMarketCurrentModule(getState());

        try {

            const {
                selected: { reportGrouping, reportBranches, reportDMA, sort } = {},
            } = getState()?.market?.root;

            const params = {
                dma: reportDMA,
                sortColumnNumber:sort.sortColumnNumber.toString().toUpperCase(),
                columnSortOrder :sort.columnSortOrder.toString().toUpperCase()
            };    

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/expansion/driverId/0`,
                {params}
            );

            if (status === 200) {
                
            await dispatch(setOverAllRankList(data));
            }
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchRankList(driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        try {

            const {
                selected: { reportGrouping, reportBranches, reportDMA, sort } = {},
            } = getState()?.market?.root;

            const currentModule = getMarketCurrentModule(getState());
  
            const params = {
                dma: reportDMA,
                sortColumnNumber:sort.sortColumnNumber.toString().toUpperCase(),
                columnSortOrder :sort.columnSortOrder.toString().toUpperCase()
            };            

          
            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/expansion/driverId/${driverId}`,
                {params}

             );

            if (status === 200) {

                let output = {quantifyData: transformTableData(data),
                    headers: getMEAHeaders(data),
                }            
            
                dispatch(setDriverRankList(output));

            }

        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchAdjustWeights(driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            // eslint-disable-next-line no-unused-vars
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());

        try {

            // eslint-disable-next-line no-unused-vars
            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/expansion/driverId/${driverId}/getWeight`
            ); 
            
            var totalDriverWeights = data.weights.reduce(function(total, value) {
                return total + (value.weight_Id? parseFloat(value.weight):0);
            }, 0);   
        
            dispatch(setTotalWeights(totalDriverWeights));
        
            dispatch(setWeights(data));
         
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function updateWeights({ driverId, weightId, weights, }) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            // eslint-disable-next-line no-unused-vars
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());
      
        const payload = JSON.stringify(weights);

        await https
            .put(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/expansion/driverId/${driverId}/weights/${weightId}`,
                payload
            )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Weights saved successfully",
                });      
                
                var totalDriverWeights = response.data.weights.reduce(function(total, value) {
                    return total + (value.weight_Id? parseFloat(value.weight):0);
                }, 0);   
            
                dispatch(setTotalWeights(totalDriverWeights));
            
                dispatch(setWeights(response.data));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}


export default marketMEASlice.reducer;