export const pageNames = {
    MARKET: "Market",
    MARKET_SUMMARY: "Market Summary",
    MARKET_DATAREVIEW: "Market Review",
    MARKET_REPORTS: "Market Reports",
    MARKET_QUANTIFY: "Market Quantify",
    MARKET_PROSPECTLIST: "Market Prospect List",
    MARKET_BUDGETING: "Market Budgeting",
    MARKET_DATAREVIEW_TABLE: "Market Review",
    MARKET_DATAREVIEW_PIE: "Market Review Chart",
    MARKET_DATAREVIEW_BAR: "Market Review Barchart",
    MARKET_DATAREVIEW_MAP: "Market Review Map",
    MARKET_EXECUTIVE_SUMMARY_MATRIX: "Market Matrix",
    MARKET_RECOMMENDATION: "Market Recommend",
    MARKET_DATAREVIEW_MEA_TABLE: "Market MEA",
    MARKET_BRANCH_OPTIMIZATION_TABLE: "Market BO",
};

export const pageNameFinancial = {
    FINANCIAL: "Financial Performance Dashboard",
    FINANCIAL_RATIOS: "Financial Ratios",
    // FINANCIAL_REPORTS: "/ba/financial/reports",
    // FINANCIAL_PEERSELECTION: "/ba/financial/peerSelection",
    // FINANCIA_CUSTOMREPORTS: "/ba/financial/customReports",

};
