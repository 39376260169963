import toast from "components/@bank-ui/core/Toast/Toast";
import { createSlice } from "@reduxjs/toolkit";
import https from 'utils/http';
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import {
  fetchPeerGroups,
  setPeerGroupId
} from '../ReportsRoute/ReportsSlice';
import { PATHS_BA, PATHS_CUA } from "constants/paths";

const initialState = {
    loading: false,
    peerName: '',
    filterData: {},
    customPeerData: {},
    institutionsList: [],
    selectedBanks: [],
    filters: [],
    previewList: [],
    editPeer: [],
    allInstitutions: [],
    customPeerEditId: '',
    shareAcrossInstitution: false,
    autoRefresh: false,
    newPeerNameForCopy: ''
};

export const peersSlice = createSlice({
    name: "peers",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setfilterData: (state, action) => {
            state.filterData = action.payload;
            state.loading = false;
        },
        mergeCustomPeerData: (state, { payload }) => {
            state.customPeerData = Object.assign(state.customPeerData, { ...payload });
        },
        setInstitutionsListFilter: (state, { payload }) => {
            state.institutionsList = payload;
            state.loading = false;
        },
        setSelectedBanks: (state, { payload }) => {
            state.selectedBanks = payload;
        },
        setFilters: (state, { payload }) => {
            state.filters = payload;
        },
        setCustomPeerName: (state, { payload }) => {
            state.peerName = payload;
        },
        addFilters: (state, { payload }) => {
            state.filters = payload;
        },
        setPreviewList: (state, { payload }) => {
            state.previewList = payload;
            state.loading = false;
        },
        setEditPeer: (state, { payload }) => {
          state.editPeer = payload;
        },
        setAllInstitions: (state, { payload }) => {
          state.allInstitutions = payload;
      },
      setShareAcrossInstitution: (state, { payload }) => {
          state.shareAcrossInstitution = payload;
      },
      setCustomPeerEditId: (state, { payload }) => {
        state.customPeerEditId = payload;
      },
      setCalculationReportTimer: (state, { payload }) => {
        state.reportStartTime = payload;
      },
      setAutoRefresh: (state, { payload }) => {
        state.autoRefresh = payload;
      },
      setNewPeerNameForCopy: (state, { payload }) => {
        state.newPeerNameForCopy = payload;
      }
    },
});

export const {
    setLoading,
    setCustomPeerName,
    setfilterData,
    mergeCustomPeerData,
    setInstitutionsListFilter,
    setSelectedBanks,
    setFilters,
    addFilters,
    setPreviewList,
    setEditPeer,
    setAllInstitions,
    setCustomPeerEditId,
    setCalculationReportTimer,
    setShareAcrossInstitution,
    setAutoRefresh,
    setNewPeerNameForCopy
} = peersSlice.actions;

export const updateCustomPeerData = (key, value) => dispatch => {
    dispatch(mergeCustomPeerData({ [key]: value }));
};

export const fetchFilterOptions = (reportId) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/filterOptions`)
    .then(({ data }) => {
      dispatch(setfilterData(data));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
    });
};

export const fetchInstitutionsList = (reportId, searchString) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    return https
      .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/institutionSearch`,
      {
        params: {
            token : searchString
        }
      }
    )
      .then(({ data }) => {
        dispatch(setInstitutionsListFilter(data));
         dispatch(setLoading(false));
      })
      .catch((error) => {
        errorHandler(getErrorMessage(error));
         dispatch(setLoading(false));
      });
  };

  export const fetchAllInstitutions = (reportId, filters) => (dispatch, getState) => {
   // dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    const payload = JSON.stringify({filters});
    return https
    .post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/query`,
      payload
    )
      .then(({ data }) => {
        dispatch(setAllInstitions(data));
       // dispatch(setLoading(false));
      })
      .catch((error) => {
        errorHandler(getErrorMessage(error));
        dispatch(setLoading(false));
      });
  };

  export const fetchPreviewList = (reportId, filters) => (dispatch, getState) => {
   dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    const payload = JSON.stringify({filters});
    return https
      .post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/query`,
      payload
    )
      .then(({ data }) => {
        dispatch(setPreviewList(data));
       dispatch(setLoading(false));
      })
      .catch((error) => {
        errorHandler(getErrorMessage(error));
         dispatch(setLoading(false));
      });
  };


  export const getCalculationReportPeers = (reportId, selectedPeerGroupId) => (dispatch, getState) => {
  const timeDiff = new Date() / 60 / 1000;
  const { bankAnalyst, reportStartTime } = getState().reports;
  if(reportStartTime === null){
  dispatch(setCalculationReportTimer(timeDiff));
  }
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${selectedPeerGroupId}/calculation`
    )
    .then(({ data }) => {
    //  dispatch(setCalculationReport(data));
      if(data.status === 'CALCULATED' || data.status === 'COMPLETED'){
          dispatch(setCalculationReportTimer(null));
          dispatch(setLoading(false));
      }else if(data.status === 'ERROR'){
          dispatch(setLoading(false));
          errorHandler(getErrorMessage({message: 'Timeout: Not able to retrive data'}));
      }else{
        const waitingTime = timeDiff - reportStartTime;
        if(reportStartTime !== null && waitingTime >= 5 ){
            dispatch(setLoading(false));
            errorHandler(getErrorMessage({message: 'Timeout: Not able to retrive data'}));
        }else{
         dispatch(setLoading(true));
              setTimeout(() => {
                dispatch(getCalculationReportPeers(reportId, selectedPeerGroupId));
            }, 1000);
        }
      }
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
      dispatch(setLoading(false));
    });
};


    export const setCustomePeerGroupSave = (reportId, filters,  peerName, autoRefresh, history, shareAcrossInstitution) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    const payload ={peerGroupName:peerName, peerGroupDescription: peerName, autoRefresh, filters, shareAcrossInstitution };
    return https
      .post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/savedQuery`,
      payload
    )
      .then(({ data }) => {
        dispatch(fetchPeerGroups());
        dispatch(setfilterData([]));
        dispatch(addFilters([]));
        dispatch(setPreviewList([]));
        dispatch(setSelectedBanks([]));
        dispatch(setAllInstitions([]));
        toast.success({
          header: "Save successful",
          message: "Peer Group is saved successfully",
        });
        if (bankAnalyst === 'ba') {
          history.push(PATHS_BA.FINANCIAL_PEERSELECTION);
        } else {
          history.push(PATHS_CUA.FINANCIAL_PEERSELECTION);
        }
        dispatch(setLoading(false));
        dispatch(getCalculationReportPeers(reportId, data.peerGroupId));
      })
      .catch((error) => {
        errorHandler(getErrorMessage(error));
        dispatch(setLoading(false));
      });
  };

  export const setCustomePeerGroupEditedSave = (reportId, filters,  peerName, autoRefresh, history, shareAcrossInstitution) => (dispatch, getState) => {
   // dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    const { customPeerEditId } = getState().peers;
    const payload ={peerGroupName:peerName, peerGroupDescription: peerName, autoRefresh, filters, shareAcrossInstitution };
    return https
      .put(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/savedQuery/${customPeerEditId}`,
      payload
    )
      .then(({ data }) => {
        dispatch(fetchPeerGroups());
        dispatch(setfilterData([]));
        dispatch(addFilters([]));
        dispatch(setPreviewList([]));
        dispatch(setSelectedBanks([]));
        dispatch(setAllInstitions([]));
        dispatch(setCustomPeerEditId(''));
        toast.success({
          header: "Edit successful",
          message: "Peer Group is updated successfully",
        });
        if (bankAnalyst === 'ba') {
          history.push(PATHS_BA.FINANCIAL_PEERSELECTION);
        } else {
          history.push(PATHS_CUA.FINANCIAL_PEERSELECTION);
        }
       // dispatch(setLoading(false));
        dispatch(getCalculationReportPeers(reportId, data.peerGroupId));
      })
      .catch((error) => {
        errorHandler(getErrorMessage(error));
        dispatch(setLoading(false));
      });
  };

  export const deleteCustomPeer = (reportId, peerGroupId, firstPeerGroupId) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    return https
      .delete(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/savedQuery/${peerGroupId}`)
      .then(({ data }) => {
        dispatch(fetchPeerGroups());
        dispatch(setPeerGroupId(firstPeerGroupId));
        toast.success({
          header: "Delete successful",
          message: "Peer Group is deleted successfully",
        });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          errorHandler(getErrorMessage({message:'You are not authorized to delete this peer group'}));
        } else {
          errorHandler(getErrorMessage(error));
        }
        dispatch(setLoading(false));
      });
  };

  export const editCustomPeer = (reportId, peerGroupId) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    return https
      .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/savedQuery/${peerGroupId}`)
        .then(({ data }) => {
          dispatch(setEditPeer(data.filters));
          dispatch(setCustomPeerName(data.peerGroupName));
          dispatch(setShareAcrossInstitution(data.shareAcrossInstitution));
          dispatch(setCustomPeerEditId(data.peerGroupId));
          dispatch(setAutoRefresh(data.autoRefresh));
          // eslint-disable-next-line eqeqeq
          const fetchFilter = data.filters.filter((item, index) => item.filterId == 1);
          const filterKeys = fetchFilter.map((item, index) => {return {...item, isActive: item.active, active: undefined, operator: item.operator === null ? "" : item.operator}});
          if (filterKeys.length > 0) {
            dispatch(fetchAllInstitutions(reportId, filterKeys));
          }
          dispatch(setLoading(false));
        })
        .catch((error) => {
          errorHandler(getErrorMessage(error));
          dispatch(setLoading(false));
      });
  };

  export const copyCustomPeerGroup = (reportId, copyingPeerId, newPeerNameForCopy) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const { bankAnalyst } = getState().reports;
    const payload = {'sourcePeerGroupId': copyingPeerId, 'targetPeerGroupName': newPeerNameForCopy};
    return https
      .post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/tools/cpt/copy`,
      payload
    )
      .then(({ data }) => {
        dispatch(fetchPeerGroups());
        toast.success({
          header: "Copy successful",
          message: "Peer Group is copied successfully",
        });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        errorHandler(getErrorMessage(error));
        dispatch(setLoading(false));
      });
  };


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const loading = ({ peers }) => peers.loading;
export const Peer_Name = ({ peers }) => peers.peerName;
export const filter_Data = ({ peers }) => peers.filterData;
export const custom_peer_data = ({ peers }) => peers.customPeerData;
export const institutions_list = ({ peers }) => peers.institutionsList;
export const selected_banks = ({ peers }) => peers.selectedBanks;
export const filters_data = ({ peers }) => peers.filters;
export const preview_list = ({ peers }) => peers.previewList;
export const edit_custom_data = ({ peers }) => peers.editPeer;
export const all_instituions = ({ peers }) => peers.allInstitutions;
export const custom_Peer_EditId = ({ peers }) => peers.customPeerEditId;
export const custom_Peer_shareAcrossInstitution = ({ peers }) => peers.shareAcrossInstitution;
export const custom_Peer_openShare= ({ peers }) => peers.openShare;
export const auto_refresh = ({ peers }) => peers.autoRefresh;
export const newPeerName_forCopy = ({ peers }) => peers.newPeerNameForCopy;

export default peersSlice.reducer;
