/**
 * Use this reducer to store all filter values selected inside route which can be used as building blocks for add to reports endpoint
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const marketFilters = createSlice({
    name: "selectedFilters",
    initialState,
    reducers: {
        setFiltersForReportEndpoint(state, { payload }) {
            return {
                ...payload,
            };
        },
    },
});

export const { setFiltersForReportEndpoint } = marketFilters.actions;

export default marketFilters.reducer;
