/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import toast from "components/@bank-ui/core/Toast/Toast";
import { getBOHeaders } from "utils/getMarketHeaders";
import { transformTableData } from "utils/transformations";
import {
    getMarketCurrentModule,
} from "routes/MarketRoute/selectors";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,   
    overAllRankList: null,
    driverRankList:null,
    weights: { 
            weights:null,
            summary:null
    },
    weightsToUpdate:[],
    totalWeights: null,
    internalMetrics:null,
    internalMetricsToUpdate:[],
    internalMetricsList:null,
    currentSelection: null,
    activeTab:100000
}; 

const marketBOSlice = createSlice({
    name: "marketBOSlice",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },

        setOverAllRankList(state, { payload }) {
            return {
                ...state,
                overAllRankList: payload,
            };
        },

        setDriverRankList(state, { payload }) {
            return {
                ...state,
                driverRankList: payload,
            };
        }, 

        setWeights(state, { payload }) {
            return {
                ...state,
                weights: payload,
            };
        },  

        setWeightsToUpdate(state, { payload }) {
            return {
                ...state,
                weightsToUpdate: payload,
            };
        },

        setTotalWeights(state, { payload }) {
            return {
                ...state,
                totalWeights: payload,
            };
        },

        setInternalMetrics(state, { payload }) {
            return {
                ...state,
                internalMetrics: payload,
            };
        },

        setInternalMetricsList(state, { payload }) {
            return {
                ...state,
                internalMetricsList: payload,
            };
        },

        setInternalMetricsToUpdate(state, { payload }) {

            let internalMetrics = [];
            
            state.internalMetricsToUpdate.map((w) => {
                internalMetrics.push({
                    branchId: w.branchId,
                    data: w.data,
                });    
            });

            let branch = internalMetrics.find((item)=> {
                return item.branchId === payload.branchId
            });

            if(!branch){
                internalMetrics.push({
                    branchId: payload.branchId,
                    data: [{
                        dataId: payload.dataId,
                        value: payload.value
                    }],
                });
            }
            else {
                let newData = [];
                branch.data.map((w)=> {
                    if (w.dataId !== payload.dataId){
                            newData.push({
                                dataId: w.dataId,
                                value: w.value
                            })
                    }
                })

                newData.push({
                    dataId: payload.dataId,
                    value: payload.value
                })

                branch.data = newData;
            }

            return {
                ...state,
                internalMetricsToUpdate: internalMetrics,
            };
        },
        setUploadInternalMetricsToUpdate(state, { payload }) {
            return {
                ...state,
                internalMetricsToUpdate: payload,
            };
        },


        setActiveBOTab(state, { payload }) {
            return {
                ...state,
                activeTab: payload,
            };
        },

        setActiveBOTab(state, { payload }) {
            return {
                ...state,
                activeTab: payload,
            };
        },

        resetMarketBO() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setConsumerRankList,
    setBusinessRankList,
    setMarketRankList,
    setWealthRankList,
    resetMarketMEA,
    setOverAllRankList,
    setDriverRankList,
    setWeights,     
    setWeightsToUpdate,
    setTotalWeights,
    setInternalMetrics,
    setInternalMetricsToUpdate,
    setInternalMetricsList,
    setActiveBOTab,
    setUploadInternalMetricsToUpdate
} = marketBOSlice.actions;

export function fetchOverAllRankList(driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        const currentModule = getMarketCurrentModule(getState());

        try {

            const {
                selected: { reportGrouping, reportBranches, reportDMA, sort } = {},
            } = getState()?.market?.root;

            const params = {
                dma: reportDMA,
                sortColumnNumber:sort.sortColumnNumber.toString().toUpperCase(),
                columnSortOrder :sort.columnSortOrder.toString().toUpperCase()
            };     


            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/brop/rank/${driverId}`,
                {params}
            );

            if (status === 200) {                
                await dispatch(setOverAllRankList(data));
            }

        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchRankList(driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const currentModule = getMarketCurrentModule(getState());

        try {

            const {
                selected: { reportGrouping, reportBranches, reportDMA, sort } = {},
            } = getState()?.market?.root;
            
            const params = {
                dma: reportDMA,
                sortColumnNumber:sort.sortColumnNumber.toString().toUpperCase(),
                columnSortOrder :sort.columnSortOrder.toString().toUpperCase()
            };     

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/brop/rank/${driverId}`,
                {params}
            );

            if (status === 200) {

                let output = {quantifyData: transformTableData(data),
                    headers: getBOHeaders(data),
                }            
            
                await dispatch(setDriverRankList(output));

            }

        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchAdjustWeights(driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const currentModule = getMarketCurrentModule(getState());

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        try {

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/brop/weights`
            ); 
            const selectedWeights = data.weights;
                           
            const selectedWeightLimits = ["131000","132000","133000"];
            let internalmetricsSelected = ((selectedWeights?.filter((weight)=> selectedWeightLimits.indexOf(weight.groupId) > -1))?.length);
            if(internalmetricsSelected) {                    
                dispatch(fetchInternalMetrics(2));
            } else {                  
                dispatch(setInternalMetrics({
                    tableData: [],
                    headers: [],
                }));
            }         
            var totalDriverWeights = data.summary.reduce(function(total, value) {
                return total + (value.groupId? parseFloat(value.weight):0);
            }, 0); 
            
        
            dispatch(setTotalWeights(totalDriverWeights));
        
            const internalWeightLimits = [
                {
                    GroupId: "131000",
                    Count: 3,
                },
                {
                    GroupId: "132000",
                    Count: 2,
                },
                {
                    GroupId: "133000",
                    Count: 4,
                },
            ];

            let editedWeights = {...data} 
            internalWeightLimits.forEach((group)=>{

                let weightsToAdd = group.Count - ((editedWeights.weights.filter((weight)=> weight.groupId === group.GroupId)).length+1) 
                
                for (let c=0; c <= weightsToAdd; c++){
                    
                    editedWeights.weights.push({
                            "weightId": 0,
                            "groupId": group.GroupId,
                            "dataId": "0",
                            "nameCommon": "",
                            "nameBam": "",
                            "weight": "0.0"
                    });
                }

            });

            dispatch(setWeights(editedWeights));

            dispatch(setWeightsToUpdate([]));
         
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchInternalMetrics() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const currentModule = getMarketCurrentModule(getState());

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        try {

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/brop/internalMetrics`
            ); 
        
            dispatch(setInternalMetrics({
                tableData: transformTableData(data),
                headers: getBOHeaders(data),
            }));
         
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchInternalMetricsList() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const currentModule = getMarketCurrentModule(getState());

        const {
            selected: { reportGrouping } = {},
        } = getState()?.market?.root;

        try {

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/brop/internalMetricsList`
            ); 
           
            let metricList = data.map((item) => ({
                id: item.dataId,
                label: item.nameBam,
                value: item.dataId,
                groupId:item.groupId
            }));

            dispatch(setInternalMetricsList(metricList));
         
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function updateWeights(weightId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const weightsToUpdate = getState()?.market?.marketBO.weightsToUpdate;

        if(weightsToUpdate.length) {
        const reqPayload = weightsToUpdate.map((item) => {
            return {
                dataId: item.dataId,
                value: item.value,
                rankOrder: item.rankOrder
            }
        })

        const currentModule = getMarketCurrentModule(getState());
      
        const payload = JSON.stringify({
            weights:reqPayload
        });

        await https
            .put(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/brop/weights/${weightId}`,
                payload
            )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Weights saved successfully",
                });   

                dispatch(fetchAdjustWeights());
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
        }
        dispatch(setLoading(false));
    };
}

export function updateInternalMetrics(weightId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const internalMetricsToUpdate = getState()?.market?.marketBO.internalMetricsToUpdate;

        if(internalMetricsToUpdate.length) 
        {
            const currentModule = getMarketCurrentModule(getState());
      
            const payload = JSON.stringify({
                branches:internalMetricsToUpdate
            });

            await https
                .put(
                    `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/brop/internalMetrics/${weightId}`,
                    payload
                )
                .then((response) => {
                    toast.success({
                        header: "Save successful",
                        message: "Weights saved successfully",
                    });   

                    dispatch(setUploadInternalMetricsToUpdate([]));

                    dispatch(fetchInternalMetrics());                   
                })
                .catch((error) => errorHandler(getErrorMessage(error)));
        }

        dispatch(setLoading(false));
    };
}

export function updateUserEntry(userEntry,driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());
      
         const payload = JSON.stringify(
             { 
                 action: userEntry.action?userEntry.action:"",
                 notes: userEntry.notes?userEntry.notes:"" 
             });

        const params = {
            action: userEntry.action,  
            notes: userEntry.notes,
        };

        await https
            .put(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${userEntry.branchId}/brop/userEntry`,
                 payload
                )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Changes saved successfully",
                });   

                dispatch(fetchRankList(driverId));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function updateWeightSelection(data, driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());
      
         const payload = JSON.stringify(
             { 
                currentDataId: data.currentDataId,
                newDataId: data.newDataId,
                rankOrder: data.rankOrder
             });

        await https
            .put(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/brop/weights/${data.weightId}/change`,
                 payload
                )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Changes saved successfully",
                }); 
                dispatch(fetchAdjustWeights())
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export function deleteWeightSelection(data, driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        const {
            selected: { reportGrouping } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());
      
         const payload = JSON.stringify(
             { 
                reportId: parseInt(reportGrouping),
                weightId: data.weightId,
                dataIdToBeRemoved: data.newDataId,
             });

        await https
            .delete(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/brop/weights/${data.weightId}/delete/${data.newDataId}`                 
                )
            .then((response) => {
                toast.success({
                    header: "Deleted successful",
                    message: "Deleted successfully",
                });   
                dispatch(fetchAdjustWeights())
                dispatch(fetchInternalMetricsList());
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export default marketBOSlice.reducer;