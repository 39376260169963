import PropTypes from 'prop-types';

const Icon = ({ IconComponentName, width, height, className, onClick, ...restProps }) => {
  return (
    <IconComponentName width={width} height={height} className={className} onClick={onClick} {...restProps} />
  );
};

Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  IconComponentName: PropTypes.any.isRequired
};

Icon.defaultProps = {
  width: '20',
  height: '20',
  onClick: () => {}
};

export default Icon;
