import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "react-jss";
import { StyledEngineProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Router } from "react-router-dom";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "element-closest-polyfill";
import { setupInterceptor } from "utils/http";
import App from "./App.jsx";
import store from "./app/store";
import theme from "./theme/theme";
import history from "./app/history";

setupInterceptor(store);

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Provider store={store}>
                    <Router history={history}>
                        <App />
                    </Router>
                </Provider>
            </BrowserRouter>
        </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
