import { createUseStyles } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { setPassComplexcity } from "routes/AuthenticationRoute/AuthenticationSlice";
import { useDispatch } from "react-redux";

const useStyles = createUseStyles(() => ({
    validationChips: {
        backgroundColor: "#00812B",
        borderRadius: "7px",
        padding: "4px",
        color: "white",
        marginRight: 20,
    },
    failValidationChips: {
        backgroundColor: "#F5F5F5",
        color: "#666666",
        borderRadius: "7px",
        padding: "4px",
        marginRight: 20,
    },
    rowValidations: {
        marginBottom: "20px !important;",
    },
    warningList: {
        borderTop: "1px solid #D7D7D7",
        paddingTop: 10,
        "& li": {
            color: "#212121",
        },
    },
}));

const PasswordComlexity = ({ value }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const passwordComplexityChecks = {
        checkForLength: false,
        checkForNumber: false,
        checkForLower: false,
        checkForUpper: false,
        checkForSpecial: false,
    };
    let {
        checkForLength,
        checkForNumber,
        checkForLower,
        checkForUpper,
        checkForSpecial,
    } = passwordComplexityChecks;

    var regularExpression = /^(?=.*[!"#$%&'()*+,-./@])/g;
    var NotregularExpression = /^(?=.*[:;<=>?[\]^_`{|}~])/g;

    if (value) {
        if (value.length >= 8) {
            checkForLength = true;
        }
        if (value.match(/[0-9]/) && value.replace(/[^0-9]/g, "").length >= 1) {
            checkForNumber = true;
        }
        if (value.match(/[a-z]/) && value.replace(/[^a-z]/g, "").length >= 1) {
            checkForLower = true;
        }
        if (value.match(/[A-Z]/) && value.replace(/[^A-Z]/g, "").length >= 1) {
            checkForUpper = true;
        }
        if (
            regularExpression.test(value) &&
            !NotregularExpression.test(value)
        ) {
            checkForSpecial = true;
        }

        if (
            checkForLength &&
            checkForNumber &&
            checkForLower &&
            checkForUpper &&
            checkForSpecial
        ) {
            dispatch(setPassComplexcity(false));
        } else {
            dispatch(setPassComplexcity(true));
        }
        // if (value.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/) && value.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g).length >= 1) {
        //     checkForSpecial = true;
        // }
    }

    return (
        <div>
            <div className={classes.rowValidations}>
                <span
                    className={
                        checkForUpper
                            ? classes.validationChips
                            : classes.failValidationChips
                    }
                >
                    <FontAwesomeIcon
                        icon={checkForUpper ? faCheck : faWindowClose}
                        color={checkForUpper ? "white" : "#C00000"}
                        size="1x"
                    />{" "}
                    1 Uppercase Character
                </span>
                <span
                    className={
                        checkForLower
                            ? classes.validationChips
                            : classes.failValidationChips
                    }
                >
                    <FontAwesomeIcon
                        icon={checkForLower ? faCheck : faWindowClose}
                        color={checkForLower ? "white" : "#C00000"}
                        size="1x"
                    />{" "}
                    1 Lowercase Character
                </span>
            </div>
            <div className={classes.rowValidations}>
                <span
                    className={
                        checkForNumber
                            ? classes.validationChips
                            : classes.failValidationChips
                    }
                >
                    <FontAwesomeIcon
                        icon={checkForNumber ? faCheck : faWindowClose}
                        color={checkForNumber ? "white" : "#C00000"}
                        size="1x"
                    />{" "}
                    1 Numeric Value
                </span>
                <span
                    className={
                        checkForSpecial
                            ? classes.validationChips
                            : classes.failValidationChips
                    }
                >
                    <FontAwesomeIcon
                        icon={checkForSpecial ? faCheck : faWindowClose}
                        color={checkForSpecial ? "white" : "#C00000"}
                        size="1x"
                    />{" "}
                    1 Special Character
                </span>
                <span
                    className={
                        checkForLength
                            ? classes.validationChips
                            : classes.failValidationChips
                    }
                >
                    <FontAwesomeIcon
                        icon={checkForLength ? faCheck : faWindowClose}
                        color={checkForLength ? "white" : "#C00000"}
                        size="1x"
                    />{" "}
                    8 Minimum Length
                </span>
            </div>
            <ul className={classes.warningList}>
                <li>Do not repeat any of the last 15 passwords used</li>
                <li>
                    The password should not contain the user’s account name or
                    display name
                </li>
                <li>
                    The password should contain at least one of these special
                    characters ! " @ # $ % & ' ( ) * + , - . /
                </li>
            </ul>
        </div>
    );
};

export default PasswordComlexity;
