/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import toast from "components/@bank-ui/core/Toast/Toast";
import { FEATURED_MODULES_MAKRKET } from "constants/global";
let userObject = JSON.parse(sessionStorage.getItem("userObject"));

const initialState = {
    loading: false,
    firstName: userObject != undefined? userObject.firstName : null,
    lastName: userObject != undefined? userObject.lastName : null,
    token: userObject != undefined? userObject.token : null,
    sessionExpire: false,
    tokenExpirationTime: null,
    lastLoginDate: userObject != undefined? userObject.lastLoginDate:null,
    accessList: userObject != undefined? userObject.accessList : null,
    activeSelectedProduct: userObject != undefined? userObject.activeSelectedProduct : "", // or 'cuanalyst'
    isContractActive: true,
    showChangePassword: false,
    dataAfterValidation: null,
    changePasswordStatus: '',
    forceChangePassword: false,
    role: userObject != undefined? userObject.role : "",
    restrictedNewMenuMarket:[],
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    passComplexcity: true,
    versionNumberForApi: userObject != undefined? userObject.versionNumberForApi : null,
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setSessionExpire: (state, action) => {
            state.sessionExpire = action.payload;
        },
        setActiveSelectedProduct: (state, action) => {
            const currentUser = JSON.parse(sessionStorage.getItem("userObject"));
            if(currentUser){
                const newUserObject = {
                    ...currentUser,
                    activeSelectedProduct: action.payload
                }
                sessionStorage.setItem("userObject", JSON.stringify(newUserObject));
            }
            state.activeSelectedProduct = action.payload;
        },
        setUser: (state, { payload }) => {
            const {
                firstName,
                lastName,
                token,
                tokenExpirationTime,
                lastLoginDate,
                loginDate,
                accessList,
                role,
                userName,
                preferredProduct,
                versionNumberForApi
            } = payload;
            return {
                ...state,
                firstName,
                lastName,
                token,
                tokenExpirationTime,
                lastLoginDate,
                loginDate,
                accessList,
                role,
                userName,
                preferredProduct,
                versionNumberForApi
            };
        },
        logout: () => {
            sessionStorage.clear();
            window.location.reload();
        },
        setContractActive: (state, {payload}) => {
            state.isContractActive = payload;
        },
        setShowChangePassword: (state) => {
            state.showChangePassword = !state.showChangePassword;
        },
        setDataAfterValidation: (state, { payload }) => {
            state.dataAfterValidation = payload;
        },
        setChangePasswordStatus: (state, { payload }) => {
            state.changePasswordStatus = payload;
        },
        setForceChangePassword: (state, { payload }) => {
            state.forceChangePassword = payload;
        },
        setVersionNumberForApi: (state, {payload}) => {
            state.versionNumberForApi = payload;
        },
        setNewFilterdNav: (state, { payload }) => {
            state.restrictedNewMenuMarket = payload;
        },
        setCurrentPassword: (state, { payload }) => {
            state.currentPassword = payload;
        },
        setNewPassword: (state, { payload }) => {
            state.newPassword = payload;
        },
        setConfirmPassword: (state, { payload }) => {
            state.confirmPassword = payload;
        },
         setPassComplexcity: (state, { payload }) => {
            state.passComplexcity = payload;
        },
    },
});

export const {
    setLoading,
    setToken,
    setActiveSelectedProduct,
    setUser,
    logout,
    setSessionExpire,
    setContractActive,
    setShowChangePassword,
    setDataAfterValidation,
    setChangePasswordStatus,
    setForceChangePassword,
    setNewFilterdNav,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    setPassComplexcity,
    setVersionNumberForApi,
} = authenticationSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const refreshToken = (userName, token) => (dispatch, getState) => {
    // await dispatch(setLoading(true));
    // todo-cj : replace base URL with env variable
    axios(`${import.meta.env.VITE_BASE_URL}/user/refreshtoken`, {
        method: "POST",
        data: {
            token: token,
            username: userName,
        },
    })
        .then(({ data }) => {
            if (!data.token) {
                dispatch.push("/");
                return;
            } else {
                dispatch(setToken(data.token));
                userObject = JSON.parse(sessionStorage.getItem("userObject"));
                userObject.token = data.token;
                sessionStorage.setItem("userObject", JSON.stringify(userObject));
                setTimeout(() => {
                            dispatch(refreshToken(userName, data.token));
                        }, 26 * 60 * 1000);
            }
            // dispatch(setLoading(false));
        })
        .catch((error) => {
            errorHandler(getErrorMessage(error));
            dispatch(setSessionExpire(true));
            sessionStorage.clear();
            window.location.reload();
            // dispatch(setLoading(false));
            return error;
        });
};

export const authenticateUser = (userName, password, history) => async (
    dispatch
) => {
    await dispatch(setLoading(true));
    // todo-cj : replace base URL with env variable
    axios(`${import.meta.env.VITE_BASE_URL}/user/authenticate`, {
        method: "POST",
        data: {
            username: userName,
            password: password,
        },
    })
        .then(({ data }) => {
            if (!data.token) {
                dispatch.push("/");
                return;
            } else {

                let userObject = {};

                if (data.changePassword) {
                    dispatch(setShowChangePassword());
                    userObject.showChangePassword = true;
                }
                dispatch(setForceChangePassword(data.changePassword));
                dispatch(setToken(data.token));
                dispatch(setContractActive(data.contractActive));
                dispatch(setActiveSelectedProduct(data.preferredProduct));
                dispatch(setVersionNumberForApi(data.version));
                dispatch(setUser({ ...data, userName, versionForApi: data.version }));
                // save in session Storage
                userObject.forceChangePassword = data.changePassword;
                userObject.token = data.token;
                userObject.contractActive = data.contractActive;
                userObject.activeSelectedProduct = data.preferredProduct;
                userObject.userName = userName;
                userObject.firstName = data.firstName;
                userObject.lastName = data.lastName;
                userObject.tokenExpirationTime = data.tokenExpirationTime;
                userObject.lastLoginDate = data.lastLoginDate;
                userObject.loginDate = data.loginDate;
                userObject.accessList = data.accessList;
                userObject.role = data.role;
                userObject.versionNumberForApi = data.version

                sessionStorage.setItem("userObject", JSON.stringify(userObject));
                setTimeout(() => dispatch(refreshToken(userName, data.token)), 26 * 60 * 1000);
            }
            dispatch(setLoading(false));
        })
        .catch((error) => {
            errorHandler(getErrorMessage(error));
            dispatch(setLoading(false));
            return error;
        });
};

export const getForgottenPassword = (userName, history) => async (
    dispatch
) => {
    await dispatch(setLoading(true));
    axios(`${import.meta.env.VITE_BASE_URL}/user/forgotPassword`, {
        method: "POST",
        data: {
            username: userName,
        },
    })
        .then(({ data }) => {
            dispatch(setLoading(false));
            history.push("/");
            toast.success({
                message: data.message,
            });
        })
        .catch((error) => {
            errorHandler(getErrorMessage(error));
            dispatch(setLoading(false));
            return error;
        });
};

export const validatePassword = (userName, password) => async (
    dispatch
) => {
    await dispatch(setLoading(true));
    // todo-cj : replace base URL with env variable
    axios(`${import.meta.env.VITE_BASE_URL}/user/authenticate`, {
        method: "POST",
        data: {
            username: userName,
            password: password,
        },
    })
        .then(({ data }) => {
            toast.success({
                message: "Password Validated Successfully",
            });
            dispatch(setDataAfterValidation(true));
            dispatch(setLoading(false));
        })
        .catch((error) => {
            if (error.response.data.message === 'Username or password incorrect.') {
                errorHandler(getErrorMessage({message:'Old Password entered is invalid. Try again.'}));
            } else if (error.response.data.message === 'User account is Locked. Please contact support team at bi.clientrelations@fiserv.com.') {
                errorHandler(getErrorMessage({message: error.response.data.message}));
                dispatch(logout());
            } else {
                errorHandler(getErrorMessage(error));
            }
            dispatch(setLoading(false));
            return error;
        });
};

export const changePassword = (token, currentPassword, newPassword) => async (
    dispatch, getState
) => {
    const { forceChangePassword } = getState().authentication;
    await dispatch(setLoading(true));
    // todo-cj : replace base URL with env variable
    axios(`${import.meta.env.VITE_BASE_URL}/user/changePassword`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            oldPassword: currentPassword,
            newPassword: newPassword,
        },
    })
        .then(({ data }) => {
            dispatch(setChangePasswordStatus(data.status));
            if (data.status === "SUCCESS") {
                dispatch(setCurrentPassword(''));
                if (forceChangePassword) {
                    dispatch(logout());
                }
                toast.success({
                    message: "Password Changed Successfully."
                });
                dispatch(setDataAfterValidation(''));
                dispatch(setShowChangePassword());
            } else if (data.errors[0] === 'PASSWORD_HISTORY_VALIATION_FAILED'){
                toast.error({
                    message: "Password can not be same as previously used password."
                });
            } else if(data.errors[0] === 'PASSWORD_COMMON_STRINGS_VALIDATION_FAILED') {
                toast.error({
                    message: "Password can not contain the user’s account name or display name."
                });
            } else if(data.errors[0] === 'PASSWORD_PATTERN_VALIDATION_FAILED') {
                toast.error({
                    message: "Password does not adhere to password policy."
                });
            } else {
                toast.error({
                    message: data.errors[0]
                });
            }
            dispatch(setLoading(false));
        })
        .catch((error) => {
            errorHandler(getErrorMessage(error));
            dispatch(setLoading(false));
            return error;
        });
};


export const restrictedMenuList = (subNavItems, restricted , id) => async (dispatch, getState) => {
    
    const newSubmenu = [];
    const { role } = getState().authentication;
    await dispatch(setLoading(true));
    if (id == "market" && restricted && subNavItems.length > 0) {

        for (let i = 0; i < subNavItems.length; i++) {
            restricted.find((item, index) => { 
                if (FEATURED_MODULES_MAKRKET[item.feature] == subNavItems[i].id && item.accessible !== true) {
                    newSubmenu.push(subNavItems[i]);
                }
                
                if (role !== "ADMIN" 
                    && (subNavItems[i].id === "MarketBO"
                    || subNavItems[i].id === "MarketExpansion"
                    ))
                {
                    newSubmenu.push(subNavItems[i]);
                }

            });           
        }
    }
    if (newSubmenu.length > 0 && id == "market") {
        let latest = subNavItems;
        
        for (let i = 0; i < newSubmenu.length; i++) {
            latest = latest.filter((item, index) => (item.id !== newSubmenu[i].id || item.id == 'marketReports'));
        }
        dispatch(setNewFilterdNav(latest));
    } else {
        dispatch(setNewFilterdNav(subNavItems));
    }

};

// The function below is called a selector, and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getAuthLoading = (state) => state.authentication.loading;
export const isAuthenticated = ({ authentication }) => !!authentication.token;
export const token = ({ authentication }) => authentication.token;
export const accessList = ({ authentication }) => authentication.accessList;
export const bankAnalystCustomDownloadAccess = ({ authentication }) => authentication.accessList[0].modules[0].restrictedFeatures[0].accessible;
export const cuaAnalystCustomDownloadAccess = ({ authentication }) => authentication.accessList[1].modules[0].restrictedFeatures[0].accessible;


export const activeSelectedProduct = ({ authentication }) =>
    authentication.activeSelectedProduct;
export const getAuthenticatedUser = (state) => state.authentication;
export const getAccessList = (state) => state.authentication;
export const getProductPreference = (state)=> state.preferredProduct;
export const contract_active = (state) => state.authentication.isContractActive;
export const show_change_password = (state) => state.authentication.showChangePassword;
export const data_after_validation = (state) => state.authentication.dataAfterValidation;
export const change_password_status = (state) => state.authentication.changePasswordStatus;
export const force_change_password = (state) => state.authentication.forceChangePassword;
export const marketCustomdownload = ({ authentication }) => authentication.accessList[0].modules[0].restrictedFeatures[0].accessible;
export const bankAnalystFinancialRestrictedFeatures = ({ authentication }) => authentication.accessList[0].modules[0].restrictedFeatures;
export const cuaAnalystFinancialRestrictedFeatures = ({ authentication }) => authentication.accessList[1].modules[0].restrictedFeatures;
export const bankAnalystMarketRestrictedFeatures = ({ authentication }) => authentication.accessList[0].modules[1].restrictedFeatures;
export const cuaAnalystMarketRestrictedFeatures = ({ authentication }) => authentication.accessList[1].modules[1].restrictedFeatures;
export const restrictedNewMenuMarket_auth = (state) => state.authentication.restrictedNewMenuMarket;
export const current_password = (state) => state.authentication.currentPassword;
export const new_password = (state) => state.authentication.newPassword;
export const confirm_password = (state) => state.authentication.confirmPassword;
export const complexcity_password = (state) => state.authentication.passComplexcity;
export const isRoleAdmin = (state) => state.authentication.role;
export const versionForApi = ({ authentication }) => authentication.versionNumberForApi;



export default authenticationSlice.reducer;
