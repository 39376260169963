import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "routes/MarketRoute/selectors";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import { transformShape } from "routes/MarketDataReviewMap/utils/transformShape";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    minRange:0,
    maxRange:999999,
    mapBranches: [],
    mapFullBranches: [],
};

const marketMEAMapSlice = createSlice({
    name: "marketMEAMap",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setMinRange(state, { payload }) {
            return {
                ...state,
                minRange: payload,
            };
        },
        setMapRange(state, { payload }) {
            return {
                ...state,
                maxRange: payload,
            };
        },
        setMapBranches(state, { payload }) {
            return {
                ...state,
                mapBranches: payload,
            };
        },
        setMapFullBranches(state, { payload }) {
            return {
                ...state,
                mapFullBranches: payload,
            };
        },
        resetMarketMEAMap() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setMapBranches,
    setMapFullBranches,
    resetMarketMEAMap,
} = marketMEAMapSlice.actions;

export function fetchMarketMEAMapBranches(driverId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        await dispatch(setMapBranches([]));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            listType,
        } = getMarketSelectedFilters(getState());

        const params = {
            dma: reportDMA,
            listType,
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/expansion/driverId/${driverId}/map`,
                { params }
            )
            .then(({ data }) => {

                const transformedData = _.map(data, (dataItem) => {
                    return _.assign(
                        {},
                        {
                            type: "Feature",
                            name: "branches",
                            properties: { ...dataItem }, 
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    dataItem.latitude,
                                    dataItem.longitude,
                                ],
                            },
                            blockData: { ...transformShape(dataItem.shape) },
                        }
                    );
                });               
                
                dispatch(setMapBranches([...transformedData]));

                dispatch(setMapFullBranches([...transformedData]));

            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

export default marketMEAMapSlice.reducer;
