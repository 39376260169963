import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import toast from "components/@bank-ui/core/Toast/Toast";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "components/addToReportMenu/selectors";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    reports: [],
    reportDetails: [],
    reportsData: [],
    addOutput: [],
    tabSelected: 0,
    templateView: false,
};

const customReportsSlice = createSlice({
    name: "customReports",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        saveCustomReports(state, { payload }) {
            return {
                ...state,
                reports: payload,
            };
        },
        setReportDetails(state, { payload }) {
            return {
                ...state,
                reportDetails: payload,
            };
        },
        reSetReportDetails(state, { payload }) {
            return {
                ...state,
                reportDetails: [],
                addOutput:[],
            };
        },
        setReportsData(state, { payload }) {
            return {
                ...state,
                reportsData:  [...state.reportsData, ...payload,],
                addOutput:[],
            };
        },
         reSetReportsData(state, { payload }) {
            return {
                ...state,
                reportsData:  [],
                addOutput:[],

            };
        },
        setAddoutPut(state, { payload }) {
            return {
                ...state,
                addOutput: [...state.addOutput, ...payload,]
            };
        },
        resetMarketReports() {
            return {
                ...initialState,
            };
        },
        setSelectedTab(state, { payload }) {
            return {
                ...state,
                tabSelected: payload
            };
        },
        setTemplateView(state, { payload }) {
            return {
                ...state,
                templateView: payload,
            };
        }
    },
});

export const {
    setLoading,
    saveCustomReports,
    setReportDetails,
    reSetReportDetails,
    setReportsData,
    reSetReportsData,
    resetMarketReports,
    setAddoutPut,
    setSelectedTab,
    setTemplateView,
} = customReportsSlice.actions;

/**
 * fetch list of all reports (custom)
 */
export function fetchCustomReports(tab) {
    return async (dispatch, getState) => {
        const { peerGroupId, reportId } = getState().reports;

        await dispatch(setLoading(true));
        const currentModule = getMarketCurrentModule(getState());
        const templateType = parseInt(tab) ? "GLOBAL" : "CUSTOM";
        await https
           .get(`${VITE_API_BASE_URL}/api/v1/${currentModule}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/downloadTemplates`)
            .then(({ data }) => {
                dispatch(
                    saveCustomReports(
                        data.filter(
                            (template) => template.templateType === templateType
                        )
                    )
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
        dispatch(setLoading(false));
    };
}

/**
 * delete a report based on templateId (custom)
 * @param {*} templateId
 */
export function deleteCustomReport(templateId, tab) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));
        const currentModule = getMarketCurrentModule(getState());
        const { peerGroupId, reportId } = getState().reports;
        await https
            .delete(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/downloadTemplates/${templateId}`
            )
            .then(({ status }) => {
                toast.success({
                    header: "Delete successful",
                    message: "Template is deleted successfully",
                });
                dispatch(fetchCustomReports(tab));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading(false));
    };
}

/**
 * get report details based on templateId (custom)
 * returns { templateName, templateDescription, templateValue, pageCount }
 * @param {*} templateId
 */
export const fetchCustomReportDetails = (templateId) => async (
    dispatch,
    getState
) => {
    await dispatch(setLoading(true));
    const { peerGroupId, reportId } = getState().reports;
    
    const currentModule = getMarketCurrentModule(getState());
    // const { reportGrouping, reportBranches } = getMarketSelectedFilters(getState());

        return await https.get(
            `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/downloadTemplates/${templateId}`
        ).then(({ data }) => {

        dispatch(setReportDetails(data));
        dispatch(setLoading(false));
        }).catch((error) =>{
        errorHandler(getErrorMessage(error));
        dispatch(setLoading(false));
        return { status: error.status, data: null };
    })
};

/**
 * get complete reports data bsed on pages array (custom)
 * returns [{ pageName, headers, rows }]
 * @param {*} pages
 */

export const endpointsFunction =(endpoints, pageName, number, pages, pagesCounter)=> async (dispatch, getState) => {
                    const { reportGrouping, reportBranches } = getMarketSelectedFilters(getState());
                    const { templateView, reportDetails } = getState().customReport;
                    const { accountingType, reportingPeriod } = getState().reports;
                    // Getting the sort value from sessionStorage to keep track of changes either coming from page refresh or sorting change
                    let sortValueForEndpoints;
                    if(sessionStorage.getItem('sortValue1')){
                        sortValueForEndpoints = sessionStorage.getItem('sortValue1')
                    } else {
                        sortValueForEndpoints = 'DEFAULT'
                    }
                    

                    // This conditional code logic could be seperated to another function and be used here just by calling it with relevant parameters but,
                    // To avoid decreasing code readibility between pages and call stack flow, keeping it here for now.
                    // This code block helps endpoints to distinguish which sorting parameter to update with what other sorting parameter.
                    // Manipulating only 'DEFAULT' sorting order creates another issues in overall application sanity.
                    let endpoinCounter = number ? number : 0;

                    if(endpoints[endpoinCounter].includes("sort=DEFAULT")){
                        endpoints[endpoinCounter] = endpoints[endpoinCounter].replace("_REPORTID_", reportGrouping).replace("_BRANCHID_", reportBranches).replace("RESTATED", accountingType).replace("ASREPORTED", accountingType).replace("ANNUAL", reportingPeriod).replace("QUARTERLY", reportingPeriod).replace("sort=DEFAULT", `sort=${sortValueForEndpoints}`);
                    } else if(endpoints[endpoinCounter].includes("sort=COST_BENEFIT")){
                        endpoints[endpoinCounter] = endpoints[endpoinCounter].replace("_REPORTID_", reportGrouping).replace("_BRANCHID_", reportBranches).replace("RESTATED", accountingType).replace("ASREPORTED", accountingType).replace("ANNUAL", reportingPeriod).replace("QUARTERLY", reportingPeriod).replace("sort=COST_BENEFIT", `sort=${sortValueForEndpoints}`);
                    } else if(endpoints[endpoinCounter].includes("sort=-COST_BENEFIT")){
                        endpoints[endpoinCounter] = endpoints[endpoinCounter].replace("_REPORTID_", reportGrouping).replace("_BRANCHID_", reportBranches).replace("RESTATED", accountingType).replace("ASREPORTED", accountingType).replace("ANNUAL", reportingPeriod).replace("QUARTERLY", reportingPeriod).replace("sort=-COST_BENEFIT", `sort=${sortValueForEndpoints}`);
                    } else{
                        endpoints[endpoinCounter] = endpoints[endpoinCounter].replace("_REPORTID_", reportGrouping).replace("_BRANCHID_", reportBranches).replace("RESTATED", accountingType).replace("ASREPORTED", accountingType).replace("ANNUAL", reportingPeriod).replace("QUARTERLY", reportingPeriod)
                    }

            
                        return https.get(`${VITE_API_BASE_URL}${_.startsWith(endpoints[endpoinCounter], "api") && "/"}${endpoints[endpoinCounter]}` )
                            .then(({ data }) => {
                                if(!_.isEmpty(reportDetails)){


                                dispatch(setAddoutPut([{
                                    endpoint: `${VITE_API_BASE_URL}${_.startsWith(endpoints[endpoinCounter], "api") && "/"}${endpoints[endpoinCounter]}`,
                                    pageName,
                                    Count:1,
                                    pdf: data,
                                }]));
                                }
                        // eslint-disable-next-line eqeqeq
                        if(templateView == true){
                             endpoinCounter = endpoinCounter+1;
                            if(endpoinCounter < endpoints.length){
                                dispatch(endpointsFunction(endpoints, pageName, endpoinCounter, pages, pagesCounter));
                            }else{
                                const { addOutput } = getState().customReport;
                                    dispatch(setReportsData([{pageName, output: addOutput}]));
                                   let pagesCounters = pagesCounter+1;
                                     if(pagesCounters < pages.length){
                                          dispatch(fetchCustomReportData(pages, pagesCounters));
                                      }
                            }

                        }
                            }).catch((error) => {
                                    errorHandler(getErrorMessage(error));
                                    dispatch(setLoading(false));
                            });
                };


export const fetchCustomReportData = (pages, number) => async (dispatch, getState) => {
    await dispatch(setLoading(true));
    if (!pages || pages.length === 0) {
        errorHandler(
            getErrorMessage({ error: { message: "No reports found!" } })
        );
        return { status: 400, data: pages };
    }

    try {
        const output = [];
        let pagesCounter = number ? number : 0;
        const { pageName, endpoints = [] } = pages[pagesCounter];

            if (endpoints && endpoints.length > 0) {
                        await dispatch(endpointsFunction(endpoints, pageName, 0, pages, pagesCounter));

                }
        dispatch(setLoading(false));
        return { status: 200, data: output };
    } catch (error) {
        errorHandler(getErrorMessage(error));
        dispatch(setLoading(false));
        return { status: error.status, data: null };
    }
};


export const custom_reportDetails = ({ customReport }) => customReport.reportDetails;
export const custom_selectedTab = ({ customReport }) => customReport.tabSelected;
export const custom_template_View = ({ customReport }) => customReport.templateView;



export default customReportsSlice.reducer;
