/* eslint-disable import/no-anonymous-default-export */
import { toast } from "react-toastify";
import styled from "styled-components";
import { ReactComponent as CheckCircleIcon } from "../../../../assets/icons/check-circle.svg";
import { ReactComponent as ExclamationIcon } from "../../../../assets/icons/exclamation.svg";
import { ReactComponent as ExclamationTriangleIcon } from "../../../../assets/icons/exclamation-triangle.svg";
import { ReactComponent as InfoCircleIcon } from "../../../../assets/icons/info-circle.svg";
import { ReactComponent as TimesHexagonIcon } from "../../../../assets/icons/times-hexagon.svg";
import { pxToRem } from "../../../../utils/getStyles";
import Svg from "../Svg";

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-left: 12px;

    svg {
        fill: ${(p) => p.theme.palette.common.white};
        height: 24px;
        width: 24px;
        margin-right: 16px;
    }
`;

const ToastContent = styled.div`
    display: flex;
    flex-direction: column;

    h4 {
        padding: 0;
        margin: 0;
        padding-bottom: 2px;
        font-size: ${pxToRem(12)};
    }

    p {
        margin: 0;
        padding: 0;
        font-size: ${pxToRem(16)};
    }
`;

function Toast({ type, header, message }) {
    let icon = ExclamationIcon;

    if (type === "success") icon = CheckCircleIcon;
    if (type === "info") icon = InfoCircleIcon;
    if (type === "warning") icon = ExclamationTriangleIcon;
    if (type === "error") icon = TimesHexagonIcon;

    return (
        <Container>
            <Svg SvgComponent={icon} />
            <ToastContent>
                <h4>{header}</h4>
                <p>{message}</p>
            </ToastContent>
        </Container>
    );
}

export default {
    success: ({ header, message, config: { autoClose = true } = {} }) => {
        const toastId = toast.success(
            <Toast type="success" header={header} message={message} />,
            {
                autoClose,
            }
        );
        return toastId;
    },
    error: ({ header, message, config: { autoClose = true } = {} }) => {
        const customId = "custom-id-yes";
        const toastId = toast.error(
            <Toast type="error" header={header} message={message} />,
            {
                autoClose,
                toastId: customId,
            }
        );
        return toastId;
    },
    warning: ({ header, message, config: { autoClose = true } = {} }) => {
        const toastId = toast.warning(
            <Toast type="warning" header={header} message={message} />,
            {
                autoClose,
            }
        );
        return toastId;
    },
    info: ({ header, message, config: { autoClose = true } = {} }) => {
        const toastId = toast.info(
            <Toast type="info" header={header} message={message} />,
            {
                autoClose,
            }
        );
        return toastId;
    },
    default: ({ header, message, config: { autoClose = true } = {} }) => {
        const toastId = toast(
            <Toast type="default" header={header} message={message} />,
            {
                autoClose,
            }
        );
        return toastId;
    },
    dismiss: (toastId) => {
        if (toastId) toast.dismiss(toastId);
        else toast.dismiss();
    },
};
