import { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { generateClassNames } from "utils/generateClassNames";

const useStyles = createUseStyles((theme) => ({
    button: {
        background: theme.colors.primary.default,
        border: 0,
        borderRadius: 4,
        color: theme.colors.white,
        padding: "8px 25px",
        cursor: "pointer",
        fontSize: theme.typography.button2.fontSize,
        fontWeight: "bold",
        margin: 5,
        "&:focus": {
            outline: "none",
        },
        "&:disabled": {
            backgroundColor: theme.colors.greys.disabled,
            color: theme.colors.white,
        },
        "&[type=submit]": {
            boxShadow: "none",
        },
        "& invert": {
            border: "1px solid #FF6600",
            padding: "7px 24px",
            color: theme.colors.greys.neutral,
            background: "none",
        },
    },
}));

export default function Button({
    disabled,
    label,
    onClick,
    inverted,
    icon,
    type,
    loader,
    color,
    size,
    className: classNameProp,
    ...props
}) {
    const classes = useStyles();
    const [disable, makeDisabled] = useState(false);
    const buttonClasses = generateClassNames(classes.button, classNameProp, {
        [classes.submit]: makeDisabled,
    });

    const preventDouble = (e) => {
        if (!onClick) return; // if no onClick is passed, we assume here that the button instance is to allow default behavior, and we abort the onClick call

        // makeDisabled(true);
        onClick && onClick(e);
        // setTimeout(() => {
        //   makeDisabled(false);
        // }, 1500);
    };

    return (
        <button
            type={type}
            disabled={disabled || disable}
            className={buttonClasses}
            {...props}
            onClick={preventDouble}
        >
            {icon ? (
                <FontAwesomeIcon icon={icon} color={color} size={size} />
            ) : null}
            {`${label}` || `Submit`}
            {loader && (
                <FontAwesomeIcon
                    style={{ marginLeft: 10 }}
                    icon={faCircleNotch}
                    spin
                    size="1x"
                />
            )}
        </button>
    );
}

Button.defaultProps = {
    className: undefined,
    disabled: false,
    label: undefined,
    type: "button",
};

Button.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
};
