export const getMarketFiltersValidity = (state) => {
    const {
        reportGrouping,
        reportBranches,
        reportDMA,
    } = state.market.root.selected;

    return reportGrouping && reportBranches && reportDMA;
};

export const getMarketSelectedFilters = (state) => state.market.root.selected;

export const getMarketEndpointFilters = (state) => state.market.marketFilters;

export const getMarketInstitutionTree = (state) =>
    state.market.root.filters.reportBranches;

export const getMarketCurrentModule = (state) =>
    state.market.root.currentModule;

export const getMarketLoading = (state) => state.market.root.loading;

export const getMarketFilters = (state) => state.market.root.filters;

export const getMarketTreeOptions = (state) => state.market.root.treeOptions;

export const getMarketReportName = (state) => state.addToReports.reportName;
export const getMarketRatioTab = (state) => state.addToReports.ratioTab;

export const getMarketReportPages = (state) => state.addToReports.pages;