/**
 * Utility to assign depth for nested children object
 * @param {*} arr 
 * @param {*} depth 
 */

export const assignDepth = (arr, depth = 0) => {
    arr.forEach((obj) => {
        obj.depth = depth;
        assignDepth(obj.children, depth + 1);
    });
};
