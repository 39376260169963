import { createSlice } from '@reduxjs/toolkit';
import https from 'utils/http';
import { isNullOrUndefined } from 'utils/isNullOrUndefined';
import ACCOUNTING_TYPE from 'utils/accountingType';
import REPORTING_PERIOD from 'utils/reportingPeriod';
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import { PATHS_BA, PATHS_CUA } from "constants/paths";
import { fetchPerformanceDashBoardSort } from "routes/DashboardRoute/DashboardSlice";
import { findDeepNestedValue } from 'utils/findDeepNestedValue';
const sessionReportId = JSON.parse(sessionStorage.getItem("reportid"));
const sessionReportName = JSON.parse(sessionStorage.getItem("reportName"));
const sessionPeerGroupId = JSON.parse(sessionStorage.getItem("peerGroupId"));
const sessionbankAnalyst= JSON.parse(sessionStorage.getItem("bankAnalyst"));
const initialState = {
  bankAnalyst: sessionbankAnalyst !== undefined? sessionbankAnalyst : 'ba',
  unAthorizeReport: true,
  reportId: sessionReportId !== undefined? sessionReportId : null,
  reportName: sessionReportName !== undefined? sessionReportName : null,
  selectedReport: {},
  periodOfReporting: {},
  selectedRatio: null,
  reportsList: [],
  reportListByGlobalSearch: [],
  peerGroupId: sessionPeerGroupId !== undefined? sessionPeerGroupId : null,
  peerGroupName: null,
  peerGroupList: [],
  peerGroupsReport: {},
  ratioHierarchyList: {},
  ratioHierarchy: {},
  summaryList: {},
  accountingType: ACCOUNTING_TYPE.ASREPORTED,
  reportingPeriod: REPORTING_PERIOD.QUARTERLY,
  // TODO: move to new controller
  productsList: [],
  loading: false,
  summaryLoading: false,
  peerListLoading: false,
  reportsViewData: {},
  statementsOption: {},
  calculationReport: {},
  reportStartTime: null,
  viewDataTableValues: {},
  statementOptionSelected: { name: 'incomeStatement', label: 'Income Statement' },
  reportViewSelectedBy: 'institution',
  ratioDrawer: false,
  search: false,
  customPeerCompleted: false,
  ratioTabs:0,
  CSVDataForReports: [],
  showReportUpdated: false,
  versionChange: false,
  rangeForCSV: {},
  selectedRatioTable: "1",
  ratioHierarchyTypes: [],
  errorOccuredForStatementData: false 
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setRatioDrawer: (state, { payload }) => {
      state.ratioDrawer = payload;
    },
     setUnAthorizeReport(state, { payload }) {
            return {
                ...state,
                unAthorizeReport: payload.value,
            };
        },
    setBankAnalyst: (state, { payload }) => {
      sessionStorage.setItem("bankAnalyst", JSON.stringify(payload));
      state.bankAnalyst = payload;
      state.selectedReport = {};
      state.loading = false;
    },
    setRatioHierarchyTypes: (state, { payload }) => {
      state.ratioHierarchyTypes = payload;
    },
    setReportId: (state, { payload }) => {
      sessionStorage.setItem("reportid", JSON.stringify(payload.reportId));
      sessionStorage.setItem("reportName", JSON.stringify(payload.reportName));
      state.reportId = payload.reportId;
      state.reportName = payload.reportName;
      state.ratioHierarchy = {};
      state.search = payload.search ? payload.search : false;
      state.loading = false;
    },
     setPeriodOfReporting: (state, { payload }) => {
      state.periodOfReporting = payload;
      state.loading = false;
    },
    setReportsList: (state, { payload }) => {
      state.reportsList = payload;
      state.loading = false;
    },
    setErrorOccuredForStatementData: (state, { payload }) => {
      state.errorOccuredForStatementData = payload
    },
    setReportsListByGlobalSearch: (state, { payload }) => {
      state.reportListByGlobalSearch = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSummaryLoading:  (state, { payload }) => {
      state.summaryLoading = payload;
    },
    setPeerListLoading:  (state, { payload }) => {
      state.peerListLoading = payload;
    },
    setPeerGroupReport: (state, { payload }) => {
      state.peerGroupsReport = payload;
      state.peerGroupName = payload.peerGroupName;
      state.loading = false;
    },
    setPeerGroupId: (state, { payload }) => {
      sessionStorage.setItem("peerGroupId", JSON.stringify(payload));
      state.peerGroupId = payload;
      // state.loading = false;
    },
    setPeerGroupList: (state, { payload }) => {
      state.peerGroupList = payload;
      // state.loading = false;
    },
    setRatioHierarchyList: (state, { payload }) => {


      state.ratioHierarchyList = payload;
      state.loading = false;
    },
    setRatioHierarchy: (state, { payload }) => {
      state.ratioHierarchy = payload;
      state.loading = false;
    },
    setSummary: (state, { payload }) => {
      state.summaryList = payload;
      state.loading = false;
    },
    setAccountingType: (state, { payload }) => {
      state.accountingType = payload;
      state.loading = false;
    },
    setReportingPeriod: (state, { payload }) => {
      state.reportingPeriod = payload;
      state.loading = false;
    },
    // TODO: move to new controller
    setProducts: (state, { payload }) => {
      state.productsList = payload;
      state.loading = false;
    },
    setReportsViewData: (state, { payload }) => {
      state.reportsViewData = payload;
      state.loading = false;
    },
    setViewDataStatementsOption: (state, { payload }) => {
      state.statementsOption = payload;
      state.loading = false;
    },
     setCalculationReport: (state, { payload }) => {
      state.calculationReport = payload;
      state.loading = false;
    },
    setCalculationReportTimer: (state, { payload }) => {
      state.reportStartTime = payload;
    },
    updateViewDataTable: (state, { payload }) => {
      state.viewDataTableValues = payload;
    },
    setStatementOptionName: (state, { payload }) => {
      state.statementOptionSelected = payload;
      // state.loading = false;
    },
    setReportViewSelectedBy: (state, { payload }) => {
      state.reportViewSelectedBy = payload;
    },
    setSelectedReport: (state, { payload }) => {
      state.selectedReport = payload;
    },
    setSelectedRatio: (state, { payload }) => {
      state.selectedRatio = payload;
    },
    setCustomPeerCompleted: (state, { payload }) => {
      state.customPeerCompleted = payload;
    },
    setRatioTabs : (state, { payload }) => {
      state.ratioTabs = payload;
    },
    setCSVDataForReports: (state, { payload }) => {
      state.CSVDataForReports = payload;
    },
    setShowReportUpdated: (state, { payload }) => {
      state.showReportUpdated = payload;
    },
    updateOnVesrionChange: (state, { payload }) => {
      state.versionChange = payload;
    },
    setRangeForCSV: (state, { payload }) => {
      state.rangeForCSV = payload;
    },
    setSelectedRatioTable: (state, {payload}) => {
      state.selectedRatioTable = payload;
    }
  },
  extraReducers: {}
});

export const {
  setRatioDrawer,
  setSelectedRatioTable,
  setUnAthorizeReport,
  setBankAnalyst,
  setStatementOptionName,
  setReportsViewData,
  setViewDataStatementsOption,
  setCalculationReport,
  setCalculationReportTimer,
  updateViewDataTable,
  setRatioHierarchyTypes,
  setProducts,
  setSummary,
  setRatioHierarchy,
  setRatioHierarchyList,
  setPeerGroupList,
  setPeerGroupId,
  setReportsList,
  setReportsListByGlobalSearch,
  setReportId,
  setPeriodOfReporting,
  setPeerGroupReport,
  setReportingPeriod,
  setAccountingType,
  setLoading,
  setSummaryLoading,
  setReportViewSelectedBy,
  setSelectedReport,
  setSelectedRatio,
  setCustomPeerCompleted,
  setRatioTabs,
  setCSVDataForReports,
  setShowReportUpdated,
  updateOnVesrionChange,
  setPeerListLoading,
  setRangeForCSV,
  setErrorOccuredForStatementData
} = reportsSlice.actions;

export const fetchReportsList = () => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports`)
    .then(({ data }) => {
      // set first report Id as reportID in store
      if (data.reports.length) {
        dispatch(setReportId(data.reports[0]));
      }
      dispatch(setReportsList(data.reports));

      // dispatch(push(`/${bankAnalyst}`));
      dispatch(setUnAthorizeReport({value: true}));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      // errorHandler(getErrorMessage(error));
      dispatch(setUnAthorizeReport({value: false}));
      dispatch(setLoading(false));
    });
};

export const fetchRatioHierarchyTypes = () => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { reportId, bankAnalyst } = getState().reports;
  return https
    .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/0/ratioHierarchyTypes`)
    .then(({data}) => {
      const formattedData = data.map(({ratioGroupHierarchyId, ratioGroupHierarchyName}) => ({label: ratioGroupHierarchyName, value: ratioGroupHierarchyId}))
      dispatch(setRatioHierarchyTypes(formattedData))
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
    })
};

export const fetchReportsListByGlobalSearch = (searchString, selectedModule) => (dispatch, getState) => {
 // dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/${selectedModule}/reports`,
      {
        params: {
          search : searchString
        }
      }
  )
    .then(({ data }) => {
      dispatch(setReportsListByGlobalSearch(data.reports));
    })
    .catch((error) => {
      if (error.response.status === 400) {
        errorHandler(getErrorMessage({message:'Validation Failed.'}));
      } else {
        errorHandler(getErrorMessage(error));
      }
      dispatch(setLoading(false));
    });
};

export const fetchReportingPeriod = (report) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${report}`)
    .then(({ data }) => {
       dispatch(setPeriodOfReporting(data));
       dispatch(setRatioHierarchy({}))
       dispatch(setLoading(false));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
       dispatch(setLoading(false));
    });
};

export const fetchPeerGroups = (report, reprtChange) => (dispatch, getState) => {
   if(!reprtChange){
   dispatch(setLoading(true));
   }
  const { bankAnalyst, reportId, reportingPeriod, accountingType, peerGroupId } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${report ? report.reportId : reportId}/peerGroups`
    )
    .then(({ data }) => {
      if (report && data.peerGroupList.length) {
        // eslint-disable-next-line eqeqeq
        const peerExist  = data.peerGroupList.filter((item, index) => item.peerGroupId == peerGroupId);
        if(peerExist.length > 0 && peerGroupId) {
          dispatch(setPeerGroupId(peerGroupId));
        } else {
          dispatch(setPeerGroupId(data.peerGroupList[0].peerGroupId));
        }
        if(reprtChange){
          let sortingValue = sessionStorage.getItem('sortValue1')
          let sortValueForPageRefresh = sortingValue || 'DEFAULT';
         dispatch(fetchPeerGroupsReport(data.peerGroupList[0].peerGroupId));
            dispatch(
                fetchPerformanceDashBoardSort(
                    report.reportId,
                    data.peerGroupList[0].peerGroupId,
                    reportingPeriod,
                    accountingType,
                    sortValueForPageRefresh
                )
            );
        }
      }
      dispatch(setPeerGroupList(data.peerGroupList));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
      dispatch(setLoading(false));
    });
};

export const fetchPeerGroupsReport = (peerGroupId, accountingType, reportingPeriod) => (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  dispatch(setPeerListLoading(true));
  const { reportId, bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}`,
      {
        params: {
          accountingType,
          reportingPeriod
        }
      }
    )
    .then(({ data }) => {
      dispatch(setPeerGroupReport(data));
      dispatch(setLoading(false));
      dispatch(setPeerListLoading(false));
    })
    .catch((error) => {
       dispatch(setLoading(false));
       dispatch(setPeerListLoading(false));
      errorHandler(getErrorMessage(error));
    });
};

export const fetchRatioHierarchyList = (reportId, peerGroupId, reportingPeriod, accountingType) => (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  const { bankAnalyst, selectedRatio, selectedRatioTable } = getState().reports;
  
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/ratioHierarchy`,
      {
        params: {
          accountingType,
          reportingPeriod,
          ratioGroup: selectedRatioTable,
        }
      }
    )
    .then(({ data }) => {
      // dispatch(fetchRatioHierarchyById(reportId, peerGroupId, data.hierarchy.ratioId));
      const isSelectedRatioFound = findDeepNestedValue([data.hierarchy], 'ratioId', `${selectedRatio}`);
      dispatch(setSelectedRatio(isSelectedRatioFound ? selectedRatio : data.hierarchy.ratioId));
      dispatch(setRatioHierarchyList(data.hierarchy));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
       dispatch(setLoading(false));
    });
};

export const fetchRatioHierarchyById = (
  reportId,
  peerGroupId,
  reportingPeriod,
  accountingType,
  ratioSelected
) => (dispatch, getState) => {
  if (isNullOrUndefined(reportId, peerGroupId, reportingPeriod, accountingType)) {
    return;
  }
  dispatch(setLoading(true));
  const { bankAnalyst, selectedRatio } = getState().reports;
  const {reports} = getState();

  const ratioId = selectedRatio ? selectedRatio : (reports.ratioHierarchy.ratioId && reports.ratioHierarchy.ratioId) ? reports.ratioHierarchy.ratioId : reports.ratioHierarchyList.ratioId;
  const ratio_id = !isNullOrUndefined(ratioSelected) ? ratioSelected : ratioId;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/ratioHierarchy/${ratio_id}`,
      {
        params: {
          accountingType,
          reportingPeriod
        }
      }
    )
    .then(({ data }) => {
      dispatch(setRatioHierarchy(data))
      // dispatch(setRatioHierarchyList(data));
      dispatch(setSelectedRatio(ratio_id));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
       dispatch(setLoading(false));
    });
};

export const fetchSummaryList = (reportId, reportingPeriod, accountingType) => (
  dispatch,
  getState
) => {
  dispatch(setSummaryLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/summary`,
      {
        params: {
          accountingType,
          reportingPeriod
        }
      }
    )
    .then(({ data }) =>{
       dispatch(setSummary(data))
       dispatch(setSummaryLoading(false));
      })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
      dispatch(setSummaryLoading(false));
    });
};

export const fetchProducts = () => (dispatch, getState) => {
  const { bankAnalyst } = getState().reports;
  return https
    .get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products`)
    .then(({ data }) => dispatch(setProducts(data.products)))
    .catch((error) => {
      errorHandler(getErrorMessage(error));
       dispatch(setLoading(false));
    });
};

export const getReportsViewData = (reportId, peerGroupId, reportingPeriod, accountingType) => (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/data/incomeStatement`,
      {
        params: {
          accountingType,
          reportingPeriod
        }
      }
    )
    .then(({ data }) => dispatch(setReportsViewData(data)))
    .catch((error) => {
      errorHandler(getErrorMessage(error));
       dispatch(setLoading(false));
    });
};

export const getViewDataStatementsOption = (reportId, peerGroupId) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/data`
    )
    .then(({ data }) => {
      dispatch(setViewDataStatementsOption(data));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
      dispatch(setViewDataStatementsOption({}));
      dispatch(setLoading(false));
    });
};

export const getCalculationReport = (reportId, selectedPeerGroupId, location, peerDetails) => (dispatch, getState) => {

  const timeDiff = new Date() / 60 / 1000;
  const { bankAnalyst, reportingPeriod, accountingType, peerGroupId, reportStartTime } = getState().reports;
  if(reportStartTime === null){
  dispatch(setCalculationReportTimer(timeDiff));
  }
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${selectedPeerGroupId}/calculation`
    )
    .then(({ data }) => {
      dispatch(setCalculationReport(data));
      if(data.status === 'CALCULATED' || data.status === 'COMPLETED'){
          dispatch(setCalculationReportTimer(null));
        if(!peerDetails){
          dispatch(setPeerGroupId(selectedPeerGroupId));
         dispatch(fetchPeerGroupsReport((peerDetails ? peerGroupId : selectedPeerGroupId)));
       }
        dispatch(setLoading(false));
         if (!isNullOrUndefined(peerGroupId) && (location.pathname === PATHS_BA.FINANCIAL_RATIOS || location.pathname === PATHS_CUA.FINANCIAL_RATIOS)){
        dispatch(fetchRatioHierarchyById(reportId, (peerDetails ? peerGroupId : selectedPeerGroupId), reportingPeriod, accountingType));
         // This dispatch action is designed to update dashboard data if client changes peer group selection while on ratio page
              let sortingValue = sessionStorage.getItem('sortValue1')
              let sortValueForPageRefresh = sortingValue || 'DEFAULT';
            dispatch(fetchPerformanceDashBoardSort(reportId, peerDetails ? peerGroupId : selectedPeerGroupId, reportingPeriod, accountingType, sortValueForPageRefresh));
        } else if (!isNullOrUndefined(peerGroupId) && (location.pathname === PATHS_BA.FINANCIAL || location.pathname === PATHS_CUA.FINANCIAL)) {
          let sortingValue = sessionStorage.getItem('sortValue1')
          let sortValueForPageRefresh = sortingValue || 'DEFAULT';
          dispatch(
            fetchPerformanceDashBoardSort(
                reportId,
                peerDetails ? peerGroupId : selectedPeerGroupId,
                reportingPeriod,
                accountingType,
                sortValueForPageRefresh
            )
          );
        }

      }else if(data.status === 'ERROR'){
          dispatch(setLoading(false));
          errorHandler(getErrorMessage({message: 'Timeout: Not able to retrive data'}));
      }else{
        const waitingTime = timeDiff - reportStartTime;
        if(reportStartTime !== null && waitingTime >= 5 ){
           dispatch(setPeerGroupId(peerGroupId));
            dispatch(setLoading(false));
            errorHandler(getErrorMessage({message: 'Timeout: Not able to retrive data'}));
        }else{
         dispatch(setLoading(true));
              setTimeout(() => {
                dispatch(getCalculationReport(reportId, selectedPeerGroupId, location, peerDetails));
            }, 1000);
        }
      }
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
      dispatch(setLoading(false));
    });
};

export const getStatementData = (reportId, peerGroupId, option, reportingPeriod, accountingType) => (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setErrorOccuredForStatementData(false));
  const { bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/data/${option.name}`,
      {
        params: {
          accountingType,
          reportingPeriod
        }
      }
    )
    .then((response) => {
      if(!response.ok) dispatch(setErrorOccuredForStatementData(true));
      dispatch(setReportsViewData(response?.data));
      // dispatch(setStatementOptionName(option));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
      dispatch(setLoading(false));
      dispatch(setReportsViewData({}));
      dispatch(setErrorOccuredForStatementData(true));
    })
    .finally(() => dispatch(setLoading(false)))
};

export const clearDataCombineReducer = (reportId, peerGroupId, option, reportingPeriod, accountingType) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/data/${option.name}`,
      {
        params: {
          accountingType,
          reportingPeriod
        }
      }
    )
    .then(({ data }) => {
      dispatch(setReportsViewData(data));
      // dispatch(setStatementOptionName(option));
    })
    .catch((error) => {
      errorHandler(getErrorMessage(error));
      dispatch(setLoading(false));
    });
};
export const loadingIndicator = (status) => (dispatch,) => {
 dispatch(setLoading(status));
};

export const reports_id = ({ reports }) => reports.reportId;
export const period_Of_Reporting = ({ reports }) => reports.periodOfReporting;
export const reports_Search = ({ reports }) => reports.search;
export const reports_Name = ({ reports }) => reports.reportName;
export const reports_list = ({ reports }) => reports.reportsList;
export const report_list_global_search = ({ reports }) => reports.reportListByGlobalSearch;
export const peer_group_id = ({ reports }) => reports.peerGroupId;
export const peer_groups_name = ({ reports }) => reports.peerGroupName;
export const peer_group_list = ({ reports }) => reports.peerGroupList;
export const peer_groups_report = ({ reports }) => reports.peerGroupsReport;
export const ratio_hierarchy_list = ({ reports }) => reports.ratioHierarchyList;
export const ratio_id = ({ reports }) => reports.ratioHierarchyList.ratioId;
export const ratio_hierarchy = ({ reports }) => reports.ratioHierarchy;
export const summary = ({ reports }) => reports.summaryList;
export const products = ({ reports }) => reports.productsList;
export const reporting_period = ({ reports }) => reports.reportingPeriod;
export const accounting_type = ({ reports }) => reports.accountingType;
export const selected_Peer_GroupId = ({ reports }) => reports.selectedPeerGroupId;
export const loading = ({ reports }) => reports.loading;
export const summaryLoading = ({ reports }) => reports.summaryLoading;
export const peer_list_loading = ({ reports }) => reports.peerListLoading;
export const reports_view_data = ({ reports }) => reports.reportsViewData;
export const statements_Option_view_Data = ({ reports }) => reports.statementsOption;
export const calculation_Report = ({ reports }) => reports.calculationReport;
export const statements_Option_Selected = ({ reports }) => reports.statementOptionSelected;
export const view_data_table_values = ({ reports }) => reports.viewDataTableValues;
export const view_data_Report_Selected = ({ reports }) => reports.reportViewSelectedBy;
export const selectedBankAnalyst = ({ reports }) => reports.bankAnalyst;
export const toggleRatioDrawer = ({ reports }) => reports.ratioDrawer;
export const selected_report = ( { reports }) => reports.selectedReport;
export const selected_Ratio = ( { reports }) => reports.selectedRatio;
export const custom_peer_completed = ({ reports }) => reports.customPeerCompleted;
export const selected_Ratio_Tab = ({ reports }) => reports.ratioTabs;
export const csv_data_forReports = ({ reports }) => reports.CSVDataForReports;
export const show_report_updated = ({ reports }) => reports.showReportUpdated;
export const version_change = ({ reports }) => reports.versionChange;
export const range_for_csv = ({ reports }) => reports.rangeForCSV;
export const getSelectedRatioTable = ({ reports }) => reports.selectedRatioTable;
export const getRatioHierarchyTypes = ({ reports }) => reports.ratioHierarchyTypes;
export const getErrorOccuredForStatementData = ({ reports }) => reports.errorOccuredForStatementData;

export const reportChange = ({ dispatch }) => (next) => (action) => {
  if (action.type === setReportId.type) {
    dispatch(fetchPeerGroups(action.payload));
  }
  return next(action);
};


export default reportsSlice.reducer;
