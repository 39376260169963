import { createSlice } from "@reduxjs/toolkit";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "routes/MarketRoute/selectors";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: {
        summary: false,
        sw: false,
        tt: false,
    },
    summary: {},
    sw: {
        reportId: null,
        branchId: null,
        dashboardName: null,
        strength: [],
        weakness: [],
    },
    toolTipDefinedMarketAreas: {},
};

const marketSummarySlice = createSlice({
    name: "marketSummary",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload.api]: payload.value,
                },
            };
        },
        setMarketSummary(state, { payload }) {
            return {
                ...state,
                summary: payload,
            };
        },
        setMarketSW(state, { payload }) {
            return {
                ...state,
                sw: payload,
            };
        },
        setToolTipDefinedMarketAreas(state, { payload }) {
            return {
                ...state,
                toolTipDefinedMarketAreas: payload,
            };
        },
        resetMarketSummary() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setMarketSummary,
    setMarketSW,
    setToolTipDefinedMarketAreas,
    resetMarketSummary,
} = marketSummarySlice.actions;

export function getMarketSummaryOverview(url) {
    return async (dispatch, getState) => {
        await dispatch(setLoading({ api: "summary", value: true }));

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, reportBranches } = getMarketSelectedFilters(
            getState()
        );

        const api =
            url ||
            `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/summary`;
        await https
            .get(api)
            .then(({ data }) => {
                dispatch(setMarketSummary(data));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading({ api: "summary", value: false }));
    };
}

export function getMarketSummarySW() {
    return async (dispatch, getState) => {
        await dispatch(setLoading({ api: "sw", value: true }));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
        } = getMarketSelectedFilters(getState());

        const params = {
            dma: reportDMA,
        };

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/dashboard/strengthAndWeakness`,
                { params }
            )
            .then(({ data }) => {
                dispatch(setMarketSW(data));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading({ api: "sw", value: false }));
    };
}

export function getToolTipDefinedMarketAreas() {
    return async (dispatch, getState) => {
        await dispatch(setLoading({ api: "tt", value: true }));

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, showBranchByName } = getMarketSelectedFilters(getState());

        const params = {          
            showBranchBy: showBranchByName ? "NAME": "ADDRESS"  
        }; 

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/definedMarketAreas`,
                { params }
            )
            .then(({ data }) => {

                let dataModified = [...data];

                dataModified.forEach((item)=> {
                    item.modifiedRadius = item.modifiedRadius? parseFloat(item.modifiedRadius).toFixed(2).toString(): parseFloat(item.recommendedRadius).toFixed(2).toString(); 
                    if(item.dmaRegion === "POLYGON")
                        item.modifiedRadius = "POLYGON"
                });

                dispatch(
                    setToolTipDefinedMarketAreas({
                        institutionData: data,
                        headers: [
                            { id: "branchId", label: "branchId" },
                            { id: "address", label: "Address" },
                            { id: "city", label: "City" },
                            { id: "state", label: "State" },
                            { id: "modifiedRadius", label: "Custom Radius" },
                            { id: "dmaHouseholds", label: "DMA Households" },
                            { id: "businessEstimates", label: "Bus Est" },
                            {
                                id: "householdsAndBusinessEstimates",
                                label: "Households & Bus Est",
                            },
                        ],
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setLoading({ api: "tt", value: false }));
    };
}

export default marketSummarySlice.reducer;
