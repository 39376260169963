import { combineReducers, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

// util
import https from "../../utils/http";
import { errorHandler, getErrorMessage } from "../../utils/errorHandler";
import { refitKeys } from "../../utils/refitKeys";
import { assignDepth } from "../../utils/assignDepth";

//reducers
import marketdataReviewReducer from "../MarketDataReview/marketDataReviewSlice";
import marketSummaryReducer from "../MarketSummary/marketSummarySlice";
import marketAddToReportsReducer from "./marketAddToReportsSlice";
import marketBranchMatrixSlice from "../MarketExecutiveSummaryMatrix/marketBranchMatrixSlice";
import marketQuantifyReducer from "../MarketQuantify/marketQuantifySlice";
import marketRecommendationReducer from "../MarketRecommendation/marketRecommendationSlice";
import marketReportsReducer from "../MarketReports/marketReportsSlice";
import marketProspectListReducer from "../MarketProspectList/marketProspectListSlice";
import marketFiltersReducer from "./marketFiltersSlice";
import marketBudgetReducer from "../MarketBudgeting/marketBudgetingSlice";
import marketFileEnhancement from "../MarketFileEnhancement/marketFileEnhancementSlice";
import marketCustomDMAReducer from "../MarketCustomDma/marketCustomDmaSlice";
import marketMEAReducer from "../MarketMEA/marketMEASlice";
import marketBOReducer from "../MarketBO/marketBOSlice";
import marketMEAMapReducer from "../MarketMEAMap/marketMEAMapSlice";
import marketBOMapReducer from "../MarketBOMap/marketBOMapSlice";


// selectors
import {
    getMarketCurrentModule,
    getMarketFilters,
    getMarketSelectedFilters,
} from "./selectors";

// constants
import { PRIMARY_MODULES } from "constants/global";

const { VITE_API_BASE_URL } = import.meta.env;

let marketRootSelected = JSON.parse(sessionStorage.getItem("marketRootSelected"));

if(!marketRootSelected)
{
    sessionStorage.setItem("marketRootSelected", JSON.stringify({
        reportGrouping: undefined,
        reportBranches: null,
        reportDMA: null,
        listType: null,
        ratioId: "1000",
        region:"REGION",
        showBranchByName: false,
        sort: {
                sortColumnNumber: 0,
                columnSortOrder: "DESCENDING"
        }
    }));
}

marketRootSelected = JSON.parse(sessionStorage.getItem("marketRootSelected"));

const initialState = {
    currentModule: PRIMARY_MODULES.BANK_ANALYST,
    unAthorizeReport: true,
    loading: {
        page: false,
        reportGrouping: false,
        reportBranches: false,
        reportDMA: false,
        components:false
    },
    filters: {
        reportGrouping: [],
        reportBranches: [],
        reportDMA: [],
    },
    selected: {
        reportGrouping: marketRootSelected? marketRootSelected.reportGrouping: undefined,
        reportBranches: marketRootSelected? marketRootSelected.reportBranches: undefined,
        reportDMA: marketRootSelected? marketRootSelected.reportDMA: undefined,
        listType: marketRootSelected? marketRootSelected.listType: null,
        ratioId: marketRootSelected? marketRootSelected.ratioId: "1000",
        region:  marketRootSelected? marketRootSelected.region: "REGION",
        showBranchByName: false,
        sort: {
                sortColumnNumber: 0,
                columnSortOrder: "ASCENDING"
        },
        report: {},
        mea:null,
    },
    treeOptions: {
        expanded: ["1000", "2000", "3000", "157000", "982000", "989000"],
    },
    flags: {
        reportGroupingChanged:false,
        mapLoaded:false
    }
};

const marketSlice = createSlice({
    name: "market",
    initialState,
    reducers: {
        setMarketCurrentModule(state, { payload }) {
            return {
                ...state,
                currentModule: payload,
            };
        },
        setUnAthorizeReport(state, { payload }) {
            return {
                ...state,
                unAthorizeReport: payload.value,
            };
        },
        setMarketFiltersLoading(state, { payload }) {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload.api]: payload.value,
                },
            };
        },
        setMarketFlags(state, { payload }) {
            return {
                ...state,
                flags: {
                    ...state.flags,
                    [payload.api]: payload.value,
                },
            };
        },
        setMarketComponentsLoading(state, { payload }) {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    components: payload,
                },
            };
        },
        setMarketSecondaryFiltersLoading(state, { payload }) {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    reportBranches: payload,
                    reportDMA: payload,
                },
            };
        },
        setMarketFilters(state, { payload }) {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.api]: payload.value,
                },
            };
        },
        setMarketSelectedFilters(state, { payload }) {

            let rootSelected = JSON.parse(sessionStorage.getItem("marketRootSelected"));
            rootSelected[payload.api] = payload.value;
            sessionStorage.setItem("marketRootSelected", JSON.stringify(rootSelected));

            return {
                ...state,
                selected: {
                    ...state.selected,
                    [payload.api]: payload.value,
                },
            };
        },
        setMarketSelectedRatioId(state, { payload }) {

            let rootSelected = JSON.parse(sessionStorage.getItem("marketRootSelected"));
            rootSelected.ratioId = payload;
            rootSelected.listType = "";
            sessionStorage.setItem("marketRootSelected", JSON.stringify(rootSelected));

            return {
                ...state,
                selected: {
                    ...state.selected,
                    ratioId: payload,
                    listType: "",
                },
            };
        },
        setMarketSelectedReportGrouping(state, { payload }) {

            let rootSelected = JSON.parse(sessionStorage.getItem("marketRootSelected"));
            rootSelected.reportGrouping = payload.value;
            rootSelected.reportBranches = null;
            rootSelected.reportDMA = null;
            sessionStorage.setItem("marketRootSelected", JSON.stringify(rootSelected));

            return {
                ...state,
                selected: {
                    ...state.selected,
                    reportGrouping: payload.value,
                    reportBranches: null,
                    reportDMA: null,
                },
            };
        },
        setMarketSelectedReportGroup(state, { payload }) {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    report: payload,
                    mea:payload.mea,
                },
            };
        },
        setShowBranchByName(state, { payload }) {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    showBranchByName: payload,
                },
            };
        },
        setMarketTreeOptionExpansion(state, { payload }) {
            let expanded = [...state.treeOptions.expanded];
            let index = expanded.indexOf(payload);

            if (index >= 0) {
                expanded.splice(index, 1);
            } else {
                expanded.push(payload);
            }

            return {
                ...state,
                treeOptions: {
                    expanded: expanded,
                },
            };
        },
        resetMarketRoot(state) {
            const regionPersist = JSON.parse(sessionStorage.getItem("marketRootSelected"))?.region;
            sessionStorage.setItem("marketRootSelected", JSON.stringify({
                reportGrouping: null,
                reportBranches: null,
                reportDMA: null,
                listType: null,
                ratioId: "1000",
                region: regionPersist,
                showBranchByName: false,
                sort: {
                        sortColumnNumber: 0,
                        columnSortOrder: "DESCENDING"
                }
                }));

            marketRootSelected = JSON.parse(sessionStorage.getItem("marketRootSelected"));
            const currModule =  JSON.parse(sessionStorage.getItem("bankAnalyst"));
            return {
                ...initialState,
                currentModule: currModule,
                selected: {
                    ...initialState.selected,
                    region: regionPersist
                }
            };
        },
    },
});

export const {
    setMarketCurrentModule,
    setUnAthorizeReport,
    setMarketFiltersLoading,
    setMarketSecondaryFiltersLoading,
    setMarketFilters,
    setMarketSelectedFilters,
    setMarketSelectedRatioId,
    setMarketSelectedReportGroup,
    setMarketSelectedReportGrouping,
    setMarketTreeOptionExpansion,
    setShowBranchByName,
    resetMarketRoot,
    setMarketComponentsLoading,
    setMarketFlags
} = marketSlice.actions;

export function fetchMarketReportGroupings() {
    return async (dispatch, getState) => {
        let result = {};
        await dispatch(
            setMarketFiltersLoading({ api: "reportGrouping", value: true })
        );

        const currentModule = getMarketCurrentModule(getState());

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports`
            )
            .then(({ status, data }) => {
                const { reports = [] } = data;
                let reportGrouping = [];
                _.forEach(reports, function (report) {
                    const { reportId, reportName, certificateId } = report;
                    reportGrouping = _.concat(reportGrouping, {
                        id: reportId,
                        label: reportName,
                        value: reportId,
                        certificateId,
                    });
                });

                dispatch(
                    setMarketFilters({
                        api: "reportGrouping",
                        value: reportGrouping,
                    })
                );
                dispatch(setUnAthorizeReport({value: true}));
            })
            .catch((error) => {
                dispatch(setUnAthorizeReport({value: false}));
                // errorHandler(getErrorMessage(error));
            });

        dispatch(
            setMarketFiltersLoading({ api: "reportGrouping", value: false })
        );
        return result;
    };
}

export function fetchMarketReportBranches(reportId) {
    return async (dispatch, getState) => {
        await dispatch(setMarketSecondaryFiltersLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const { showBranchByName } = getMarketSelectedFilters(getState());

        const params = _.assign(
            {},
            showBranchByName && { showBranchBy: "NAME" }
        );

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportId}/branches`,
                { params }
            )
            .then(({ data }) => {
                const keysNew = ["id", "label"];
                const keysOld = ["branchId", "branchName"];
                const newBranches = refitKeys(
                    data,
                    (key) => keysNew[keysOld.indexOf(key)] || key
                );
                assignDepth(_.concat([], newBranches));
                dispatch(
                    setMarketFilters({
                        api: "reportBranches",
                        value: _.concat([], newBranches),
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketSecondaryFiltersLoading(false));
    };
}

export function fetchMarketReportGroup(reportId) {
    return async (dispatch, getState) => {
        await dispatch(setMarketSecondaryFiltersLoading(true));

        const currentModule = getMarketCurrentModule(getState());

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportId}`,
            )
            .then(({ data }) => {
                dispatch(
                    setMarketSelectedReportGroup(data)
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketSecondaryFiltersLoading(false));
    };
}

export function fetchMarketReportDMA(reportId) {
    return async (dispatch, getState) => {
        await dispatch(setMarketSecondaryFiltersLoading(true));

        const currentModule = getMarketCurrentModule(getState());

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportId}/dma`
            )
            .then(({ data }) => {
                let reportDMA = [];
                _.forEach(data, function (dmaItem) {
                    const { radius, name } = dmaItem;
                    reportDMA = _.concat(reportDMA, {
                        id: radius,
                        label: name,
                        value: radius,
                    });
                });
                dispatch(
                    setMarketFilters({
                        api: "reportDMA",
                        value: reportDMA,
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketSecondaryFiltersLoading(false));
    };
}

export function fetchMarketOtherFilters(reportId) {
    return async (dispatch) => {
        await dispatch(fetchMarketReportGroup(reportId));
        await dispatch(fetchMarketReportBranches(reportId));
        await dispatch(fetchMarketReportDMA(reportId));
    };
}

export function setDefaultOtherFilters() {
    return (dispatch, getState) => {
        const { reportBranches } = getMarketFilters(getState());
        const { id } = reportBranches[0] || {};

        dispatch(
            setMarketSelectedFilters({
                api: "reportBranches",
                value: marketRootSelected.reportBranches? marketRootSelected.reportBranches: id,
            })
        );

        dispatch(
            setMarketSelectedFilters({ api: "reportDMA", value: marketRootSelected.reportDMA? marketRootSelected.reportDMA : "CUSTOM" })
        );
    };
}

export function initiateMarketFilters() {
    return async (dispatch, getState) => {
        await dispatch(setMarketFiltersLoading({ api: "page", value: true }));

        // eslint-disable-next-line no-unused-vars
        const { status } = await dispatch(fetchMarketReportGroupings());

        const { reportGrouping } = getMarketFilters(getState());
        const { id: reportId } = reportGrouping[0] || {};

        if(reportGrouping.length){
        await dispatch(setMarketSelectedReportGrouping({ value: reportId }));
        await dispatch(fetchMarketOtherFilters(reportId));

            await dispatch(setDefaultOtherFilters());
        }

        dispatch(setMarketFiltersLoading({ api: "page", value: false }));
        }
    };

export default combineReducers({
    root: marketSlice.reducer,
    summary: marketSummaryReducer,
    dataReview: marketdataReviewReducer,
    addToReports: marketAddToReportsReducer,
    branchMatrix: marketBranchMatrixSlice,
    quantify: marketQuantifyReducer,
    recommendation: marketRecommendationReducer,
    marketFilters: marketFiltersReducer,
    prospectList: marketProspectListReducer,
    reports: marketReportsReducer,
    budgeting: marketBudgetReducer,
    fileEnhancement: marketFileEnhancement,
    customDMA:marketCustomDMAReducer,
    marketMEA:marketMEAReducer,
    marketMEAMap:marketMEAMapReducer,    
    marketBO:marketBOReducer,
    marketBOMap:marketBOMapReducer
});
