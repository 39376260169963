import { useEffect, useState } from "react";
import _ from "lodash";
import {
    Button,
    CircularProgress,
    FormControl,
    TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/lab/Autocomplete";
import { blue } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PATHS_BA, PATHS_CUA } from "constants/paths";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SearchIcon } from "assets/icons/fa-search.svg";
import {
    accounting_type,
    fetchReportsListByGlobalSearch,
    reporting_period,
    report_list_global_search,
    setReportId,
    selected_report,
    setSelectedReport,
    fetchPeerGroupsReport,
    peer_group_list,
    fetchReportingPeriod,
    selectedBankAnalyst,
    fetchReportsList,
} from "routes/ReportsRoute/ReportsSlice";
import { fetchPerformanceDashBoardSort } from "routes/DashboardRoute/DashboardSlice";
import { getAuthenticatedUser } from "routes/AuthenticationRoute/AuthenticationSlice";
import useDebounce from "hooks/useDebounce";
import {
    fetchMarketOtherFilters,
    setMarketSelectedReportGrouping,
    setDefaultOtherFilters,
    fetchMarketReportGroupings,
    setMarketSelectedRatioId,
} from "routes/MarketRoute/marketSlice";
import { MARKET_FILTERS } from "constants/market";
import { resetMarketSummary } from "routes/MarketSummary/marketSummarySlice";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        minWidth: 360,
    },
    select__formcontrol__root: {
        minWidth: 90,
        height: 50,
        border: "1px solid rgba(0, 0, 0, 0.12)",
        color: "#808080",
        fontSize: 10,
        padding: "5px 0px 0px 5px",
        "& div": {
            fontSize: 14,
            color: "#212121",
            fontWeight: "600",
        },
    },
    select__inputlabel__filled: {
        "&.MuiInputLabel-shrink": {
            fontSize: theme.typography.pxToRem(12),
            textTransform: "uppercase",
        },
    },
    select__icon: {
        height: 24,
        width: 12,
        marginLeft: 8,
        marginRight: 8,
        fill: "currentColor",
    },
    select__menu__list: {
        padding: 4,
    },
    select__menu__list__item: {
        "&:hover": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: blue[50],
        },
    },
    select__filledinput__root: {
        borderRadius: theme.shape.borderRadius,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: "transparent",
        "&.Mui-focused": {
            backgroundColor: "transparent",
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    autocomplete__formcontrol__root: {
        minWidth: 264,
        height: 50,
    },
    autocomplete__textfield__root: {
        margin: 0,
        height: 50,
    },
    autocomplete__textfield__filledinput__root: {
        borderRadius: 0,
        height: 50,
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        border: `1px solid ${theme.palette.divider}`,
        borderLeft: 0,
        backgroundColor: "transparent",
        "&.Mui-focused": {
            backgroundColor: "transparent",
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    searchbutton__container: {},
    searchbutton__root: {
        height: 50,
        width: 60,
        minWidth: 60,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        "& svg": {
            height: 20,
            width: 20,
            fill: "currentColor",
        },
    },
}));

export default function HeaderSearchBar() {
    const history = useHistory();
    const reportListGlobalSearch = useSelector(report_list_global_search);
    const peerGroupList = useSelector(peer_group_list);
    const reportingPeriod = useSelector(reporting_period);
    const accountingType = useSelector(accounting_type);
    const selectedReport = useSelector(selected_report);
    const selectBaCua = useSelector(selectedBankAnalyst);
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const [searchType, setSearchType] = useState("");
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [autoCompleteKey, setAutoCompleteKey] = useState(false);
    const { role } = useSelector((state) => getAuthenticatedUser(state));

    useEffect(() => {
        reportListGlobalSearch &&
            reportListGlobalSearch.length > 0 &&
            setOptions(reportListGlobalSearch);
        if (options.length <= 0) {
            setAutoCompleteKey(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportListGlobalSearch, peerGroupList]);

    const loading =
        open && inputValue && inputValue.length > 2 && options.length === 0;

    useEffect(() => {
        _.includes(pathname, "financial") && setSearchType("financial");
        _.includes(pathname, "market") && setSearchType("market");
    }, [pathname]);

    const debouncedInput = useDebounce(inputValue, 500);

    function handleAutoCompleteSearch(input) {
        dispatch(fetchReportsListByGlobalSearch(input, searchType));
        setInputValue("");
    }

    useEffect(() => {
        debouncedInput &&
            debouncedInput.length > 2 &&
            handleAutoCompleteSearch(debouncedInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInput]);

    async function handleSearch() {
        if (
            pathname ===
                `/${selectBaCua}/financial/peerSelection/customePeers` ||
            pathname === `/${selectBaCua}/financial/peerSelection/peerPreview`
        ) {
            if (selectBaCua === "cua") {
                await history.push(`${PATHS_CUA.FINANCIAL_PEERSELECTION}`);
            } else {
                await history.push(`${PATHS_BA.FINANCIAL_PEERSELECTION}`);
            }
        }
        if (
            searchType === "financial" &&
            !_.isEmpty(selectedReport) &&
            selectedReport.reportId
        ) {
            await dispatch(fetchReportingPeriod(selectedReport.reportId));
            await dispatch(fetchReportsList());
            await dispatch(
                setReportId({
                    reportId: selectedReport.reportId,
                    reportName: selectedReport.reportName,
                    search: true,
                })
            );

            await dispatch(fetchPeerGroupsReport(peerGroupList[0].peerGroupId));
            await dispatch(
                fetchPerformanceDashBoardSort(
                    selectedReport.reportId,
                    peerGroupList[0].peerGroupId,
                    reportingPeriod,
                    accountingType,
                    "DEFAULT"
                )
            );
            setInputValue("");
            setOptions([]);
            setAutoCompleteKey(true);
        }

        if (
            searchType === "market" &&
            !_.isEmpty(selectedReport) &&
            selectedReport.reportId
        ) {
            await dispatch(resetMarketSummary());
            await dispatch(fetchMarketOtherFilters(selectedReport.reportId));
            await dispatch(fetchMarketReportGroupings());
            await dispatch(
                setMarketSelectedReportGrouping({
                    api: MARKET_FILTERS.REPORT_GROUPING,
                    value: selectedReport.reportId,
                })
            );
            await dispatch(setMarketSelectedRatioId("1000"));
            await dispatch(setDefaultOtherFilters(selectedReport.reportId));
        }
        setInputValue("");
        setOptions([]);
        setAutoCompleteKey(true);
    }

    const classes = useStyles();

    if (role === "ADMIN") {
        return (
            <div className={classes.container}>
                <div className={classes.select__formcontrol__root}>
                    Search
                    <div>
                        {_.includes(pathname, "financial")
                            ? "Financial"
                            : "Market"}
                    </div>
                </div>
                <FormControl
                    variant="filled"
                    className={classes.autocomplete__formcontrol__root}
                >
                    <Autocomplete
                        key={autoCompleteKey}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        loading={loading}
                        freeSolo
                        id="global-search-autocomplete"
                        disableClearable
                        onChange={(event, newValue) => {
                            dispatch(setSelectedReport(newValue));
                        }}
                        getOptionLabel={(option) =>
                            `[${option.reportId}] ${option.reportName}`
                        }
                        options={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                margin="dense"
                                variant="filled"
                                placeholder={"Search for Institution"}
                                className={
                                    classes.autocomplete__textfield__root
                                }
                                onChange={(event) => {
                                    const { value } = event.target;
                                    setInputValue(value);
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.autocomplete__textfield__filledinput__root,
                                    },
                                    endAdornment: (
                                        <>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>
                <div className={classes.searchbutton__container}>
                    <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        className={classes.searchbutton__root}
                        onClick={handleSearch}
                    >
                        <SearchIcon />
                    </Button>
                </div>
            </div>
        );
    }
    return null;
}
