/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/** need more improvement in this component so we can use this componet as reusable  pop-up notifications */

import { useEffect, useState } from "react";
// import { withAuth } from 'providers/AuthProvider';
// import IdleSession from 'components/notifications/IdleSession';

const IdleTimeout = ({ logout, idleTime, notificationTime }) => {
    const [sessionNotificationTimer, setSessionNotificationTimer] = useState(
        ""
    );
    const [sessionEndNotification, setSessionEndNotification] = useState(false);
    const [keepLiveSession, setKeepLiveSession] = useState(false);

    let timeoutID;

    let minDiff;
    let endTime;

    const timeInMinuts = (value) => {
        return value / 60 / 1000;
    };
    // Active Session on mousemove / mousedown / keypress / mousewheel / DOMMouseScroll / touchmove / MSPointerMove
    const activeSession = () => {
        startTimer();
    };

    // start notification timer befor session timeout
    const inactiveSession = () => {
        setSessionEndNotification(true);
        let waitingTime;
        let timeDiff = new Date();
        endTime = timeInMinuts(timeDiff);

        waitingTime = setTimeout(() => {
            logout();
        }, 1000 * 60 * notificationTime);

        setKeepLiveSession(false);
        setSessionNotificationTimer(waitingTime);
    };

    // reset time on intraction
    const resetTimer = (e) => {
        clearInterval(timeoutID);
        activeSession();
    };

    // start time on intraction
    const startTimer = () => {
        timeoutID = setTimeout(inactiveSession, 1000 * 60 * idleTime);
        let timeDiff = new Date();
        minDiff = timeInMinuts(timeDiff);

        //if(sessionStorage.length !== 0){
        localStorage.setItem("startIdle", minDiff);
        // }
    };

    //  handle session continue pop-up button
    const handleSessionContinue = () => {
        clearTimeout(sessionNotificationTimer);
        setSessionEndNotification(false);
        setKeepLiveSession(true);
        setSessionNotificationTimer("");
    };

    // handle dismiss button
    const handleSessionLogOut = () => {
        setSessionEndNotification(false);
        setKeepLiveSession(false);
        logout();
    };

    useEffect(() => {
        const sessionIdleTime = localStorage.getItem("startIdle");
        if (!setKeepLiveSession && sessionIdleTime) {
            let currentTime = new Date();
            minDiff = timeInMinuts(currentTime);
            const sessionCheck = minDiff - sessionIdleTime;
            window.addEventListener("beforeunload", function (event) {
                localStorage.setItem("sessionCheck", sessionCheck);
            });
        }
    });

    useEffect(() => {
        document.addEventListener("mousemove", resetTimer, false);
        document.addEventListener("mousedown", resetTimer, false);
        document.addEventListener("keypress", resetTimer, false);
        document.addEventListener("DOMMouseScroll", resetTimer, false);
        document.addEventListener("mousewheel", resetTimer, false);
        document.addEventListener("touchmove", resetTimer, false);
        document.addEventListener("MSPointerMove", resetTimer, false);
        startTimer();
        return () => {
            document.addEventListener("mousemove", resetTimer, false);
            document.addEventListener("mousedown", resetTimer, false);
            document.addEventListener("keypress", resetTimer, false);
            document.addEventListener("DOMMouseScroll", resetTimer, false);
            document.addEventListener("mousewheel", resetTimer, false);
            document.addEventListener("touchmove", resetTimer, false);
            document.addEventListener("MSPointerMove", resetTimer, false);
            clearInterval(timeoutID);
            clearTimeout(sessionNotificationTimer);
        };
    }, [resetTimer]);

    return null;
};

export default IdleTimeout;
