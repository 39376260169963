import { createSlice } from "@reduxjs/toolkit";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import { listTypes } from "constants/market";
import { getQuantifyHeaders } from "utils/getMarketHeaders";
import { transformTableData } from "utils/transformations";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,    
} from "routes/MarketRoute/selectors";

import {
    setMarketComponentsLoading
} from "routes/MarketRoute/marketSlice";

import { getQuantifySelectedListType, getSortedForFirstTime, getSortColumnDetailsQuantify } from "./selectors"

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    listTypes: listTypes,
    selectedListType: "",
    quantifyData: [],
    transformed: [],
    dataSortedByHeader: null,
    sortedForFirstTime: true,
    sortColumnDetailsQuantify: {}
};

const marketQuantifySlice = createSlice({
    name: "marketQuantify",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setSortedForFirstTime(state, { payload }) {
            return {
                ...state,
                sortedForFirstTime: payload
            }
        },
        setSortColumnDetailsQuantify(state, { payload }) {
            return {
                ...state,
                sortColumnDetailsQuantify: payload
            }
        },
        setDataSortedByHeader(state, { payload }) {
            return {
                ...state,
                dataSortedByHeader: payload
            }
        },
        setMarketQuantifySelectedListType(state, { payload }) {
            return {
                ...state,
                selectedListType: payload,
            };
        },
        setMarketQuantifyTableData(state, { payload }) {
            return {
                ...state,
                quantifyData: payload.quantifyData,
                transformed: {
                    ...state.transformed,
                    ...payload.transformed,
                },
            };
        },
        resetMarketQuantifyTable() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setMarketQuantifyTableData,
    resetMarketQuantifyTable,
    setMarketQuantifySelectedListType,
    setDataSortedByHeader,
    setSortedForFirstTime,
    setSortColumnDetailsQuantify
} = marketQuantifySlice.actions;

export function fetchMarketQuantifyTableData() {
    return async (dispatch, getState) => {        
        await dispatch(setMarketComponentsLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            reportDMA,
            region,
            sort,
            showBranchByName
        } = getMarketSelectedFilters(getState());
        const sortedForFirstTime = getSortedForFirstTime(getState());
        
        const params = {
            dma: reportDMA,
            region,
            listType: getQuantifySelectedListType(getState()), 
            showBranchBy: showBranchByName ? "NAME": "ADDRESS"  
        };

        if (sort.sortColumnNumber >= 0 && !sortedForFirstTime) {
            const sortColumnDetailsQuantify = getSortColumnDetailsQuantify(getState());
            if(sortColumnDetailsQuantify){
                params.sortColumnNumber = sortColumnDetailsQuantify.sortNumber;
                params.columnSortOrder = sortColumnDetailsQuantify.sortOrder;
            } else{
                params.sortColumnNumber = 4; 
                params.columnSortOrder  = "DESCENDING";
                dispatch(setSortColumnDetailsQuantify({
                    sortNumber: 4,
                    sortOrder: "DESCENDING"
                }))
            }   
        } else {
                params.sortColumnNumber = 4; 
                params.columnSortOrder  = "DESCENDING";
                dispatch(setSortColumnDetailsQuantify({
                    sortNumber: 4,
                    sortOrder: "DESCENDING"
                }))
                dispatch(setSortedForFirstTime(false))
        }

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/quantification`,
                { params }
            )
            .then(({ data }) => {
                
                let rowIndex = 0;
                let transformedQuantifyData = transformTableData(data).map((item) => {
                    rowIndex++;
                    return {
                            No: rowIndex,
                            ...item,
                    }                  
                });
                const headers = getQuantifyHeaders(data)
                const dataSortedBy = headers.find((eachHeader) => eachHeader.id === `value${params.sortColumnNumber}`)?.label;
                
                dispatch(
                    setDataSortedByHeader(
                        dataSortedBy
                    )
                )

                dispatch(
                    setMarketQuantifyTableData({
                        ...data,
                        quantifyData: transformedQuantifyData,
                        transformed: {
                            headers: [{id:"No", label:"No"}, ...getQuantifyHeaders(data)],
                        },
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        await dispatch(setMarketComponentsLoading(false));
    };
}

export default marketQuantifySlice.reducer;
