export const mapFilters = [
    { id: "branches", label: "Branches", api: "branches" },
    { id: "blockGroups", label: "Block Groups/Zip", api: "blockGroups" },
    { id: "radiusDMA", label: "Radius/DMA", api: "radiusDMA" },
    { id: "competitors", label: "Competitors", api: "competitors" },
];

export const shapes = {
    polygon: "Polygon",
    circle: "Circle",
    rectangle: "Reactangle",
    line: "Line",
    point: "Point",
    multipolygon: "MultiPolygon",
};
