/* eslint-disable eqeqeq */
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
    bankAnalystPrimaryNav,
    cuAnalystPrimaryNav,
} from "constants/bankAnalystPrimaryNav";
import HorizontalNavTab from "./HorizontalNavTab";
import { selectedBankAnalyst } from "routes/ReportsRoute/ReportsSlice";
import { getAuthenticatedUser } from "routes/AuthenticationRoute/AuthenticationSlice";

const Nav = styled.nav``;

export default function Navigation() {
    const selectedType = useSelector(selectedBankAnalyst);
    const { accessList } = useSelector((state) => getAuthenticatedUser(state));

    const BA =
        accessList !== null
            ? accessList[0].modules &&
              accessList[0].modules.filter(
                  (item) =>
                      item.displayName == "MARKET" ||
                      item.displayName == "FINANCIAL"
              )
            : null;
    const CUA =
        accessList !== null
            ? accessList[1].modules &&
              accessList[1].modules.filter(
                  (item) =>
                      item.displayName == "MARKET" ||
                      item.displayName == "FINANCIAL"
              )
            : null;

    const navigator =
        selectedType === "ba" ? bankAnalystPrimaryNav : cuAnalystPrimaryNav;

    const newNavBA =
        BA &&
        navigator.filter((item) => {
            const test = BA.find(
                (obj, index) => obj.displayName == item.label.toUpperCase()
            );
            return test || item.id == "home";
        });

    const newNavCUA =
        CUA &&
        navigator.filter((item) => {
            const test = CUA.find(
                (obj, index) => obj.displayName == item.label.toUpperCase()
            );
            return test || item.id == "home";
        });

    return (
        <Nav>
            {_.map(selectedType === "ba" ? newNavBA : newNavCUA, (route) => {
                if (route.id == "market") {
                    const filter =
                        selectedType === "ba"
                            ? BA?.find(
                                  (item) =>
                                      item.shortName ==
                                      route.label.toUpperCase()
                              ).restrictedFeatures
                            : CUA?.find(
                                  (item) =>
                                      item.shortName ==
                                      route.label.toUpperCase()
                              ).restrictedFeatures;
                    return (
                        <HorizontalNavTab
                            key={route.id}
                            selectedType={selectedType}
                            itemProps={_.assign({}, { route })}
                            restricted={filter ? filter : null}
                        />
                    );
                }
                if (route.id == "finance") {
                    const filter =
                        selectedType === "ba"
                            ? BA?.find(
                                  (item) =>
                                      item.shortName ==
                                      route.label.toUpperCase()
                              ).restrictedFeatures
                            : CUA?.find(
                                  (item) =>
                                      item.shortName ==
                                      route.label.toUpperCase()
                              ).restrictedFeatures;
                    return (
                        <HorizontalNavTab
                            key={route.id}
                            selectedType={selectedType}
                            itemProps={_.assign({}, { route })}
                            restricted={filter ? filter : null}
                        />
                    );
                }
                return (
                    <HorizontalNavTab
                        key={route.id}
                        selectedType={selectedType}
                        itemProps={_.assign({}, { route })}
                    />
                );
            })}
        </Nav>
    );
}

Navigation.propTypes = {};
