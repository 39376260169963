/**
 * Utility to transform the table data (strings to numbers) for purpose of sorting
 */

import _ from "lodash";
import { transformShape } from "routes/MarketDataReviewMap/utils/transformShape";

export function transformStringToNumber(input) {
    if (
        input &&
        typeof input === "string" &&
        input.match(/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*%?$/)
    ) {
        return Number(_.replace(input, /,|%/g, ""));
    }
    return input;
}

export function transformTableData(data) {
    const renewedData = _.map(data, (dataItem) => {
        let renewedObj = {};
        for (const [key, value] of Object.entries(dataItem)) {
            if (_.includes(key, "value")) {
                renewedObj = _.assign(renewedObj, {
                    [key]: value, //transformStringToNumber(value),
                });
            } else {
                renewedObj = _.assign(renewedObj, { [key]: value });
            }
        }
        return renewedObj;
    });

    return renewedData;
}

export function transformMapData(data, dataType) {
    function transformBranches() {
        return _.map(data, (dataItem) => {
            return _.assign(
                {},
                {
                    type: "Feature",
                    name: "branches",
                    properties: { ...dataItem },
                    geometry: {
                        type: "Point",
                        coordinates: [dataItem.latitude, dataItem.longitude],
                    },
                }
            );
        });
    }

    function transformMapRadiusDMA() {

        const transformedRadius = _.map(
            _.filter(data, (item) => !!item.radius),
            (dataItem) => {
                if (dataItem.radius) {
                    return _.assign(
                        {},
                        {
                            type: "Feature",
                            name: "radiusDMA",
                            properties: { ...dataItem },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    dataItem.latitude,
                                    dataItem.longitude,
                                ],
                            },
                        }
                    );
                }
            }
        );

        const transformedBlocks = _.map(
            _.filter(data, (item) => !!item.shape),
            (dataItem) => {
                if (dataItem.shape != null ) { 
                        return _.assign(
                            {},
                            {
                                type: "Feature",
                                name: "radiusBlockGroups",
                                properties: { ...dataItem },
                                geometry: { ...transformShape(dataItem.shape.slice(0, dataItem.shape.length-3) + "))") },
                            }
                        );                            
                }                    
            }
        );

        return {
            blocks: transformedBlocks,
            radius: transformedRadius
        }
    }

    function transformCompetitors() {
        return _.map(data, (dataItem) => {
            return _.assign(
                {},
                {
                    type: "Feature",
                    name: "competitors",
                    properties: { ...dataItem },
                    geometry: {
                        type: "Point",
                        coordinates: [dataItem.latitude, dataItem.longitude],
                    },
                }
            );
        });
    }

    function transformBlockGroupsZip() {

        const transformedBlocks = _.map(data, (dataItem) => {
            return _.assign(
                {},
                {
                    type: "Feature",
                    name: "blockGroups",
                    properties: { ...dataItem },
                    geometry: { ...transformShape(dataItem.shape) },
                }
            );
        });

        const transformedZips = _.map(data, (dataItem) => {
            return _.assign(
                {},
                {
                    type: "Feature",
                    name: "blockGroups",
                    properties: { ...dataItem },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            dataItem.latitude,
                            dataItem.longitude,
                        ],
                    },
                }
            );
        });

        return {
            blocks: [...transformedBlocks],
            zips: [...transformedZips],
        };
    }

    if (dataType === "branches") return transformBranches();
    if (dataType === "radiusAndDMA") return transformMapRadiusDMA();
    if (dataType === "competitors") return transformCompetitors();
    if (dataType === "blockGroupsAndZip") return transformBlockGroupsZip();
}
