export const isNullOrUndefined = (...parameters) => {
    return parameters.some((arg) => arg === undefined || arg === null);
};

export const decodeHTML = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

export const amountFormat = (value) => {
    return new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 6 }).format(value);
};

export const amountUnformat = (value) => {
    // eslint-disable-next-line no-useless-escape
    return value.replace(/[&\/\\#@,+()$~%^a-zA-Z'":*?<>{}]/g, "");
};

export default Object.assign({}, { isNullOrUndefined, decodeHTML });
