/**
 * Utility that accepts a collection, key and value -> returns the matching deeply nested object
 * @param {*} collection
 * @param {*} key
 * @param {*} value
 */

export function findDeepNestedValue(collection, key, value) {
    for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
            if (k === key && v === value) {
                return o;
            }
            if (Array.isArray(v)) {
                const _o = findDeepNestedValue(v, key, value);
                if (_o) {
                    return _o;
                }
            }
        }
    }
}
