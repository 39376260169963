import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import toast from "components/@bank-ui/core/Toast/Toast";
import { getMarketTableData } from "utils/getMarketTableData";
import { getReportDMAParam } from "utils/getReportEndpoints";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
    getMarketFilters
} from "routes/MarketRoute/selectors";
import { pageNames } from "constants/pageNames";
import { findDeepNestedValue } from "utils/findDeepNestedValue";
import {
    setMarketComponentsLoading
} from "routes/MarketRoute/marketSlice";
import {
    getResourceFocusList, getSortColumnDetailsRecommendGrowth, getSortColumnDetailsRecommendExpansion, getSortColumnDetailsRecommendRationalize
} from "routes/MarketRecommendation/selectors";
import {
    fetchMarketRecommendationResourceFocus
} from "routes/MarketRecommendation/marketRecommendationSlice";
import {
    getMatrixListTypes, getSortColumnDetailsMatrix
} from "routes/MarketExecutiveSummaryMatrix/selectors";
import {
    getQuantifyListTypes, getSortColumnDetailsQuantify
} from "routes/MarketQuantify/selectors";

import { RATIOS } from "constants/market";
import { getSortColumnDetailsIdentify } from "routes/MarketDataReviewTable/selectors";
import { setDataSortedByHeader } from "routes/MarketDataReviewTable/marketDataReviewTableSlice";


const { VITE_API_BASE_URL } = import.meta.env;
const initialState = {
    loading: false,
    reports: [],
    reportDetails: {},
    reportsData: [],
    currentPage:0
};

const marketReportsSlice = createSlice({
    name: "marketReports",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        saveCustomReports(state, { payload }) {
            return {
                ...state,
                reports: payload,
            };
        },
        setReportDetails(state, { payload }) {
            return {
                ...state,
                reportDetails: payload,
            };
        },
        setReportsData(state, { payload }) {
            return {
                ...state,
                reportsData: payload,
            };
        },
        setCurrentPage(state, { payload }) {
            return {
                ...state,
                currentPage: payload,
            };
        },
        resetMarketReports() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    saveCustomReports,
    setReportDetails,
    setReportsData,
    setCurrentPage,
    resetMarketReports,
} = marketReportsSlice.actions;

/**
 * fetch list of all reports (custom)
 */
export function fetchCustomReports(tab) {
    return async (dispatch, getState) => {
        dispatch(setMarketComponentsLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, reportBranches } = getMarketSelectedFilters(
            getState()
        );

        const templateType = parseInt(tab) ? "GLOBAL": "CUSTOM";

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/downloadTemplates`
            )
            .then(({ data }) => {
                dispatch(
                    saveCustomReports(
                        data.filter(
                            (template) => template.templateType === templateType
                        )
                    )
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketComponentsLoading(false));
    };
}

/**
 * delete a report based on templateId (custom)
 * @param {*} templateId
 */
export function deleteCustomReport(templateId) {
    return async (dispatch, getState) => {
        dispatch(setMarketComponentsLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, reportBranches } = getMarketSelectedFilters(
            getState()
        );

        await https
            .delete(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/downloadTemplates/${templateId}`
            )
            .then(({ status }) => {
                toast.success({
                    header: "Delete successful",
                    message: "Template is deleted successfully",
                });
                dispatch(fetchCustomReports());
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketComponentsLoading(false));
    };
}

/**
 * get report details based on templateId (custom)
 * returns { templateName, templateDescription, templateValue, pageCount }
 * @param {*} templateId
 */
export const fetchCustomReportDetails = (templateId) => async (
    dispatch,
    getState
) => {
    dispatch(setMarketComponentsLoading(true));
    dispatch(setLoading(true));

    const currentModule = getMarketCurrentModule(getState());
    const { reportGrouping, reportBranches } = getMarketSelectedFilters(
        getState()
    );

    try {
        const { status, data } = await https.get(
            `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/downloadTemplates/${templateId}`
        );

        dispatch(setReportDetails(data));
        dispatch(setMarketComponentsLoading(false));
        dispatch(setLoading(false));
        return { status, data };
    } catch (error) {
        errorHandler(getErrorMessage(error));
        dispatch(setMarketComponentsLoading(false));
        dispatch(setLoading(false));
        return { status: error.status, data: null };
    }
};

/**
 * get complete reports data bsed on pages array (custom)
 * returns [{ pageName, headers, rows }]
 * @param {*} pages
 */
  
export const fetchCustomReportData = (pages) => async (dispatch, getState) => {
    dispatch(setMarketComponentsLoading(true));
    dispatch(setLoading(true));

    if (!pages || pages.length === 0) {
        errorHandler(
            getErrorMessage({ error: { message: "No reports found!" } })
        );
        return { status: 400, data: pages };
    }

    try {
        const output = [];
        
        const { reportGrouping, reportBranches, reportDMA, sort, showBranchByName } = getMarketSelectedFilters(getState());

        const reportAllBranches = getMarketFilters(getState()).reportBranches;
        const allReports = getMarketFilters(getState()).reportGrouping;

        const selectedBranch = findDeepNestedValue(reportAllBranches, "id", reportBranches);
        const selectedReport = findDeepNestedValue(allReports, "id", reportGrouping);

        // This tracker variable is needed to check whether we need to apply functionality to show the data if market segmentation is selected
        // Without this tracker, metrics other than market segmentation will not show up on the excel sheet
      
        let assignmentTracker = 0;
        let rowCountTracker = 0;

        for (const page of pages) {
            const { pageName, endpoints = [] } = page;
            
            const pageOutput = [];
            
            if (endpoints && endpoints.length > 0) {
                for (var endpoint of endpoints) {
                    
                    endpoint = endpoint
                        .replace("_REPORTID_", reportGrouping)
                        .replace("_BRANCHID_", reportBranches)
                        .replace("_DRIVERID_", 10000)
                        .replace("_DMA_", getReportDMAParam(reportDMA))
                        .replace("_sortColumnNumber_", `sortColumnNumber=${sort.sortColumnNumber}`)
                        .replace("_columnSortOrder_", `columnSortOrder=${sort.columnSortOrder}`);
                        
                    // Manipulate endpoints to reflect showBranchBy selection change and show data accordingly
                    // Except if the page name is other than Market Summary
                    if(!pageName.match(new RegExp(pageNames.MARKET_SUMMARY))){
                        if(!endpoint.includes('showBranchBy')){
                            endpoint += `&showBranchBy=${showBranchByName?"NAME":"ADDRESS"}`
                        } else if(endpoint.includes('showBranchBy=ADDRESS')){
                            endpoint = endpoint
                            .replace("showBranchBy=ADDRESS", `showBranchBy=${showBranchByName?"NAME":"ADDRESS"}`)
                        } else if(endpoint.includes('showBranchBy=NAME')){
                            endpoint = endpoint
                            .replace("showBranchBy=NAME", `showBranchBy=${showBranchByName?"NAME":"ADDRESS"}`)
                        }
                    }
                    
                    if (pageName.match(new RegExp(pageNames.MARKET_QUANTIFY))){
                        const sortColumnDetailsQuantify = getSortColumnDetailsQuantify(getState());
                        if(!endpoint.includes("sortColumnNumber")){
                            endpoint = endpoint + `&sortColumnNumber=3`
                        }
                        if(!endpoint.includes("columnSortOrder")){
                            endpoint = endpoint + `&columnSortOrder=DESCENDING`
                        }
                        if(sortColumnDetailsQuantify.sortOrder){
                            const sortColumnNumber = endpoint.substring(endpoint.indexOf("sortColumnNumber="), endpoint.indexOf("&columnSortOrder"))
                            endpoint = endpoint.replace(sortColumnNumber, `sortColumnNumber=${sortColumnDetailsQuantify.sortNumber}`)

                            if(endpoint.includes("columnSortOrder=DESCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=DESCENDING", `columnSortOrder=${sortColumnDetailsQuantify.sortOrder}`)
                            }
                            if(endpoint.includes("columnSortOrder=ASCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=ASCENDING", `columnSortOrder=${sortColumnDetailsQuantify.sortOrder}`)
                            }
                        }
                    }

                    if(pageName.match(new RegExp(pageNames.MARKET_RECOMMENDATION))){
                        
                        var tabRatio = endpoint.match(new RegExp(/\d+\/recommendation/))[0].match(new RegExp(/\d+/));
                        let ratioNameForRecommend = parseInt(tabRatio[0]) < RATIOS.RATIONALISATION_RATIO_ID? "EXPANSION" : parseInt(tabRatio[0]) === RATIOS.RATIONALISATION_RATIO_ID? "RATIONALIZATION":"GROWTH";
                        
                        const sortColumnDetailsRecommendGrowth = getSortColumnDetailsRecommendGrowth(getState());
                        const sortColumnDetailsRecommendExpansion = getSortColumnDetailsRecommendExpansion(getState());
                        const sortColumnDetailsRecommendRationalize = getSortColumnDetailsRecommendRationalize(getState());

                        if(!endpoint.includes("sortColumnNumber")){
                            endpoint = endpoint + `&sortColumnNumber=6`
                        }
                        if(!endpoint.includes("columnSortOrder")){
                            endpoint = endpoint + `&columnSortOrder=DESCENDING`
                        }

                        if(ratioNameForRecommend === "GROWTH" && sortColumnDetailsRecommendGrowth.sortOrder){
                            const sortColumnNumber = endpoint.substring(endpoint.indexOf("sortColumnNumber="), endpoint.indexOf("&columnSortOrder"))
                            endpoint = endpoint.replace(sortColumnNumber, `sortColumnNumber=${sortColumnDetailsRecommendGrowth.sortNumber}`)

                            if(endpoint.includes("columnSortOrder=DESCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=DESCENDING", `columnSortOrder=${sortColumnDetailsRecommendGrowth.sortOrder}`)
                            }
                            if(endpoint.includes("columnSortOrder=ASCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=ASCENDING", `columnSortOrder=${sortColumnDetailsRecommendGrowth.sortOrder}`)
                            }
                        }

                        if(ratioNameForRecommend === "EXPANSION" && sortColumnDetailsRecommendExpansion.sortOrder){
                            const sortColumnNumber = endpoint.substring(endpoint.indexOf("sortColumnNumber="), endpoint.indexOf("&columnSortOrder"))
                            endpoint = endpoint.replace(sortColumnNumber, `sortColumnNumber=${sortColumnDetailsRecommendExpansion.sortNumber}`)

                            if(endpoint.includes("columnSortOrder=DESCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=DESCENDING", `columnSortOrder=${sortColumnDetailsRecommendExpansion.sortOrder}`)
                            }
                            if(endpoint.includes("columnSortOrder=ASCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=ASCENDING", `columnSortOrder=${sortColumnDetailsRecommendExpansion.sortOrder}`)
                            }
                        }

                        if(ratioNameForRecommend === "RATIONALIZATION" && sortColumnDetailsRecommendRationalize.sortOrder){
                            const sortColumnNumber = endpoint.substring(endpoint.indexOf("sortColumnNumber="), endpoint.indexOf("&columnSortOrder"))
                            endpoint = endpoint.replace(sortColumnNumber, `sortColumnNumber=${sortColumnDetailsRecommendRationalize.sortNumber}`)

                            if(endpoint.includes("columnSortOrder=DESCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=DESCENDING", `columnSortOrder=${sortColumnDetailsRecommendRationalize.sortOrder}`)
                            }
                            if(endpoint.includes("columnSortOrder=ASCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=ASCENDING", `columnSortOrder=${sortColumnDetailsRecommendRationalize.sortOrder}`)
                            }
                        }
                    }

                    if (pageName.match(new RegExp(pageNames.MARKET_EXECUTIVE_SUMMARY_MATRIX))){
                        const sortColumnDetailsMatrix = getSortColumnDetailsMatrix(getState());
                        if(!endpoint.includes("sortColumnNumber")){
                            endpoint = endpoint + `&sortColumnNumber=3`
                        }
                        if(!endpoint.includes("columnSortOrder")){
                            endpoint = endpoint + `&columnSortOrder=DESCENDING`
                        }
                        if(sortColumnDetailsMatrix.sortOrder){
                            const sortColumnNumber = endpoint.substring(endpoint.indexOf("sortColumnNumber="), endpoint.indexOf("&columnSortOrder"))
                            endpoint = endpoint.replace(sortColumnNumber, `sortColumnNumber=${sortColumnDetailsMatrix.sortNumber}`)

                            if(endpoint.includes("columnSortOrder=DESCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=DESCENDING", `columnSortOrder=${sortColumnDetailsMatrix.sortOrder}`)
                            }
                            if(endpoint.includes("columnSortOrder=ASCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=ASCENDING", `columnSortOrder=${sortColumnDetailsMatrix.sortOrder}`)
                            }
                        }
                    }

                    if (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW)) || pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW_MAP))){
                        const sortColumnDetailsIdentify = getSortColumnDetailsIdentify(getState());
                        if(!endpoint.includes("sortColumnNumber")){
                            endpoint = endpoint + `&sortColumnNumber=2`
                        }
                        if(!endpoint.includes("columnSortOrder")){
                            endpoint = endpoint + `&columnSortOrder=DESCENDING`
                        }
                        if(sortColumnDetailsIdentify.sortOrder){
                            const sortColumnNumber = endpoint.substring(endpoint.indexOf("sortColumnNumber="), endpoint.indexOf("&columnSortOrder"))
                            endpoint = endpoint.replace(sortColumnNumber, ``)
                            if(endpoint.includes("columnSortOrder=DESCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=DESCENDING", ``)
                            }
                            if(endpoint.includes("columnSortOrder=ASCENDING")){
                                endpoint = endpoint.replace("columnSortOrder=ASCENDING", ``)
                            }
                        }
                    }
                    
                    dispatch(setCurrentPage(output.length+1));
                    
                    let prefIxSlash = _.startsWith(endpoint, "api")? "/":"";
                    const { data } = await https.get(
                        `${VITE_API_BASE_URL}${prefIxSlash}${endpoint}`
                    );

                    if (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW)) || pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW_MAP))){
                        if(!endpoint.includes("sortColumnNumber") && !endpoint.includes("columnSortOrder")){
                            dispatch(
                                setDataSortedByHeader(
                                    data?.defaultSortColumn
                                )
                            )
                        }
                    };     

                    let resourceFocusList = getResourceFocusList(getState());

                    if(pageName.match(new RegExp(pageNames.MARKET_RECOMMENDATION)) && !resourceFocusList.length){
                        await dispatch(fetchMarketRecommendationResourceFocus());
                        resourceFocusList = getResourceFocusList(getState());
                    }

                    let { headers, rows } = getMarketTableData(
                        pageName,
                        data
                    );

                    if(assignmentTracker === 0){
                        rowCountTracker = rows?.length;
                        assignmentTracker++;
                    }

                    if (data){
                        
                        let marketOutput, ratioName, dmaMatch, dataPath, listTypeMatch, shownBy;

                        //console.log("A");

                        marketOutput = output.find(item =>  {return (item.pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW))) && item.xls !== null });
                        dmaMatch  = endpoint.match(/dma=\w+/)? endpoint.match(/dma=\w+/)[0].toString().replace("dma=",""):"";
                        shownBy = endpoint.match(/region=\w+/)? endpoint.match(/region=\w+/)[0].toString().replace("region=",""):"";

                        //console.log("A1", pageName);

                        if(pageName.match(new RegExp(pageNames.MARKET_RECOMMENDATION))){

                            // eslint-disable-next-line no-redeclare
                            var tabRatio = endpoint.match(new RegExp(/\d+\/recommendation/))[0].match(new RegExp(/\d+/));

                            ratioName = parseInt(tabRatio[0]) < RATIOS.RATIONALISATION_RATIO_ID? "EXPANSION" : parseInt(tabRatio[0]) === RATIOS.RATIONALISATION_RATIO_ID? "RATIONALIZATION":"GROWTH";

                            // eslint-disable-next-line no-loop-func
                            listTypeMatch = resourceFocusList.find((item) => item.id.toString() === tabRatio[0]).label;

                            shownBy = endpoint.match(/expansionArea=[S,D]/)? endpoint.match(/expansionArea=[S,D]/)[0].toString().replace("expansionArea=S","Product Summary").replace("expansionArea=D","Product Detail"):shownBy;
                        }
                        else if (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW_MEA_TABLE))){                        
                            listTypeMatch = "";
                        }
                        else {

                            listTypeMatch  = endpoint.match(/listType=\w+/)? endpoint.match(/listType=\w+/)[0].replace(/listType=/,"").match(/\w+/):["1"];

                            let listTypes = [];


                            if (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW)) || pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW_MAP))){
                                listTypes = data.listTypes?data.listTypes:listTypes;
                                ratioName = data.name?data.name:ratioName;
                            }

                            if (pageName.match(new RegExp(pageNames.MARKET_QUANTIFY))){
                                listTypes = getQuantifyListTypes(getState());
                            }

                            if (pageName.match(new RegExp(pageNames.MARKET_EXECUTIVE_SUMMARY_MATRIX))){
                                listTypes = getMatrixListTypes(getState());
                            }

                            let listTypeMatchVal = listTypeMatch[0]?.toString();

                            listTypeMatch = (listTypes && listTypes.length > 0 && listTypes.find((item) => item.listType === listTypeMatchVal))?listTypes.find((item) => item.listType === listTypeMatchVal).label:"";

                        }

                        //console.log("A2");

                        /*
                            Following "If" statement special processing was created for Market Review requirement which says
                            the data has to appear side by side in excel output not stacked on top of each other.
                            All data gets assigned to CLSX xls property. This special processing does not apply to PDF output.
                            "Else" part sets the intital excel data for first page. Subsequent pages falls thru "if" block since marketOutput will turn
                            true since the data has been initialised thru "Else" in the first pass.
                        */
                        if ((rows.length <= rowCountTracker) && marketOutput && (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW)) && !pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW_MAP)))) {
                            
                            ratioName = data.name;
                            
                            dataPath  = `/Metric=${ratioName}/SubMetric=${listTypeMatch}/DMA=${dmaMatch}/ViewBY=${shownBy}/Report=${selectedReport.label? selectedReport.label:""}`;
                            
                            marketOutput.output[0].Count += 1;

                            var mxls = marketOutput.output[0].xls;                           

                            /*
                            if same column names repeat which happens in case of data stacking data for sub metrics, CLSX excel fails to produce the output.
                            Following logic produces unique column name by appending count so that CLSX produces the output.
                            */
                            
                            for (let i = 0; i < headers.length; i++) {
                                if (mxls?.headers?.find((h)=> h === headers[i])) {
                                    headers[i] = headers[i] +  "\u200B".repeat(marketOutput.output[0].Count);
                                }
                            }
                        
                            mxls.headers =  [...mxls?.headers, "\u200B".repeat(marketOutput.output[0].Count), ...headers];

                            //set the Data Path row
                            let newDataPathRow = {"": ""}; 
                            let headerCount = Object.keys(marketOutput.output[0].dataPathRow).length;
                            
                            // eslint-disable-next-line no-unused-vars
                            for (const key in headers) {  
                                    newDataPathRow = {...newDataPathRow, [headerCount+1]: null }
                                    headerCount++;
                            }
                            
                            // Tracker counter for which cell should be blank || which cell should metrics align
                            let tracker = Object.keys(marketOutput.output[0].dataPathRow).length;
                            // Leave the first cell as blanck.
                            newDataPathRow[tracker] = "";
                            // Start the data from second cell
                            newDataPathRow[tracker + 1] = dataPath;
                            //Combine the new data path row into the previous one
                            marketOutput.output[0].dataPathRow = { ...marketOutput.output[0].dataPathRow, ...newDataPathRow};
                            // Rows combined horizontally                            
                            mxls.rows = mxls.rows.map((row, index) => {
                                // sourceRow recursively returns metric rows sharing same institution name
                                var sourceRow = rows.find(searchRow => { return searchRow.Institution === row.Institution});
                                // we need alternative source row if metrics does not share same column names
                                // this source object will hold each individual submetric value to be rendered on the page without depending on mutual column names
                                let alternativeSource;
                                if(index < rows.length){
                                    alternativeSource = rows[index];
                                } else{
                                    alternativeSource = {}
                                }
                        
                                var modifiedSourceRow = {};
                                // this cache object will be used only if metrics doesnt share same column criterias
                                let alternativeSourceRow = {};

                                // Set values for those unique column names
                                if(sourceRow){
                                    for (const [key, value] of Object.entries(sourceRow)) {
                                        if(value === undefined) continue;
                                        if (row.hasOwnProperty(key)) {
                                            modifiedSourceRow[key + "\u200B".repeat(marketOutput.output[0].Count)] = value;
                                        } else {
                                            modifiedSourceRow[key] = value;
                                        }
                                    }
                                } else {
                                    for(const [key, value] of Object.entries(alternativeSource)){
                                        if(value === undefined) continue;
                                        if (row.hasOwnProperty(key)){
                                            alternativeSourceRow[key + "\u200B".repeat(marketOutput.output[0].Count)] = value;
                                        } else {
                                            alternativeSourceRow[key] = value;
                                        }                                          
                                    }
                                }

                                // Combine rows columnwise
                                var newRow = {...row, ...modifiedSourceRow, ...alternativeSourceRow};
                        
                                return newRow;
                            });

                                pageOutput.push({
                                    endpoint: `${
                                        _.startsWith(endpoint, "api") && "/"
                                    }${endpoint}`,
                                    pageName,
                                    branchName:selectedBranch.label,
                                    ratioName,
                                    dmaMatch,
                                    listTypeMatch,
                                    shownBy,
                                    pdf: { ...data },
                                    xls: null
                                });

                        } else {

                            if (!pageName.match(new RegExp(pageNames.MARKET_SUMMARY))) {
                                dataPath  = `/Metric=${ratioName?ratioName:""}/SubMetric=${listTypeMatch?listTypeMatch:""}/DMA=${dmaMatch?dmaMatch:""}/ViewBY=${shownBy?shownBy:""}/Report=${selectedReport.label?selectedReport.label:""}`;
                            }

                            //set the Data Path
                            let dataPathRow = {"": ""};
                            for (const key in headers) {
                                if(key === undefined) continue;
                                dataPathRow = {...dataPathRow, [key]: null }
                            }
                          
                            dataPathRow[1] = dataPath;
                            
                            const filteredHeaders = headers?.filter((header) => header !== undefined)

                            const filteredRows = rows.map((eachMetric) => {
                                const filteredMetric = Object.assign({}, eachMetric);

                                Object.keys(filteredMetric).forEach((key) => filteredMetric[key] === undefined && delete filteredMetric[key]);

                                return filteredMetric
                            });
                            
                            pageOutput.push({
                                endpoint: `${
                                    _.startsWith(endpoint, "api") && "/"
                                }${endpoint}`,
                                pageName,
                                dataPathRow,
                                branchName:selectedBranch.label,
                                ratioName,
                                dmaMatch,
                                listTypeMatch,
                                shownBy,
                                Count:1,
                                pdf: { ...data },
                                xls: {
                                    headers:headers? [" ", ...filteredHeaders]:[""],
                                    rows:filteredRows
                                },
                            });
                        }
                    }
                }
            }
            
            if (pageOutput.length > 0) {
                output.push({
                    pageName,
                    report:selectedReport,
                    branch:selectedBranch,
                    output: pageOutput,
                });
            }
        }

        await dispatch(setReportsData(output));
        dispatch(setMarketComponentsLoading(false));
        dispatch(setLoading(false));
        return { status: 200, data: output };
    } catch (error) {
        dispatch(setMarketComponentsLoading(false));
        dispatch(setLoading(false));
        errorHandler(getErrorMessage(error));
        return { status: error.status, data: null };
    }
};

export default marketReportsSlice.reducer;
