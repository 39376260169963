import { createUseStyles } from "react-jss";
import { generateClassNames } from "utils/generateClassNames";
import PropTypes from "prop-types";

const useStyles = createUseStyles((theme) => ({
    dividerVertical: {
        width: 2,
        height: "100%",
        borderLeft: `1px solid ${theme.colors.greys.disabled}`,
        paddingLeft: theme.calculateSpacing(3),
        marginLeft: theme.calculateSpacing(3),
    },
    dividerHorizontal: {
        height: 2,
        width: "100%",
        borderBottom: `1px solid ${theme.colors.greys.disabled}`,
        paddingBottom: theme.calculateSpacing(3),
        marginBottom: theme.calculateSpacing(3),
    },
}));

export default function Divider({ orientation, className: classNameProp }) {
    const classes = useStyles();
    const dividerClassNames = generateClassNames(
        {
            [classes.dividerVertical]: orientation === "vertical",
            [classes.dividerHorizontal]: orientation === "horizontal",
        },
        classNameProp
    );
    return <div className={dividerClassNames} />;
}

Divider.propTypes = {
    className: PropTypes.string,
    orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

Divider.defaultProps = {
    className: undefined,
    orientation: "horizontal",
};
