import { LinearProgress as MuiLinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    barColorPrimary: {
        backgroundColor: theme.palette.accent.orange,
    },
}));

export default function LinearProgress({ ...rest }) {
    const classes = useStyles();

    return (
        <MuiLinearProgress
            id="linearProgress"
            color="primary"
            classes={{ barColorPrimary: classes.barColorPrimary }}
            {...rest}
        />
    );
}
