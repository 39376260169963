import { useEffect, useRef } from "react";
import styled from "styled-components";
import lottie from "lottie-web";
import loadingAnimation from "assets/static/loading.json";
import { Backdrop } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { createUseStyles } from "react-jss";

const LoadingContainer = styled(Backdrop)`
    z-index: ${(p) => p.theme.zIndex.drawer + 1};
`;

const useStyles = createUseStyles({
    Spinner: {
        loop: Infinity,
        ease: "linear",
        duration: 1,
    },
});

export default function Loading() {
    const loadingRef = useRef(null);
    const classes = useStyles();
    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: loadingRef.current,
            animationData: loadingAnimation,
            renderer: "svg",
            loop: true,
            autoplay: true,
        });

        return () => animation.destroy();
    }, []);

    return (
        <LoadingContainer open>
            <FontAwesomeIcon
                className={classes.Spinner}
                icon={faCircleNotch}
                size={"3x"}
                spin
            />
            {/* <div id="loading-animation" ref={loadingRef} /> */}
        </LoadingContainer>
    );
}
