export const getIdentifyLoading = (state) =>
    state.market.dataReview.table.loading;

export const getIdentifyListTypes = (state) =>
    state.market.dataReview.table?.transformed?.listTypes;

export const getIdentifyHeaders = (state) =>
    state.market.dataReview.table?.transformed?.headers;

export const getIdentifyTableData = (state) =>
    state.market.dataReview.table?.marketData;

export const getIdentifyTableTitle = (state) =>
    state.market.dataReview.table?.shortDescription;

export const getIdentifyTableTooltip = (state) =>
    state.market.dataReview.table?.longDescription;

export const getDataSortedBy = (state) =>
    state.market.dataReview.table?.dataSortedBy;

export const getImpactSummaryFieldName = (state) =>
    state.market.dataReview.table?.defaultSortColumn;
    
export const getIdentifyImpactSummary = (state) =>
    state.market.dataReview.table?.impactSummary;

export const getIdentifyTree = (state) =>
    state.market.dataReview.table?.marketTree;

export const getDataLayerForTree = (state) =>
    state.market.dataReview.table?.dataLayerForTree;

export const getSortColumnDetailsIdentify = (state) =>
    state.market.dataReview.table?.sortColumnDetailsIdentify 

export const getSortedForFirstTime = (state) =>
    state.market.dataReview.table?.sortedForFirstTime

export const getDataSortedByHeader = (state) =>
    state.market.dataReview.table?.dataSortedByHeader
