import { createSlice } from "@reduxjs/toolkit";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "routes/MarketRoute/selectors";
import toast from "components/@bank-ui/core/Toast/Toast";
import { setMarketComponentsLoading } from "routes/MarketRoute/marketSlice";
import _ from "lodash";
import { transformShape } from "routes/MarketDataReviewMap/utils/transformShape";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    customDMAData: [],
    reportLocked: {
        isLocked:false,
        lockedBy:""
    },
    clientLockedBy: "",
    branchCoordinatesData: {
        commitProcessing: false,
        customDMAs: [],
    },
    polygon: null,
};

const marketCustomDMASlice = createSlice({
    name: "marketCustomDMA",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setBranchCoordinatesData(state, { payload }) {
            return {
                ...state,
                branchCoordinatesData: payload.branchCoordinatesData,
            };
        },
        setMarketCustomDMAData(state, { payload }) {
            return {
                ...state,
                customDMAData: payload.customDMAData,
            };
        },
        setMarketCustomDMAReportLocked(state, { payload }) {
            return {
                ...state,
                reportLocked: payload.reportLocked,
            };
        },
        setMarketCustomDMAPolygon(state, { payload }) {
            return {
                ...state,
                polygon: payload.polygon,
            };
        },
        resetMarketCustomDMAData() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setMarketCustomDMAData,
    setBranchCoordinatesData,
    setMarketCustomDMAReportLocked,
    setMarketCustomDMAPolygon,
    resetMarketCustomDMAData,
} = marketCustomDMASlice.actions;

export function fetchMarketCustomDMASliceTableData() {
    return async (dispatch, getState) => {
        await dispatch(setMarketComponentsLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping } = getMarketSelectedFilters(getState());

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/customDefinedMarketAreas`
            )
            .then(({ data }) => {
                dispatch(
                    setMarketCustomDMAData({
                        customDMAData: data.customDMAs,
                    })
                );
                dispatch(
                    setMarketCustomDMAReportLocked({
                        reportLocked: { 
                                        isLocked: data.clientLocked,
                                        lockedBy: data.clientLockedBy,
                                    }
                    })
                );                
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketComponentsLoading(false));
    };
}

export function updateCustomDMAEdits(commitProcessing) {
    return async (dispatch, getState) => {
        await dispatch(setMarketComponentsLoading(true));

        const { selected: { reportGrouping } = {} } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());

        var branchCoordinatesData = {
            ...getState()?.market?.customDMA?.branchCoordinatesData,
        };
        branchCoordinatesData.commitProcessing = commitProcessing;

        const payload = JSON.stringify(branchCoordinatesData);

        if (!branchCoordinatesData.customDMAs || (branchCoordinatesData.customDMAs && branchCoordinatesData.customDMAs.length === 0)){
            toast.warning({
                message: "No Edits were found to be saved.",
            });
        }
        else{
            await https
                .put(
                    `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/customDefinedMarketAreas`,
                    payload
                )
                .then((response) => {
                    toast.success({
                        header: commitProcessing
                            ? "Committed successfully"
                            : "Save successful",
                        message: commitProcessing
                            ? "Changes successfully submitted for processing"
                            : "Edits saved successfully",
                    });

                    dispatch(
                        setMarketCustomDMAData({
                            customDMAData: response.data.customDMAs,
                        })
                    );
                    dispatch(
                        setMarketCustomDMAReportLocked({
                            reportLocked: { 
                                            isLocked: response.data.clientLocked,
                                            lockedBy: response.data.clientLockedBy,
                                        }
                        })
                    );
                })
                .catch((error) => errorHandler(getErrorMessage(error)));
        }
        dispatch(setMarketComponentsLoading(false));
    };
}

export function deleteCustomDMAEdits() {
    return async (dispatch, getState) => {
        await dispatch(setMarketComponentsLoading(true));

        const { selected: { reportGrouping } = {} } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());

        await https
            .delete(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/customDefinedMarketAreas`
            )
            .then((response) => {
                toast.success({
                    header: "Cancel processed",
                    message: "Changes successfully canceled.",
                });

                dispatch(
                    setMarketCustomDMAData({
                        customDMAData: response.data.customDMAs,
                    })
                );
                dispatch(
                    setMarketCustomDMAReportLocked({
                        reportLocked: { 
                                        isLocked: response.data.clientLocked,
                                        lockedBy: response.data.clientLockedBy,
                                    }
                    })
                );
                dispatch(
                    setBranchCoordinatesData({
                        branchCoordinatesData: {
                            commitProcessing: false,
                            customDMAs: [],
                        },
                    })
                );
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketComponentsLoading(false));
    };
}

export function editCustomDMAData({ branchId, type, coordinates, radius }) {
   
    return async (dispatch, getState) => {
        const { customDMAData, branchCoordinatesData } =
            getState().market?.customDMA;

        var customDMAs = [...branchCoordinatesData.customDMAs];
        var branchIndex = customDMAs.length
            ? customDMAs.findIndex((branch) => branch.branchId === branchId)
            : -1;
        var itemsToRemove = 0;
        if (branchIndex >= 0) itemsToRemove = 1;
        // eslint-disable-next-line no-self-assign
        branchIndex === -1 ? (branchIndex = 0) : (branchIndex = branchIndex);

        var branchDMAData;

        if (type === "POLYGON")
        
            branchDMAData = {
                branchId: branchId,
                dmaRegion: type,
                spatialQuery: {
                    shape: type,
                    borderCoordinates: JSON.parse(
                        JSON.stringify(coordinates)
                            .replace(/lat/g, "latitude")
                            .replace(/lng/g, "longitude")
                    ),
                },
            };

        if (type === "POINTER")
            branchDMAData = {
                branchId: branchId,
                dmaRegion: type,
                spatialQuery: {
                    shape: type,
                    centerCoordintate: JSON.parse(
                        JSON.stringify(coordinates)
                            .replace(/lat/g, "latitude")
                            .replace(/lng/g, "longitude")
                    ),
                },
            };

        if (type === "CIRCLE") {
            
            var earth = 6378.137,  //radius of the earth in kilometer
            pi = Math.PI,
            m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree
            let newLatitude = coordinates.lat + (radius * m);
            let newLongitude = coordinates.lng + (radius * m) / Math.cos(coordinates.lat * (pi / 180));
            
            branchDMAData = {
                branchId: branchId,
                dmaRegion: "POLYGON",
                spatialQuery: {
                    shape: type,
                    centerCoordintate: JSON.parse(
                        JSON.stringify(coordinates)
                            .replace(/lat/g, "latitude")
                            .replace(/lng/g, "longitude")
                    ),
                    borderCoordinates: [
                        {
                            latitude: newLatitude,
                            longitude: newLongitude,
                        },
                    ],
                },
            };
        }
       
        if (type === "RADIUS")
            branchDMAData = {
                branchId: branchId,
                dmaRegion: type,
                modifiedRadius: radius,
            };

        if (type === "NONE")
            branchDMAData = {
                branchId: branchId,
                dmaRegion: type,
            };

        customDMAs.splice(branchIndex, itemsToRemove, branchDMAData);  
        
        dispatch(
            setBranchCoordinatesData({
                branchCoordinatesData: {
                    commitProcessing: branchCoordinatesData.commitProcessing,
                    customDMAs: customDMAs,
                },
            })
        );

        var updatedCustomDMAData = [];

        customDMAData.forEach((branch) => {
            var newBranch = { ...branch };

            if (branch.branchId === branchId) {
                if (type === "NONE") {
                    newBranch.dmaTypeCode = "B";
                    newBranch.dmaRegion = type;
                }

                if (type === "RADIUS") {
                    newBranch.dmaTypeCode = "R";
                    newBranch.modifiedRadius = radius;
                    newBranch.dmaRegion = type;
                }

                if (type === "POLYGON") {
                    newBranch.dmaTypeCode = "P";
                    newBranch.dmaRegion = type;
                }

                if (type === "CIRCLE") {
                    newBranch.dmaTypeCode = "P";
                    newBranch.dmaRegion = "POLYGON";
                }
            }

            updatedCustomDMAData.push(newBranch);
        });

        dispatch(
            setMarketCustomDMAData({
                customDMAData: updatedCustomDMAData,
            })
        );
    };
}

export function processChanges() {
    return async (dispatch, getState) => {
        const { customDMAData } =
            getState().market?.customDMA;

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping } = getMarketSelectedFilters(getState());
        
        var updateData = {
            branchId: 0,
            type: "",
            coordinates: null,
            radius: null,
        };

        for (const branch of customDMAData) {
            if (branch.editingInProgress && branch.dmaRegion !== "POLYGON") {

                updateData.branchId = branch.branchId;
                updateData.type = branch.dmaRegion;
                updateData.radius = branch.modifiedRadius;

                if (updateData.type === "POLYGON") {
                    // make call to the server and get the co-ordinates
                    const transformedBlocks = await getCustomDmaBranchPolygon(currentModule, reportGrouping, branch.branchId);                    
                    updateData.coordinates = [];
                    transformedBlocks.geometry.coordinates.forEach((coordinate) => {
                        updateData.coordinates.push({latitude:coordinate[0], longitude:coordinate[1]})
                    });
                }               
                dispatch(editCustomDMAData(updateData));
            }
        };

        dispatch(updateCustomDMAEdits(true));
    };
}

export function fetchCustomDmaBranchPolygon(branchId) {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        dispatch(
            setMarketCustomDMAPolygon({
                polygon: null,
            })
        );

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping } = getMarketSelectedFilters(getState());

        const transformedBlocks = await getCustomDmaBranchPolygon(currentModule, reportGrouping, branchId);

        dispatch(
            setMarketCustomDMAPolygon({
                polygon: transformedBlocks,
            })
        );

        dispatch(setLoading(false));
    };
}

async function getCustomDmaBranchPolygon(currentModule, reportGrouping, branchId){

    let transformedBlocks=[], count=0;

    await https
    .get(
        `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/customDefinedMarketAreas/${branchId}`
    )
    .then(({ data }) => {

        if (data.shape) {
 
            if(data.dmaTypeCode === "C"){
                        
                transformedBlocks[count] = _.assign(
                    {},
                    {
                        type: "Feature",
                        name: "circle",
                        properties: JSON.parse(data.shape),
                        branchLatitude: data.latitude,
                        branchLongitude: data.longitude,
                        geometry: {
                            type: "Point",
                            coordinates: [
                                JSON.parse(data.shape).coordinates.split(",")[0],
                                JSON.parse(data.shape).coordinates.split(",")[1],
                            ],
                        },
                    }
                );
            }
            else          
                transformedBlocks[count] = _.assign(
                    {},
                    {
                        type: "Feature",
                        name: "polygon",
                        properties: { ...data },
                        branchLatitude: data.latitude,
                        branchLongitude: data.longitude,
                        geometry: { ...transformShape(data.shape.slice(0, data.shape.length-3) + "))") },
                    }
                );     

              
        }
       
        else {
            transformedBlocks[count] = _.assign(
                {},
                {
                    type: "Feature",
                    name: "NONE",
                    properties: { ...data },
                    branchLatitude: data.latitude,
                    branchLongitude: data.longitude,
                    geometry: null,
                }
            );     
        }

        count++;   

        if (data.blockGroups && data.blockGroups.length > 0) {
        
            data.blockGroups.forEach((bGroup) => {
                
                if (bGroup.shape){
                    transformedBlocks[count] = _.assign(
                        {},
                        {
                            type: "Feature",
                            name: "blockGroups",
                            properties: { ...data },
                            geometry: { ...transformShape(bGroup.shape.slice(0, bGroup.shape.length-3) + "))") },
                        }
                    );     
                }

                count++;
            });
        
        }

    })
    .catch((error) => errorHandler(getErrorMessage(error)));  
    
    return transformedBlocks;
}

export default marketCustomDMASlice.reducer;
