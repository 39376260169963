import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as DollarSignIcon } from "assets/icons/dollar-sign.svg";
import { ReactComponent as ListAltIcon } from "assets/icons/list-alt.svg";
import { ReactComponent as SuitcaseIcon } from "assets/icons/suitcase.svg";
import { PATHS_BA, PATHS_CUA } from "./paths";
import { PRIMARY_MODULES } from "./global";


export const marketFilters = [
    {
        type: "select",
        name: "reportGrouping",
        api: "reportGrouping",
        label: "Report Grouping",
    },
    {
        type: "nestedSelect",
        name: "reportBranches",
        api: "reportBranches",
        label: "Institution",
    },
    {
        type: "select",
        name: "reportDMA",
        api: "reportDMA",
        label: "Market Area",
    },
    {
        type: "navigationSelect",
        name: "reportAnalysisMode",
        api: "reportAnalysisMode",
        label: "Analysis Mode",
    },
];

export const MARKET_FILTERS = {
    REPORT_GROUPING: "reportGrouping",
    REPORT_BRANCHES: "reportBranches",
    REPORT_DMA: "reportDMA",
};

export const MARKET_FLAGS = {
    REPORT_GROUPING_CHANGED: "reportGroupingChanged",
    MAP_LOADED: "mapLoaded",
};

export const selectTypes = {
    SELECT: "select",
    NESTEDSELECT: "nestedSelect",
    NAVIGATIONSELECT: "navigationSelect",
};

export const marketSecondaryNavRoutes = (currentModule) => {
    if (currentModule === PRIMARY_MODULES.BANK_ANALYST) {
        return [
            {
                id: "dataReview",
                label: "Identify",
                value: PATHS_BA.MARKET_DATAREVIEW_TABLE,
                icon: UserIcon,
                subNavItems: [],
            },
            {
                id: "branchMatrix",
                label: "Branch Matrix",
                value: PATHS_BA.MARKET_EXECUTIVE_SUMMARY_MATRIX,
                icon: SuitcaseIcon,
                subNavItems: [],
            },
            {
                id: "quantify",
                label: "Quantify",
                value: PATHS_BA.MARKET_QUANTIFY,
                icon: DollarSignIcon,
                subNavItems: [],
            },
            {
                id: "recommend",
                label: "Recommend",
                value: PATHS_BA.MARKET_RECOMMENDATION,
                icon: ListAltIcon,
                subNavItems: [],
            },
        ];
    }
    if (currentModule === PRIMARY_MODULES.CU_ANALYST) {
        return [
            {
                id: "dataReview",
                label: "Identify",
                value: PATHS_CUA.MARKET_DATAREVIEW_TABLE,
                icon: UserIcon,
                subNavItems: [],
            },
            {
                id: "branchMatrix",
                label: "Branch Matrix",
                value: PATHS_CUA.MARKET_EXECUTIVE_SUMMARY_MATRIX,
                icon: SuitcaseIcon,
                subNavItems: [],
            },
            {
                id: "quantify",
                label: "Quantify",
                value: PATHS_CUA.MARKET_QUANTIFY,
                icon: DollarSignIcon,
                subNavItems: [],
            },
            {
                id: "recommend",
                label: "Recommend",
                value: PATHS_CUA.MARKET_RECOMMENDATION,
                icon: ListAltIcon,
                subNavItems: [],
            },
        ];
    }
    return [];
};

export const treeToBeExpandedRatios = [
    1000,
    2000,
    3000,
    157000,
    982000,
    989000,
];

export const listTypes =  [
    {
        id: "1",
        label: "Total Deposits",
        value: "1",
        listType: "1",
    },
    {
        id: "2",
        label: "Consumer Deposits",
        value: "2",
        listType: "2",
    },
    {
        id: "3",
        label: "Commercial Deposits",
        value: "3",
        listType: "3",
    },
    {
        id: "4",
        label: "Total Loans",
        value: "4",
        listType: "4",
    },
    {
        id: "5",
        label: "Consumer Loans",
        value: "5",
        listType: "5",
    },
    {
        id: "6",
        label: "Commercial Loans",
        value: "6",
        listType: "6",
    },
];

export const RATIOS = {
    EXPANSION_RATIO_ID: 1000,
    RATIONALISATION_RATIO_ID: 2000,
    GROWTH_RATIO_ID: 3000,
};

export const branchViewList = [
    { id: 1, label: "View By Branch", value: "B" },
    { id: 2, label: "View By Region", value: "R" },
    { id: 3, label: "View Product Summary", value: "S" },
    { id: 4, label: "View Product Detail", value: "D" },
];

export const budgetSummaryHeaders = [
    { id: "budgetId", label: "Budget ID" },
    { id: "description", label: "Name" },
    { id: "createdBy", label: "Created By" },
    { id: "actions", label: "Actions" },
    { id: "deleteAction", label: "" },
];

export const budgetDetailsHeaders = [
    { id: "branchName", label: "Institution" },
    { id: "value", label: "Mkt Expansion Total Deposits Current Balance ($000)" },
    { id: "growthProjectionValue", label: "BankAnalyst Growth Projection ($000)" },
    { id: "growthProjectionPercentage", label: "Growth Projection / Total Current Balance (%)" },
    { id: "unadjustedMarketGoal", label: "Unadjusted Corporate Market Goal ($000)" },
    { id: "adjustementFactor", label: "Market Adjustment Factor (Default to 1.00)" },
    { id: "rawAdjustedMarketGoal", label: "Raw Adjusted Corporate Market Goal ($000)" },
    { id: "adjustedMarketGoalValue", label: "Aligned Adjusted Corporate Market Goal ($000)" },
    { id: "adjustedMarketGoalPercentage", label: "Aligned Adjusted Corporate Market Goal (%)" },
];

export const customDMAHeaders = [
    { id: "name", label: "branch name" },
    { id: "address", label: "address" },
    { id: "city", label: "city" },
    { id: "state", label: "state" },
    { id: "zip", label: "zip" },
    { id: "editingInProgress", label: "editing in progress" },
    { id: "recommendedRadius", label: "suggested radius (miles)" },
    { id: "customType", label: "custom type" },
    { id: "customOverride", label: "custom override" },
];

export const leftAlignHeaders = [
    "name",
    "description",
    "branchName",
    "budgetName",
    "createdBy",
    "address",
    "city",
    "state",
    "zip",
];

export const customDMATypes = [
    { id: "none", label: "None", value: "B" },
    { id: "radius", label: "Radius", value: "R" },
    { id: "polygon", label: "Polygon", value: "P" },    
];

export const EXPORT_TYPES = {
    XLS: "XLS",
    PDF: "PDF",
};

export const states = {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
};
