import { createSlice } from '@reduxjs/toolkit';
import { reportsSlice } from '../routes/ReportsRoute/ReportsSlice';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {},
  reducers: {},
  extraReducers: {
    ...Object.keys(reportsSlice.actions).reduce((actions, key) => {
      if (/(Request)$/.test(key)) {
        actions[`${reportsSlice.name}/${key}`] = (state, { type }) => {
          const matches = /(.*)(Request|Success|Failure)$/.exec(type);
          if (!matches) {
            return state;
          }
          const [, requestName, requestState] = matches;
          Object.assign(state, {
            [requestName.replace(/(.*\/)/, '')]: requestState === 'Request'
          });
        };
      }
      return actions;
    }, {})
  }
});

export const loading_indicator = ({ loading }) => loading;

export default loadingSlice.reducer;
