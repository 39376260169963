import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import { RATIOS } from "constants/market";
import { getRecommendHeaders } from "utils/getMarketHeaders";
import { transformTableData } from "utils/transformations";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "routes/MarketRoute/selectors";
import { setMarketComponentsLoading } from "routes/MarketRoute/marketSlice";
import toast from "components/@bank-ui/core/Toast/Toast";
import { getSortColumnDetailsRecommendExpansion, getSortColumnDetailsRecommendGrowth, getSortColumnDetailsRecommendRationalize, getSortedForFirstTimeExpansion, getSortedForFirstTimeGrowth, getSortedForFirstTimeRationalize } from "./selectors"

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    resourceFocusList: [],
    recommendationData: [],
    transformed: { headers: [] },
    dataSortedByHeader: null,
    sortedForFirstTime: true,
    sortColumnDetailsRecommend: {},
    sortColumnDetailsRecommendRationalize: {},
    sortedFirstTimeRationalize: true,
    sortColumnDetailsRecommendExpansion: {},
    sortedFirstTimeExpansion: true,
    sortColumnDetailsRecommendGrowth: {},
    sortedFirstTimeGrowth: true,
};

const marketRecommendationSlice = createSlice({
    name: "marketRecommendation",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setSortedForFirstTime(state, { payload }) {
            return {
                ...state,
                sortedForFirstTime: payload
            }
        },
        setSortedFirstTimeRationalize(state, { payload }) {
            return {
                ...state,
                sortedFirstTimeRationalize: payload
            }
        },
        setSortedFirstTimeExpansion(state, { payload }) {
            return {
                ...state,
                sortedFirstTimeExpansion: payload
            }
        },
        setSortedFirstTimeGrowth(state, { payload }) {
            return {
                ...state,
                sortedFirstTimeGrowth: payload
            }
        },
        setSortColumnDetailsRecommend(state, { payload }) {
            return {
                ...state,
                sortColumnDetailsRecommend: payload
            }
        },
        setSortColumnDetailsRecommendRationalize(state, { payload }) {
            return {
                ...state,
                sortColumnDetailsRecommendRationalize: payload
            }
        },
        setSortColumnDetailsRecommendGrowth(state, { payload }) {
            return {
                ...state,
                sortColumnDetailsRecommendGrowth: payload
            }
        },
        setSortColumnDetailsRecommendExpansion(state, { payload }) {
            return {
                ...state,
                sortColumnDetailsRecommendExpansion: payload
            }
        },
        setDataSortedByHeader(state, { payload }) {
            return {
                ...state,
                dataSortedByHeader: payload
            }
        },
        setMarketRecommendationFocus(state, { payload }) {
            return {
                ...state,
                resourceFocusList: payload,
            };
        },
        setMarketRecommendationTableData(state, { payload }) {
            return {
                ...state,
                recommendationData: payload.recommendationData,
                transformed: {
                    ...state.transformed,
                    headers: payload.headers,
                },
            };
        },
        resetMarketRecommendationTable() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setMarketRecommendationFocus,
    setMarketRecommendationTableData,
    resetMarketRecommendationTable,
    setDataSortedByHeader,
    setSortedForFirstTime,
    setSortColumnDetailsRecommend,
    setSortColumnDetailsRecommendRationalize,
    setSortedFirstTimeRationalize,
    setSortColumnDetailsRecommendGrowth,
    setSortedFirstTimeGrowth,
    setSortColumnDetailsRecommendExpansion,
    setSortedFirstTimeExpansion
} = marketRecommendationSlice.actions;

export function fetchMarketRecommendationTableData({
    ratioId,
    tabRatioId,
    branchView,
    distance,
    sortColumnNumber,
    columnSortOrder,
}) {
    return async (dispatch, getState) => {
        dispatch(setMarketComponentsLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping, reportBranches, reportDMA, showBranchByName, region } =
            getMarketSelectedFilters(getState());
        
        const sortedForFirstTimeRationalize = getSortedForFirstTimeRationalize(getState());
        const sortedForFirstTimeGrowth = getSortedForFirstTimeGrowth(getState());
        const sortedForFirstTimeExpansion = getSortedForFirstTimeExpansion(getState());

        let params = {
            dma: reportDMA,
            region,
            showBranchBy: showBranchByName ? "NAME": "ADDRESS"  
        };

        if(tabRatioId === RATIOS.RATIONALISATION_RATIO_ID) {
            if(sortedForFirstTimeRationalize){
                params.sortColumnNumber = 2; 
                params.columnSortOrder  = "DESCENDING";
                dispatch(setSortColumnDetailsRecommendRationalize({
                    sortNumber: 2,
                    sortOrder: "DESCENDING"
                }))
                dispatch(setSortedFirstTimeRationalize(false))
            } else {
                const sortColumnDetailsRecommendRationalize = getSortColumnDetailsRecommendRationalize(getState());
                if(sortColumnDetailsRecommendRationalize){
                    params.sortColumnNumber = sortColumnDetailsRecommendRationalize.sortNumber;
                    params.columnSortOrder = sortColumnDetailsRecommendRationalize.sortOrder;
                }
            }
        };

        if(tabRatioId === RATIOS.EXPANSION_RATIO_ID) {
            if(sortedForFirstTimeExpansion){
                params.sortColumnNumber = 6; 
                params.columnSortOrder  = "DESCENDING";
                dispatch(setSortColumnDetailsRecommendExpansion({
                    sortNumber: 6,
                    sortOrder: "DESCENDING"
                }))
                dispatch(setSortedFirstTimeExpansion(false))
            } else {
                const sortColumnDetailsRecommendExpansion = getSortColumnDetailsRecommendExpansion(getState());
                if(sortColumnDetailsRecommendExpansion){
                    params.sortColumnNumber = sortColumnDetailsRecommendExpansion.sortNumber;
                    params.columnSortOrder = sortColumnDetailsRecommendExpansion.sortOrder;
                }
            }
        }

        if(tabRatioId === RATIOS.GROWTH_RATIO_ID) {
            if(sortedForFirstTimeGrowth){
                params.sortColumnNumber = 6; 
                params.columnSortOrder  = "DESCENDING";
                dispatch(setSortColumnDetailsRecommendGrowth({
                    sortNumber: 6,
                    sortOrder: "DESCENDING"
                }))
                dispatch(setSortedFirstTimeGrowth(false))
            } else {
                const sortColumnDetailsRecommendGrowth = getSortColumnDetailsRecommendGrowth(getState());
                if(sortColumnDetailsRecommendGrowth){
                    params.sortColumnNumber = sortColumnDetailsRecommendGrowth.sortNumber;
                    params.columnSortOrder = sortColumnDetailsRecommendGrowth.sortOrder;
                }
            }
        }

        if (tabRatioId === RATIOS.GROWTH_RATIO_ID) {
            params = _.assign(params, {
                expansionArea: branchView === "R" ? "B" : branchView,
            });
            params = _.assign(params, {
                region: branchView === "R" ? "REGION" : "BRANCH",
            });
        }
        if (tabRatioId === RATIOS.EXPANSION_RATIO_ID)
            distance
                ? (params = _.assign(params, {
                      expansionArea: "BYDISTANCE",
                      expansionAreaInDistance: distance,
                  }))
                : (params = _.assign(params, {
                      expansionArea: "S",
                  }));

        if (reportBranches)
            await https
                .get(
                    `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/ratioHierarchy/${ratioId}/recommendation`,
                    { params }
                )
                .then(({ data }) => {
                    if (data && data.length === 0)
                        toast.warning({
                            message: "No Data Received",
                        });
                    else {
                        _.forEach(data, function (recommendationItem) {
                            const { branchId, countyId, countyName, name } =
                                recommendationItem;

                            if (branchId === null && countyId !== null)
                                recommendationItem["branchName"] = countyName;

                            if (branchId === null && countyId === null)
                                recommendationItem["branchName"] = name;
                        });

                        let rowIndex = 0;
                        let transformedRecommendData = transformTableData(data).map((item) => {
                            rowIndex++;
                            return {
                                    No: rowIndex,
                                    ...item,
                            }                  
                        });

                        const headers = getRecommendHeaders(data)
                        
                        const dataSortedBy = headers.find((eachHeader) => eachHeader.id === `value${params.sortColumnNumber}`)?.label;
                        
                        dispatch(
                            setDataSortedByHeader(
                                dataSortedBy
                            )
                        )

                        dispatch(
                            setMarketRecommendationTableData({
                                recommendationData: transformedRecommendData,
                                headers: [{id:"No", label:"No"}, ...getRecommendHeaders(data)],
                            })
                        );
                    }
                })
                .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketComponentsLoading(false));
    };
}

export function fetchMarketRecommendationResourceFocus() {
    return async (dispatch, getState) => {
        dispatch(setMarketComponentsLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const { reportGrouping } = getMarketSelectedFilters(getState());

        try {
            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/recommendationOptions`
            );

            if (data && data.length > 0) {
                let resourceFocusList = [];

                _.forEach(data, function (recommendationOption) {
                    const { ratioId, name, parentRatioId, level, hasMenuItem } =
                        recommendationOption;

                    resourceFocusList = _.concat(resourceFocusList, {
                        id: ratioId,
                        label: _.replace(name, "Mkt Expansion -", ""),
                        value: ratioId,
                        parentRatioId,
                        level,
                        hasMenuItem,
                    });
                });

                dispatch(setMarketRecommendationFocus(resourceFocusList));
                dispatch(setMarketComponentsLoading(false));
                return { status, data: resourceFocusList };
            }
        } catch (error) {
            errorHandler(getErrorMessage(error));
            dispatch(setMarketComponentsLoading(false));
            return { status: error.status, data: [] };
        }
    };
}

export default marketRecommendationSlice.reducer;
