/**
 * Utility to generate Market table headers dynamically.
 */

import _ from "lodash";

export function getIdentifyHeaders(marketData) {
    const headers = _.filter(
        _.keys(marketData[0] || {}),
        (key) => !_.includes(key, "value") // && marketData[0][key] !== null
    );

    let renewedHeaders = [];
    _.map(headers, (headerItem) => {
        if (!_.includes(headerItem, "header"))
            renewedHeaders = _.concat(renewedHeaders, {
                id: headerItem,
                label: headerItem === "name" ? "Institution" : null,
            });
        else {
            const index = headerItem.charAt(headerItem.length - 1);
            renewedHeaders = _.concat(renewedHeaders, {
                id: `value${index}`,
                label: (marketData[0] || {})[`header${index}`]
                    ? `${(marketData[0] || {})[`header${index}`]}${
                          marketData[0][`value${index}`] &&
                          marketData[0][`value${index}`].substr(-1) === "%"
                              ? ""
                              : ""
                      }`
                    : "",
            });
        }
    });

    return renewedHeaders;
}

export function getRecommendHeaders(marketData) {
    const headers = _.filter(
        _.keys(marketData[0] || {}),
        (key) => !_.includes(key, "value")
    );

    let renewedHeaders = [];
    _.map(headers, (headerItem) => {
        if (!_.includes(headerItem, "header"))
            renewedHeaders = _.concat(renewedHeaders, {
                id: headerItem,
                label: headerItem === "branchName" ? "Institution" : (_.includes(headerItem,"marketFocus") && marketData[0]["marketFocus"] != null)? headerItem : (_.includes(headerItem,"name") && marketData[0]["name"] != null) ? marketData[0]["name"]: null,
            });
        else {
            const index = headerItem.charAt(headerItem.length - 1);
            if(marketData[0][`header${index}`] != null)
            renewedHeaders = _.concat(renewedHeaders, {
                id: `value${index}`,
                label:
                    marketData[0] || {}[`header${index}`]
                        ? `${(marketData[0] || {})[`header${index}`]}${
                              marketData[0][`value${index}`] &&
                              marketData[0][`value${index}`].substr(-1) === "%"
                                  ? " (%)"
                                  : ""
                          }`
                        : "",
            });
        }
    });

    if (
        marketData[0]["branchId"] === null &&
        marketData[0]["countyId"] === null
    )
        _.find(
            renewedHeaders,
            (header) => header.label === "Institution"
        ).label = "";

    return renewedHeaders;
}

export function getQuantifyHeaders(marketData) {
    const headers = _.filter(
        _.keys(marketData[0] || {}),
        (key) => !_.includes(key, "value") // && marketData[0][key] !== null
    );

    let renewedHeaders = [];
    _.map(headers, (headerItem) => {
        if (!_.includes(headerItem, "header"))
            renewedHeaders = _.concat(renewedHeaders, {
                id: headerItem,
                label: headerItem === "name" ? "Institution" : null,
            });
        else {
            const index = headerItem.charAt(headerItem.length - 1);
            renewedHeaders = _.concat(renewedHeaders, {
                id: `value${index}`,
                label: (marketData[0] || {})[`header${index}`]
                    ? `${_.replace(
                          (marketData[0] || {})[`header${index}`],
                          "<br/>",
                          ""
                      )}
                    ${
                        marketData[0][`value${index}`] &&
                        marketData[0][`value${index}`].substr(-1) === "%"
                            ? " (%)"
                            : ""
                    }`
                    : "",
            });
        }
    });    

    return renewedHeaders;
}


export function getMEAHeaders(marketData) {

    let renewedHeaders = [];

    try {
    const headers = _.filter(
        _.keys(marketData[0] || {}),
        (key) => !_.includes(key, "value") // && marketData[0][key] !== null
    );


    _.map(headers, (headerItem) => {
        // If it is a normal column name and not a indexed header(header0, header1)
        if (!_.includes(headerItem, "header")){ 
            if (headerItem.toUpperCase().includes("RANK")){

                if (headerItem === "weight_Rank" && marketData[0][headerItem])                   
                    renewedHeaders.splice(2, 0, {
                        id: headerItem,
                        label: marketData[0]["driver_Cat"].replace(/Drivers/,"Rank"),
                    });                

                if (headerItem === "overall_Rank") 
                    renewedHeaders.splice(0, 0, {
                        id: headerItem,
                        label: "Overall Rank",
                    });              
            } 
            else{

                if (headerItem === "name") 
                    renewedHeaders.splice(0, 0, {
                        id: headerItem,
                        label: "Block Group",
                    })
                else if (marketData[0][headerItem] && headerItem.toUpperCase().includes("DRIVERS")){
                    renewedHeaders = _.concat(renewedHeaders, {
                        id: headerItem,
                        label:headerItem.replace("_Drivers"," "),
                    });              
                }
                else
                    renewedHeaders = _.concat(renewedHeaders, {
                        id: headerItem,
                        label:null,
                    });              

            } 
        }
        else {
            const index = headerItem.charAt(headerItem.length - 1);
            renewedHeaders = _.concat(renewedHeaders, {
                id: `value${index}`,
                label: (marketData[0] || {})[`header${index}`]
                    ? `${_.replace(
                          (marketData[0] || {})[`header${index}`],
                          "<br/>",
                          ""
                      )}
                    ${
                        marketData[0][`value${index}`] &&
                        marketData[0][`value${index}`].substr(-1) === "%"
                            ? " (%)"
                            : ""
                    }`
                    : "",
            });
        }
    });   
   
    return renewedHeaders;

   }catch(e){
   }
}


export function getBOHeaders(marketData) {

    const excludedFieds = ["branchId","driverCat", "sequence", "node_Type", "nodeType"];

    const headers = _.filter(
        _.keys(marketData[0] || {}),
        (key) => !_.includes(key, "value") 
    );

    let renewedHeaders = [];
    _.map(headers, (headerItem) => {
        if (!_.includes(headerItem, "header")){ 
            if (headerItem.toUpperCase().includes("RANK")){

                if (headerItem === "overallRank") 
                    renewedHeaders.splice(0, 0, {
                        id: headerItem,
                        label: "Overall Rank",
                    });
                else          
                {
                    if (headerItem === "externalRank" && marketData[0]["externalRank"]) 
                        renewedHeaders.splice(2, 0, {
                            id: "externalRank",
                            label: "External Rank",
                        });    
                    
                    if (headerItem === "internalRank" && marketData[0]["internalRank"]) 
                        renewedHeaders.splice(2, 0, {
                            id: "internalRank",
                            label: "Internal Rank",
                        });    
                }

            }
            else if (headerItem.toUpperCase().includes("DATAID")){

                renewedHeaders = _.concat(renewedHeaders, {
                    id: headerItem,
                    label:null,
                });    

            }
            else{
                if (headerItem === "name") 
                    renewedHeaders.splice(1, 0, {
                        id: headerItem,
                        label: "Block Group",
                    });
                else
                    renewedHeaders = _.concat(renewedHeaders, {
                        id: headerItem,
                        label:marketData[0][headerItem] && !excludedFieds.filter((f) => f === headerItem).length?headerItem:null,
                    });              

            } 
        }
        else {
            const index = headerItem.match(/\d+/);
            renewedHeaders = _.concat(renewedHeaders, {
                id: `value${index}`,
                label: (marketData[0] || {})[`header${index}`]
                    ? `${_.replace(
                          (marketData[0] || {})[`header${index}`],
                          "<br/>",
                          ""
                      )}
                    ${
                        marketData[0][`value${index}`] &&
                        marketData[0][`value${index}`].substr(-1) === "%"
                            ? " (%)"
                            : ""
                    }`
                    : "",
            });
        }
    });    

    return renewedHeaders;
}
