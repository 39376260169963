import { createSlice } from "@reduxjs/toolkit";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import {
    getMarketCurrentModule,
} from "routes/MarketRoute/selectors";
import { getBase64 } from "utils/getBase64";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    orderCost: "",
    orderId: "",
    isFormatCorrect: true,
    isOrderSuccessful: true,
    price: "",
    count: ""
};

const marketFileEnhancementSlice = createSlice({
    name: "marketFileEnhancement",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },        
        setMarketFileEnhancementData(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
        setIsFormatCorrect(state, { payload }) {
            return {
                ...state,
                isFormatCorrect: payload,
            };
        },
        setIsOrderSuccessful(state, { payload }) {
            return {
                ...state,
                isOrderSuccessful: payload
            }
        }
    },
});

export const {
    setLoading,
    setMarketFileEnhancementUploadedFiles,
    setMarketFileEnhancementData,
    setIsFormatCorrect,
    setIsOrderSuccessful
} = marketFileEnhancementSlice.actions;

export function SendFileEnhancementUpload(uploadedFiles) {
    return async (dispatch, getState) => {

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;
        
        const currentModule = getMarketCurrentModule(getState());
        
        uploadedFiles &&
        uploadedFiles.forEach(async (file)=> {         
           await getBase64(file)
                .then(async (fileInformation) => {

                    const decodedBase64 = fileInformation.substring(
                        fileInformation.indexOf(",") + 1
                    )

                    const payload = {'file': decodedBase64}

                    await https
                    .post(
                        `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/fileEnhancement/enhancementCost`,
                        payload
                    )
                    .then(({data}) => {
                        dispatch(setIsFormatCorrect(true))                
                        dispatch(setMarketFileEnhancementData(data));
                    })
                    .catch((error) => {
                        dispatch(setMarketFileEnhancementData({orderId: "", orderCost: ""}))
                        dispatch(setIsFormatCorrect(false))
                        errorHandler(getErrorMessage(error))
                    });

                })
        })

    };
}

export function PlaceOrder(uploadedFiles) {
    return async (dispatch, getState) => {

        dispatch(setLoading(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const currentModule = getMarketCurrentModule(getState());

        uploadedFiles &&
        uploadedFiles.forEach(async (file)=> {            
            await getBase64(file)
                .then(async (fileInformation) => {

                    const decodedBase64 = fileInformation.substring(
                        fileInformation.indexOf(",") + 1
                    )

                    const payload = {'file': decodedBase64}

                    await https
                        .post(
                            `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/fileEnhancement/enhancementOrder`,
                            payload
                        )
                        .then(({data}) => {
                            dispatch(setIsOrderSuccessful(true));                
                            dispatch(setMarketFileEnhancementData(data));
                            dispatch(setLoading(false));
                        })
                        .catch((error) => {
                            dispatch(setMarketFileEnhancementData({orderId: "", orderCost: ""}))
                            dispatch(setIsOrderSuccessful(false));
                            errorHandler(getErrorMessage(error))
                            dispatch(setLoading(false));
                        });

                })
        })
    };
}

export default marketFileEnhancementSlice.reducer;
