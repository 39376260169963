import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
`;
export function FlexWrapper({ children, ...rest }) {
    return <Container {...rest}>{children}</Container>;
}

FlexWrapper.propTypes = {
    children: PropTypes.any,
};
