export const getQuantifyLoading = (state) => state.market.quantify.loading;
export const getQuantifyListTypes = (state) => state.market.quantify?.listTypes;
export const getQuantifySelectedListType = (state) => state.market.quantify?.selectedListType;
export const getQuantifyTableHeaders = (state) =>
    state.market.quantify?.transformed?.headers;
export const getQuantifyTableData = (state) =>
    state.market.quantify?.quantifyData;
export const getDataSortedByHeader = (state) =>
state.market.quantify?.dataSortedByHeader;
export const getSortedForFirstTime = (state) =>
state.market.quantify?.sortedForFirstTime
export const getSortColumnDetailsQuantify = (state) =>
state.market.quantify?.sortColumnDetailsQuantify