import { createSlice } from "@reduxjs/toolkit";
import {
    getMarketCurrentModule,
    getMarketSelectedFilters,
} from "routes/MarketRoute/selectors";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import { setMarketComponentsLoading } from "routes/MarketRoute/marketSlice";
import { getMatrixSelectedListType } from "./selectors";
import { transformTableData } from "utils/transformations";
import { getSortColumnDetailsMatrix, getSortedForFirstTime } from "./selectors"

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    listTypes: [
        {
            id: "1",
            label: "Total Deposits",
            value: "1",
            listType: "1",
        },
        {
            id: "2",
            label: "Consumer Deposits",
            value: "2",
            listType: "2",
        },
        {
            id: "3",
            label: "Commercial Deposits",
            value: "3",
            listType: "3",
        },
        {
            id: "4",
            label: "Total Loans",
            value: "4",
            listType: "4",
        },
        {
            id: "5",
            label: "Consumer Loans",
            value: "5",
            listType: "5",
        },
        {
            id: "6",
            label: "Commercial Loans",
            value: "6",
            listType: "6",
        },
    ],
    selectedListType: "",
    tableHeaders: [],
    matrixData: null,
    dataSortedByHeader: null,
    sortedForFirstTime: true,
    sortColumnDetailsMatrix: {}
};

const marketBranchMatrixSlice = createSlice({
    name: "marketBranchMatrix",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setMarketBranchMatrixData(state, { payload }) {
            return {
                ...state,
                matrixData: payload,
            };
        },
        setSortColumnDetailsMatrix(state, { payload }) {
            return {
                ...state,
                sortColumnDetailsMatrix: payload
            }
        },
        setMarketBranchMatrixHeaders(state, { payload }) {
            return {
                ...state,
                tableHeaders: payload,
            };
        },
        setSortedForFirstTime(state, { payload }) {
            return {
                ...state,
                sortedForFirstTime: payload
            }
        },
        setDataSortedByHeader(state, { payload }) {
            return {
                ...state,
                dataSortedByHeader: payload
            }
        },
        setMarketBranchMatrixSelectedListType(state, { payload }) {
            return {
                ...state,
                selectedListType: payload,
            };
        },
        resetMarketBranchMatrix() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setMarketBranchMatrixHeaders,
    setMarketBranchMatrixData,
    resetMarketBranchMatrix,
    setMarketBranchMatrixSelectedListType,
    setDataSortedByHeader,
    setSortedForFirstTime,
    setSortColumnDetailsMatrix
} = marketBranchMatrixSlice.actions;

export function fetchMarketBranchMatrixData() {
    return async (dispatch, getState) => {
        dispatch(setMarketComponentsLoading(true));

        const currentModule = getMarketCurrentModule(getState());
        const {
            reportGrouping,
            reportBranches,
            region,
            reportDMA,
            sort,
            showBranchByName
        } = getMarketSelectedFilters(getState());
        const sortedForFirstTime = getSortedForFirstTime(getState());

        const params = {
            listType: getMatrixSelectedListType(getState()),
            region,
            dma:reportDMA,
            showBranchBy: showBranchByName ? "NAME": "ADDRESS"  
        };

        if (sort.sortColumnNumber >= 0 && !sortedForFirstTime) {
            const sortColumnDetailsMatrix = getSortColumnDetailsMatrix(getState());
            if(sortColumnDetailsMatrix){
                params.sortColumnNumber = sortColumnDetailsMatrix.sortNumber;
                params.columnSortOrder = sortColumnDetailsMatrix.sortOrder;
            } else{
                params.sortColumnNumber = 2; 
                params.columnSortOrder  = "DESCENDING";
                dispatch(setSortColumnDetailsMatrix({
                    sortNumber: 2,
                    sortOrder: "DESCENDING"
                }))
            }   
        } else {
                params.sortColumnNumber = 2; 
                params.columnSortOrder  = "DESCENDING";
                dispatch(setSortColumnDetailsMatrix({
                    sortNumber: 2,
                    sortOrder: "DESCENDING"
                }))
                dispatch(setSortedForFirstTime(false))
        }

        await https
            .get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/branchMatrix`,
                { params }
            )
            .then(({ data }) => {

                let rowIndex = 0;
                let transformedMatrixData = transformTableData(data).map((item) => {
                    rowIndex++;
                    return {
                            No: rowIndex,
                            ...item,
                    }                  
                });

                const headers = [
                        { id: "value0", label: data[0].header0 },
                        { id: "value1", label: data[0].header1 },
                        { id: "value2", label: data[0].header2 },
                        { id: "value3", label: data[0].header3 },
                        { id: "value4", label: data[0].header4 },
                ];

                const dataSortedBy = headers.find((eachHeader) => eachHeader.id === `value${params.sortColumnNumber}`)?.label;

                dispatch(
                    setDataSortedByHeader(
                        dataSortedBy
                    )
                )
                
                dispatch(
                    setMarketBranchMatrixHeaders([
                        { id: "No", label: "No" },
                        { id: "name", label: "Institution" },
                        { id: "value0", label: data[0].header0 },
                        { id: "value1", label: data[0].header1 },
                        { id: "value2", label: data[0].header2 },
                        { id: "value3", label: data[0].header3 },
                        { id: "value4", label: data[0].header4 },
                    ])
                );
                dispatch(setMarketBranchMatrixData(transformedMatrixData));
            })
            .catch((error) => errorHandler(getErrorMessage(error)));

        dispatch(setMarketComponentsLoading(false));
    };
}

export default marketBranchMatrixSlice.reducer;
