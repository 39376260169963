function getClassName(condition) {
  switch (typeof condition) {
    case 'object':
      if (Array.isArray(condition)) {
        return condition
          .map((check) => getClassName(check))
          .filter((value) => value)
          .join(' ');
      }
      if (condition) {
        return Object.keys(condition)
          .map((check) => (getClassName(condition[check]) ? check : ''))
          .filter((value) => value)
          .join(' ');
      }
      return '';

    case 'string':
      return condition || '';
    case 'boolean':
    case 'number':
      return condition ? condition.toString() : '';
    default:
      return '';
  }
}
export const generateClassNames = (...mix) => {
  if (!mix.length) {
    return '';
  }
  const classNames = [];

  mix.forEach((funcArg) => {
    const shouldAdd = getClassName(funcArg);
    if (shouldAdd) {
      classNames.push(shouldAdd);
    }
  });
  return classNames.join(' ');
};
export default generateClassNames;
