export const PRIMARY_MODULES = {
    BANK_ANALYST: "ba",
    CU_ANALYST: "cua",
};

export const ERROR_MASSEGES = {
    NO_REPORTS: 'There are no reports available for your subscription Please contact your relationship manager at '
}

export const FEATURED_MODULES_FINANCIAL = {
    CREATE_CUSTOM_DOWNLOAD: 'CREATE_CUSTOM_DOWNLOAD',
    CREATE_CUSTOM_PEER: 'CREATE_CUSTOM_PEER',
}

export const FEATURED_MODULES_MAKRKET = {
    CREATE_CUSTOM_DOWNLOAD: 'marketReports',
    ORDER_FILE_ENHANCEMENT: 'fileEnhancement',
    ORDER_PROSPECT_LISTS: 'prospectlist',
    ORDER_REPORTS: 'ORDER_REPORTS',
    SHARE_PROSPECT_LIST_FILTERS_GLOBALLY: 'SHARE_PROSPECT_LIST_FILTERS_GLOBALLY',
}

export const RESTRICTED_MODULE_PATHS = {
    CREATE_CUSTOM_PEER: 'financial/peerSelection/customePeers',
    ORDER_FILE_ENHANCEMENT: "file/enhancement",
    ORDER_PROSPECT_LISTS: "market/prospect",
    MEA_EXPANSION: "market/datareview/mea",
    SHARE_PROSPECT_LIST_FILTERS_GLOBALLY: "market/prospectDetail"
}

