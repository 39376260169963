/* eslint-disable eqeqeq */
import { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { generateClassNames } from "utils/generateClassNames";
import { BREAKPOINTS } from "theme/breakpoints";

const ANCHOR = {
    TOP: "top",
    LEFT: "left",
    BOTTOM: "bottom",
    RIGHT: "right",
};

const VARIANT = {
    PERMANENT: "permanent",
    TEMPORARY: "temporary",
};

const useStyles = createUseStyles((theme) => ({
    drawer: {
        zIndex: 99,
        position: "fixed",
        boxSizing: "border-box",
        backgroundColor: "#1E4063",
        transition: "background-color 0.8s ease",
    },
    [`drawer-${ANCHOR.TOP}`]: {
        top: 0,
        left: 0,
        width: "100vw",
        height: 120,
        overflow: "hidden",
        transition: (props) => `top ${props.transitionDuration}ms`,
    },
    [`drawer-${ANCHOR.LEFT}`]: {
        top: 108 + theme.calculateSpacing(2),
        left: 0,
        height: "100%",
        width: 231,
        overflowX: "hidden",
        overflowY: "auto",
        transition: "max-width 0.8s ease",
    },
    [`drawer-${ANCHOR.BOTTOM}`]: {
        bottom: 0,
        left: 0,
        width: "100vw",
        height: 120,
        overflow: "hidden",
        transition: (props) => `bottom ${props.transitionDuration}s`,
    },
    [`drawer-${ANCHOR.RIGHT}`]: {
        top: 0,
        right: 0,
        height: "100%",
        width: 600,
        overflowX: "hidden",
        overflowY: "auto",
        transition: (props) => `right ${props.transitionDuration}s ease`,
    },
    drawerElevation: {
        boxShadow: (props) =>
            props.elevation
                ? theme.getShadow(props.elevation, theme.colors.greys.disabled)
                : "none",
    },
    [`drawerMobile-${ANCHOR.TOP}`]: {
        top: 0,
    },
    [`drawerMobile-${ANCHOR.LEFT}`]: {
        left: 0,
    },
    [`drawerMobile-${ANCHOR.RIGHT}`]: {
        bottom: 0,
    },
    [`drawerMobile-${ANCHOR.BOTTOM}`]: {
        right: 0,
    },
    drawerMobileMenu: {
        display: "none",
    },
    [`@media screen and (max-width: ${BREAKPOINTS.sm}px)`]: {
        drawerMobileMenu: {
            display: "block",
        },
        [`drawer-${ANCHOR.TOP}`]: {
            top: "-240px",
            height: 120,
        },
        [`drawer-${ANCHOR.LEFT}`]: {
            left: "-100%",
            width: "100%",
        },
        [`drawer-${ANCHOR.BOTTOM}`]: {
            bottom: "-240px",
            height: 120,
        },
        [`drawer-${ANCHOR.RIGHT}`]: {
            right: "-100%",
            width: "100%",
        },
    },
}));

export function Drawer({
    className: classNameProp,
    component: Component,
    anchor,
    variant,
    onClose,
    elevation,
    transitionDuration,
    visible,
    rightWidth,
    ...props
}) {
    const [open, toggleDrawer] = useState(false);
    const classes = useStyles({
        elevation: variant === VARIANT.TEMPORARY ? elevation : 0,
        transitionDuration,
    });
    const drawerClasses = generateClassNames(
        classes.drawer,
        {
            [classes[`drawer-${ANCHOR.TOP}`]]: anchor === ANCHOR.TOP,
            [classes[`drawer-${ANCHOR.LEFT}`]]: anchor === ANCHOR.LEFT,
            [classes[`drawer-${ANCHOR.RIGHT}`]]: anchor === ANCHOR.RIGHT,
            [classes[`drawer-${ANCHOR.BOTTOM}`]]: anchor === ANCHOR.BOTTOM,
            [classes.drawerElevation]: variant === VARIANT.TEMPORARY,
            // ['']: open || variant === VARIANT.PERMANENT,
        },
        classNameProp
    );

    const handleClick = (event) => {
        event.preventDefault();
        if (open) {
            toggleDrawer(false);
        }
        if (onClose) {
            onClose();
        }
    };

    const maxWidth =
        anchor == ANCHOR.LEFT
            ? { maxWidth: visible ? 231 : 60 }
            : { width: visible ? rightWidth : 0 };

    return (
        <Component
            className={drawerClasses}
            onClick={handleClick}
            {...props}
            style={maxWidth}
        />
    );
}

Drawer.propTypes = {
    anchor: PropTypes.oneOf(Object.values(ANCHOR)),
    className: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(VARIANT)),
    onClose: PropTypes.func,
    elevation: PropTypes.number,
    transitionDuration: PropTypes.number,
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.elementType,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

Drawer.defaultProps = {
    anchor: ANCHOR.LEFT,
    className: undefined,
    component: "div",
    transitionDuration: 0.8,
    elevation: 5,
    rightWidth: 600,
};
