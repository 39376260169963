import { createSlice } from "@reduxjs/toolkit";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import toast from "components/@bank-ui/core/Toast/Toast";
import { refitKeys } from "utils/refitKeys";
import { assignDepth } from "utils/assignDepth";
import { getSessionStorageOrDefault } from "../../utils/getSessionStorageOrDefault";
import _ from "lodash";
import {
    getMarketCurrentModule
} from "routes/MarketRoute/selectors";
import { prospectReportBuilder } from "utils/prospectReportBuilder";

const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
    loading: false,
    loadingPrice:false,
    prospectLeadType: getSessionStorageOrDefault('prospectLeadType', 'consumer'),
    prospectList: null,
    prospectItemId: getSessionStorageOrDefault('itemId', null),
    prospectItem: getSessionStorageOrDefault('itemName', null),
    prospectLeadOptions: getSessionStorageOrDefault('prospectLeadOptions', null),
    productsHierarchy: getSessionStorageOrDefault('productsHierarchy', []),
    industryHierarchy: getSessionStorageOrDefault('industryHierarchy', null),    
    prospectReportQuote: null,
    prospectReportPrice: null,
    prospectReportDetails: null,
    definedReportDetails:null,
    leafNodes:[],
    dependencies:[],
    institutionSelections: [],
    industrySelections: [],
    titleNameSelections: [],
    hasSuppressionOption: false,
    suppressionOptions: {}
};

const marketProspectListSlice = createSlice({
    name: "marketProspectList",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            return {
                ...state,
                loading: payload,
            };
        },
        setDependencies(state, { payload }) {
            return {
                ...state,
                dependencies: payload,
            };
        },
        setHasSuppressionOption(state, { payload }) {
            return {
                ...state,
                hasSuppressionOption: payload
            }
        },
        setSuppressionOptions(state, { payload }) {
            return {
                ...state,
                suppressionOptions: payload
            }
        },
        setLeafNodes(state, { payload }) {            
            return {
                ...state,
                leafNodes: payload,
            };
        },
        setLoadingPrice(state, { payload }) {
            return {
                ...state,
                loadingPrice: payload,
            };
        },
        setMarketProspectLeadType(state, { payload }) {
            sessionStorage.setItem("prospectLeadType", JSON.stringify(payload));
            return {
                ...state,
                prospectLeadType: payload,
            };
        },
        setInstitutionSelections(state, { payload }) {
            return {
                ...state,
                institutionSelections: payload,
            }
        },
        setTitleNameSelections(state, { payload }) {
            return {
                ...state,
                titleNameSelections: payload,
            }
        },
        setIndustrySelections(state, { payload }) {
            return {
                ...state,
                industrySelections: payload,
            }
        },
        setMarketProspectLeadOptions(state, { payload }) {
            sessionStorage.setItem("prospectLeadOptions", JSON.stringify(payload));
            return {
                ...state,
                prospectLeadOptions: payload,
            };
        },
        setMarketProductsHierarchy(state, { payload }) {
            sessionStorage.setItem("productsHierarchy", JSON.stringify(payload));
            return {
                ...state,
                productsHierarchy: payload,
            };
        },
        setMarketIndustryHierarchy(state, { payload }) {
            sessionStorage.setItem("industryHierarchy", JSON.stringify(payload));
            return {
                ...state,
                industryHierarchy: payload,
            };
        },
        setMarketProspectList(state, { payload }) {
            return {
                ...state,
                prospectList: payload,
            };
        },
        setMarketProspectItemId(state, { payload }) {
            sessionStorage.setItem("itemId", JSON.stringify(payload));
            return {
                ...state,
                prospectItemId: payload,
            };
        },
        setMarketProspectItem(state, { payload }) {
            sessionStorage.setItem("itemName", JSON.stringify(payload));
            return {
                ...state,
                prospectItem: payload,
            };
        },
        setMarketProspectName(state, { payload }) {
            return {
                ...state,
                prospectItem: {
                    ...state.prospectItem,
                    name: payload,
                },
            };
        },
        setMarketProspectNotes(state, { payload }) {
            return {
                ...state,
                prospectItem: {
                    ...state.prospectItem,
                    notes: payload,
                },
            };
        },
        setMarketProspectSharedFlag(state, { payload }) {
            return {
                ...state,
                prospectItem: {
                    ...state.prospectItem,
                    sharedFlag: payload,
                },
            };
        },
        setMarketProspectGlobalFlag(state, { payload }) {
            return {
                ...state,
                prospectItem: {
                    ...state.prospectItem,
                    globalFlag: payload,
                },
            };
        },
        setMarketProspectItemFilters(state, { payload }) {
            let filters = [];
            let filter = null;


            // eslint-disable-next-line array-callback-return
            state.prospectItem?.savedfilters?.map((f) => {
                filters.push({
                    filter: f.filter,
                    options: Array.isArray(f.options)? [...f.options]: [f.options],
                });
            });


            // Get the existing filter to edit or create new one if one doesn't exist

            if (filters.filter((f) => f.filter === payload.key).length > 0) {
                filter = filters.filter((f) => f.filter === payload.key)[0];
            } else {
                
                filter = {
                    filter: payload.key,
                    options: payload.single ? "" : [],
                };
                filters.push(filter);
            }

            if (payload.single) {
                filter.options = Array.isArray(payload.value)? [...payload.value]: [payload.value];
            } else {
                let options = [...filter.options];
                
                if (payload.index < 0) {
                    let index = options.indexOf(payload.value);

                    if (index >= 0) {
                        options.splice(index, 1);
                    } else {
                        options.push(payload.value);
                    }
                } else {
                        options[payload.index] = payload.value;
                }
                filter.options = options;
            }
            
            // this section is for modifying options if there is Zip Code Filter in the array.
            // Request to be send to the API for Zip Codes should be array of individual Zip Code Strings
            let zipCodeFilter = filters.find((element) => element.filter === "ZipCode" || element.filter === "zip_code");
            if(zipCodeFilter && zipCodeFilter.options.length < 2){
                    let option = zipCodeFilter.options[0];
                    let modifiedZipOptions = _.split(option, ',').map((eachZipCode) => eachZipCode.trim());
                    let modifiedZipFilterObject
                    if(zipCodeFilter.filter === "ZipCode"){
                         modifiedZipFilterObject = {
                            filter: "ZipCode",
                            options: modifiedZipOptions
                        }
                    } else {
                        modifiedZipFilterObject = {
                            filter: "zip_code",
                            options: modifiedZipOptions
                        }
                    }
                    
                    let indexOfZip = filters.findIndex((element) => element.filter === "ZipCode" || element.filter === "zip_code");
                    filters.splice(indexOfZip, 1, modifiedZipFilterObject)

                    if(modifiedZipFilterObject.options[0] === ""){
                        filters = filters.filter((eachSelection) => eachSelection.options.length > 0 && eachSelection.options[0] !== "");
                    }
            };

            //  const filteredSelections = filters.filter((eachSelection) => eachSelection.options.length > 0 && eachSelection.options[0] !== "");
            
            return {
                ...state,
                prospectItem: {
                    ...state.prospectItem,
                    savedfilters: filters,
                },
            };
        },
        setMarketProspectReportQuote(state, { payload }) {
            return {
                ...state,
                prospectReportQuote: payload,
            };
        },
        setMarketProspectReportPrice(state, { payload }) {
            return {
                ...state,
                prospectReportPrice: payload,
            };
        },
        setDefinedReportDetails(state, { payload }) {
            return {
                ...state,
                definedReportDetails: payload,
            };
        },
        setMarketProspectReportDetails(state, { payload }) {
            return {
                ...state,
                prospectReportDetails: payload
            }
        },
        resetMarketProspectItemFilters(state, { payload }) {
            return {
                ...state,
                prospectItem: {
                    ...state.prospectItem,
                    savedfilters: payload,
                },
            };
        },
        resetMarketProspectLead() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setLoading,
    setDependencies,
    setLeafNodes,
    setLoadingPrice,
    setMarketProspectLeadType,
    setMarketProspectLeadOptions,
    setMarketProductsHierarchy,
    setMarketIndustryHierarchy,
    setMarketProspectList,
    setMarketProspectItemId,
    setMarketProspectItem,
    setMarketProspectName,
    setMarketProspectNotes,
    setMarketProspectSharedFlag,
    setMarketProspectGlobalFlag,
    setMarketProspectItemFilters,
    setMarketProspectReportQuote,
    setMarketProspectReportPrice,
    resetMarketProspectLead,
    resetMarketProspectItemFilters,
    setMarketProspectReportDetails,
    setDefinedReportDetails,
    setInstitutionSelections,
    setIndustrySelections,
    setTitleNameSelections,
    setHasSuppressionOption,
    setSuppressionOptions,
} = marketProspectListSlice.actions;

export function fetchMarketProspectLeadOptions() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        try {
            const {
                selected: { reportGrouping, reportBranches } = {},
            } = getState()?.market?.root;

            const { prospectLeadType } = getState()?.market?.prospectList;

            const currentModule = getMarketCurrentModule(getState());

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchOptions`
            );

            let optionsData = {...data};
            
            // We dont want to create a new tab for Suppression option. instead we insert this into save & order tab
            const suppressionOptions = optionsData?.categories?.find(({categoryName}) => categoryName === "Suppression");
            
            if (suppressionOptions) {
                const suppressionDataFilters = suppressionOptions.filters[0].options?.map((option) => {
                    return {
                        name: option.name,
                        value: option.value
                    }
                })

                const suppressionData = {
                    filter: suppressionOptions.filters[0].value,
                    options: [...suppressionDataFilters]
                };
             
                dispatch(setSuppressionOptions(suppressionData));
                dispatch(setHasSuppressionOption(true));
              } else {
                dispatch(setSuppressionOptions({}));
                dispatch(setHasSuppressionOption(false));
              }
                
            // Because of the reason stated above, we are filterin options object not to have Suppression category.    
            if (Array.isArray(optionsData?.categories)) {
                optionsData.categories = optionsData.categories.filter(({categoryName}) => categoryName !== "Suppression");
              }
            
            optionsData.categories.forEach((category)=> {
                    category.filters.forEach((filter) => {
                        filter.id = filter.value; 
                    });
            });

            if (status === 200) {
                await dispatch(setMarketProspectLeadOptions(optionsData));
                                                        }
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchMarketProspectLeadOptionsProductsHierarchy() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        try {
            const {
                selected: { reportGrouping } = {},
            } = getState()?.market?.root;

            const currentModule = getMarketCurrentModule(getState());

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/productsHierarchy`
            );

            if (status === 200) {
                const keysNew = ["id", "label"];
                const keysOld = ["productId", "name"];
                const newProductsHierarchy = refitKeys(
                    data,
                    (key) => keysNew[keysOld.indexOf(key)] || key
                );
                assignDepth(_.concat([], newProductsHierarchy));                
                await dispatch(setMarketProductsHierarchy(_.concat([], newProductsHierarchy)));
            }
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchMarketProspectLeadOptionsIndustryHierarchy() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        try {
            const {
                selected: { reportGrouping } = {},
            } = getState()?.market?.root;

            const currentModule = getMarketCurrentModule(getState());

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/industryHierarchy`
            );

            if (status === 200) {
                await dispatch(setMarketIndustryHierarchy(data));
            }
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchMarketprospectList() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        try {
            const {
                selected: { reportGrouping, reportBranches } = {},
            } = getState()?.market?.root;

            const { prospectLeadType } = getState()?.market?.prospectList;

            const currentModule = getMarketCurrentModule(getState());

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchQueries`
            );

            if (status === 200) {
                await dispatch(setMarketProspectList(data));
            }
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function fetchMarketprospectItem() {
    return async (dispatch, getState) => {
        await dispatch(setLoading(true));

        try {
            const {
                selected: { reportGrouping, reportBranches } = {},
            } = getState()?.market?.root;

            const {
                prospectLeadType,
                prospectItemId,
            } = getState()?.market?.prospectList;

            const currentModule = getMarketCurrentModule(getState());

            const { status, data } = await https.get(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchQueries/${prospectItemId}`
            );

            if (status === 200) {
                await dispatch(setMarketProspectItem(data));
            }
        } catch (error) {
            errorHandler(getErrorMessage(error));
        }

        await dispatch(setLoading(false));
    };
}

export function createProspectItem() {
    return async (dispatch, getState) => {
        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const {
            prospectLeadType,
            prospectItem,
        } = getState()?.market?.prospectList;

        const currentModule = getMarketCurrentModule(getState());

        const payload = JSON.stringify(prospectItem);

        https
            .post(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchQueries`,
                payload
            )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Prospect is created successfully",
                });
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
    };
}

export function saveProspectItem() {
    return async (dispatch, getState) => {
        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const {
            prospectLeadType,
            prospectItemId,
            prospectItem,
        } = getState()?.market?.prospectList;

        const currentModule = getMarketCurrentModule(getState());

        const payload = JSON.stringify(prospectItem);
        
        https
            .put(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchQueries/${prospectItemId}`,
                payload
            )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Prospect is saved successfully",
                });
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
    };
}

export function deleteProspectItem(prospectItemId) {
    return async (dispatch, getState) => {
        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const { prospectLeadType } = getState()?.market?.prospectList;

        const currentModule = getMarketCurrentModule(getState());

        https
            .delete(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchQueries/${prospectItemId}`
            )
            .then((response) => {
                toast.success({
                    header: "Save successful",
                    message: "Prospect is deleted successfully",
                });
                dispatch(fetchMarketprospectList());
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
    };
}

function GetPriceFilters(state){

    const {
        prospectItem,
        leafNodes,
        prospectLeadType
    } = state.market?.prospectList;

    let priceFilters = [];

    prospectItem.savedfilters.forEach((filter) => {
        
        if (filter.options && filter.options.length>0){

            //Add Institution hierarchy only when no zip code is entered 
            if (filter.filter === "BranchId" || (filter.filter === "ZipCode" || filter.filter === "zip_code")
                || filter.filter === "DMA"){

               let zipFilter = prospectItem.savedfilters.filter((f) => (f.filter === "ZipCode" || f.filter === "zip_code")).length?
               prospectItem.savedfilters.filter((f) => (f.filter === "ZipCode" || f.filter === "zip_code"))[0]:null;
                                  
               let zipOption = zipFilter && zipFilter.options[0];

               if(filter.filter === "BranchId" && !zipOption)
               {
                    let newValue = [];
                    // Only send the leaf branches in the hierarchy
                    filter.options.forEach((v) => {
                        if(leafNodes.find((f)=> f === v))
                            newValue.push(v);
                    });
                    
                    priceFilters.push({
                        filter:filter.filter,
                        options:newValue
                    });
               }
               
               if(filter.filter === "DMA" && !zipOption) 
                    priceFilters.push(filter);

               if((filter.filter === "ZipCode" || filter.filter === "zip_code") && zipOption) 
                    priceFilters.push(filter); 
            }
            else        
                priceFilters.push(filter);   

        }

    });
    
    if(prospectLeadType === "business") {
        priceFilters = priceFilters.map(function(filter){ return { filter: filter.filter, options: filter.options.map((element) => element && element?.replaceAll(/,/g, ''))};});
    }
    
    const controlledPriceFilters = priceFilters.filter((eachObject) => {
        let optionsArray = eachObject?.options;
        if(optionsArray.length > 1 && ((optionsArray[0] === "") && optionsArray[1] === "")) return false;
        if(optionsArray.length > 1 && ((!optionsArray[0]) && !optionsArray[1])) return false;
        if(optionsArray.length === 1 && !optionsArray[0]) return false;
        return true; 
    })

    return controlledPriceFilters;

}

export function downloadSelectedReport(reportType) {
    return async (dispatch, getState) => {

        dispatch(setLoadingPrice(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const {
            prospectLeadType,
            prospectReportQuote,
            institutionSelections,
            industrySelections,
            titleNameSelections,
            prospectLeadOptions
        } = getState()?.market?.prospectList;

      let query = { filters: GetPriceFilters(getState()) };

      // Rarely, query object includes filter criterias includes empty string
      // At this step, we are filtering all criterias whose options array includes empty string values
      let filteredQuery = query.filters.filter((eachFilter) => !eachFilter.options.every(element => element === ""))
    
      if(prospectLeadType === "business"){
        filteredQuery = filteredQuery.filter((item) => item.filter !== "AllRecPerBusO")
      }; 

      const cleanPayload = {
        filters: filteredQuery
        }

      if (prospectReportQuote) {
            cleanPayload.count = prospectReportQuote;
        }

        const currentModule = getMarketCurrentModule(getState());
      
        const payload = JSON.stringify(cleanPayload);

        await https
            .post(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/reports?reportName=${reportType}`,
                payload
            )
            .then((response) => {
            
                const dataToManipulate = response.data;
                const businessEstimates = dataToManipulate?.businessEstimates?.prospects;
                const finalizedData = prospectReportBuilder(dataToManipulate, businessEstimates, cleanPayload, institutionSelections, industrySelections, titleNameSelections, prospectLeadOptions);
                
                dispatch(setDefinedReportDetails(finalizedData));
            })
            .catch((error) => {
                errorHandler(getErrorMessage(error));
                dispatch(setDefinedReportDetails(null));
            });

        dispatch(setLoadingPrice(false));
    };
}

export function queryProspectReportPrice() {
    return async (dispatch, getState) => {

        dispatch(setLoadingPrice(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const {
            prospectLeadType,
            prospectReportQuote,
        } = getState()?.market?.prospectList;

      let query = { filters: GetPriceFilters(getState()) };

      // Rarely, query object includes filter criterias includes empty string
      // At this step, we are filtering all criterias whose options array includes empty string values
      let filteredQuery = query.filters.filter((eachFilter) => !eachFilter.options.every(element => element === ""))
    
      if(prospectLeadType === "business"){
        filteredQuery = filteredQuery.filter((item) => item.filter !== "AllRecPerBusO")
      }; 

      const cleanPayload = {
        filters: filteredQuery
        }

      if (prospectReportQuote) {
            cleanPayload.count = prospectReportQuote;
        }

        const currentModule = getMarketCurrentModule(getState());

        const payload = JSON.stringify(cleanPayload);

        await https
            .post(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchPrice`,
                payload
            )
            .then((response) => {
                dispatch(setMarketProspectReportPrice(response.data));
            })
            .catch((error) => {
                errorHandler(getErrorMessage(error));
                dispatch(setMarketProspectReportPrice(null));
            });

        dispatch(setLoadingPrice(false));
    };
}

export function orderProspectReport(orderType) {
    return async (dispatch, getState) => {

        dispatch(setLoadingPrice(true));

        const {
            selected: { reportGrouping, reportBranches } = {},
        } = getState()?.market?.root;

        const {
            prospectLeadType,
            prospectReportQuote,
        } = getState()?.market?.prospectList;

        const currentModule = getMarketCurrentModule(getState());

        const params = {
            listType:orderType
        };    

        let query = { filters: GetPriceFilters(getState()) };

        let filteredQuery = query.filters.filter((eachFilter) => !eachFilter.options.every(element => element === ""))

        if(prospectLeadType === "business"){
            filteredQuery = filteredQuery.filter((item) => item.filter !== "AllRecPerBusO")
        };    

        const cleanPayload = {
            filters: filteredQuery
            }

        if (prospectReportQuote) {
                cleanPayload.count = prospectReportQuote;
            }

        const payload = JSON.stringify(cleanPayload);
        
        https
            .post(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/prospects/${prospectLeadType}/searchOrder`,
                payload, { params }
            )
            .then((response) => {
                dispatch(setMarketProspectReportDetails(response.data));
                toast.success({
                    header: "Order successful",
                    message: "Order is successfully processed",
                });
            })
            .catch((error) => {
                errorHandler(getErrorMessage(error));
            });

            dispatch(setLoadingPrice(false));
    };
}

export default marketProspectListSlice.reducer;
