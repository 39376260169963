export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};

export const SCREEN_SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
};

export function breakPointMediaQuery(size) {
  return `@media screen and (min-width: ${BREAKPOINTS[size]}px)`;
}

export function mediaQueryStyle(size) {
  return `@media screen and (max-width: ${BREAKPOINTS[size]}px)`;
}
