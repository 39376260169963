/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
import { createUseStyles } from "react-jss";
import { TextField } from "@mui/material";
import Divider from "components/layout/Divider/Divider";
import Button from "components/global/Button";
import { useDispatch, useSelector } from "react-redux";
import {
    validatePassword,
    data_after_validation,
    changePassword,
    setShowChangePassword,
    setDataAfterValidation,
    force_change_password,
    current_password,
    new_password,
    confirm_password,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    complexcity_password,
} from "../../routes/AuthenticationRoute/AuthenticationSlice";
import PasswordComlexity from "utils/passwordComplexity";
import toast from "components/@bank-ui/core/Toast/Toast";
import { generateClassNames } from "utils/generateClassNames";

const useStyles = createUseStyles((theme) => ({
    wrapper: {
        width: "100%",
        "& div": {
            margin: 10,
            color: "#666666",
        },
    },

    currentPasswordForm: {
        maxWidth: "100%",
        width: "100%",
        marginTop: "3rem !important",
        marginLeft: "3.5rem !important",
    },

    newPasswordForm: {
        maxWidth: "100%",
        width: "100%",
        marginTop: "2rem !important",
        marginLeft: "3rem !important",
        display: "flex",
        flexDirection: "column",
    },

    currentPassword: {
        width: "350px",
        "& label": {
            fontWeight: "bold",
        },
        "& .MuiFilledInput-root": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: "rgb(255, 255, 255)",
        },
    },
    validateButton: {
        marginTop: "1.8rem !important",
        marginLeft: "1.5rem !important",
    },
    divider: {
        width: "auto",
    },
    selectRatioBottom: {
        width: "800px",
        paddingBottom: 42,
        paddingRight: 30,
        backgroundColor: "white",
    },
    button: {
        fontSize: 12,
        width: "auto",
        height: "38px",
        float: "right",
        marginRight: "20px",
        color: "#fff",
    },
    buttonCancel: {
        height: "38px",
        //color: "#000",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        color: "#3675D0",
        float: "right",
        fontSize: 12,
    },
    disableCursor: {
        cursor: "default",
    },
}));

const ChangePasswordForm = ({ token, userName }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataAfterValidation = useSelector(data_after_validation);
    const forceChangePassword = useSelector(force_change_password);
    const currentPassword = useSelector(current_password);
    const newPassword = useSelector(new_password);
    const confirmPassword = useSelector(confirm_password);
    const passComplexity = useSelector(complexcity_password);

    const handleCurrentPassword = (event) => {
        dispatch(setCurrentPassword(event.target.value));
    };

    const handleNewPassword = (event) => {
        dispatch(setNewPassword(event.target.value));
    };

    const handleConfirmPassword = (event) => {
        dispatch(setConfirmPassword(event.target.value));
    };

    const onClose = () => {
        dispatch(setCurrentPassword(""));
        dispatch(setNewPassword(""));
        dispatch(setConfirmPassword(""));
        dispatch(setDataAfterValidation(""));
        dispatch(setShowChangePassword());
        if (forceChangePassword) {
            sessionStorage.clear();
            window.location.reload();
        }
    };

    const handleValidate = () => {
        const emailId =
            userName && userName.includes(":")
                ? userName.split(":")[1]
                : userName;
        dispatch(validatePassword(emailId, window.btoa(currentPassword)));
    };

    const handleSavePassword = () => {
        if (newPassword !== confirmPassword) {
            toast.error({
                message: "New Passwords do not match. Try again.",
            });
            return;
        }

        dispatch(
            changePassword(
                token,
                window.btoa(currentPassword),
                window.btoa(newPassword)
            )
        );
        dispatch(setNewPassword(""));
        dispatch(setConfirmPassword(""));
    };

    // Testing Fortify Scan Fixes
    // Second test

    return (
        <div className={classes.wrapper}>
            <div className={classes.currentPasswordForm}>
                <TextField
                    className={classes.currentPassword}
                    variant="filled"
                    id="currentPassword"
                    type="password"
                    onChange={(event) => handleCurrentPassword(event)}
                    label="Current Password"
                    value={currentPassword}
                    required
                    InputProps={{
                        disableUnderline: true,
                    }}
                    disabled={dataAfterValidation}
                />
                <Button
                    type="button"
                    label={`Validate`}
                    onClick={handleValidate}
                    className={generateClassNames(
                        classes.validateButton,
                        !currentPassword || dataAfterValidation
                            ? classes.disableCursor
                            : null
                    )}
                    disabled={!currentPassword || dataAfterValidation}
                />
            </div>
            {dataAfterValidation ? (
                <>
                    <div className={classes.changePasswordSection}>
                        <Divider
                            orientation={"horizontal"}
                            className={classes.divider}
                        />
                        <div className={classes.newPasswordForm}>
                            <TextField
                                className={classes.currentPassword}
                                variant="filled"
                                id="newPassword"
                                type="password"
                                onChange={(event) => handleNewPassword(event)}
                                label="New Password"
                                value={newPassword}
                                required
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                            <PasswordComlexity value={newPassword} />
                            <TextField
                                className={classes.currentPassword}
                                variant="filled"
                                id="confirmPassword"
                                type="password"
                                onChange={(event) =>
                                    handleConfirmPassword(event)
                                }
                                label="Confirm Password"
                                value={confirmPassword}
                                required
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.selectRatioBottom}>
                        <Divider
                            orientation={"horizontal"}
                            className={classes.divider}
                        />
                        <Button
                            type="button"
                            label={`Save`}
                            onClick={handleSavePassword}
                            className={generateClassNames(
                                classes.button,
                                !newPassword || !confirmPassword
                                    ? classes.disableCursor
                                    : null
                            )}
                            disabled={
                                !(newPassword == confirmPassword) ||
                                passComplexity
                            }
                        />
                        <Button
                            type="button"
                            label={`Cancel`}
                            onClick={onClose}
                            className={classes.buttonCancel}
                        />
                    </div>
                </>
            ) : null}
        </div>
    );
};
export default ChangePasswordForm;
