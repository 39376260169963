import { combineReducers, createSlice } from "@reduxjs/toolkit";
import marketdataReviewMapReducer from "routes/MarketDataReviewMap/marketDataReviewMapSlice";
import marketdataReviewTableReducer from "routes/MarketDataReviewTable/marketDataReviewTableSlice";

const initialState = {};

const marketDataReviewSlice = createSlice({
    name: "marketDataReview",
    initialState,
    reducers: {
        resetMarketDataReview() {
            return {
                ...initialState,
            };
        },
    },
});

export const { resetMarketDataReview } = marketDataReviewSlice.actions;

export default combineReducers({
    root: marketDataReviewSlice.reducer,
    map: marketdataReviewMapReducer,
    table: marketdataReviewTableReducer,
});
