/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import PropTypes from "prop-types";
import VerticalNavTab from "./VerticalNavTab";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import Svg from "../Svg";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import {
    restrictedMenuList,
    restrictedNewMenuMarket_auth,
} from "routes/AuthenticationRoute/AuthenticationSlice";

const useStyles = createUseStyles({
    disableTabs: {
        pointerEvents: "none",
    },
});

const swingInTopFwd = keyframes`
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
`;

const ActiveIndicator = styled.div`
    content: "";
    display: block;
    height: 6px;
    background-color: ${(p) => p.theme.palette.accent.blueDark};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all ease-in-out 250ms;
`;

const SubMenu = styled.div`
    position: absolute;
    white-space: nowrap;
    z-index: 2;
    margin: 0;
    padding: 8px 0;
    left: 0;
    display: none;
    border-radius: 0;
    width: 280px;
    max-width: 280px;
    background: ${(p) => p.theme.palette.background.paper};
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

const SubMenuNav = styled.nav`
    display: flex;
    flex-direction: column;
`;

const ListItem = styled.li`
    display: inline-block;
    position: relative;
    margin: 0 8px;
    cursor: pointer;
    .horizontal-link-item {
        height: 100%;
        text-decoration: none;
        overflow: hidden;
        font-size: 20px;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
        color: ${(p) =>
            p.active
                ? p.theme.palette.text.accent.blueDark
                : p.theme.palette.text.accent.blue};
        span {
            padding: 20px 16px;
            svg {
                fill: ${(p) =>
                    p.active
                        ? p.theme.palette.text.accent.blueDark
                        : p.theme.palette.text.accent.blue};
                margin-left: 8px;
                height: 12px;
                width: 12px;
                transition: transform 150ms ease;
            }
        }
    }
    &:hover {
        background: ${(p) => p.theme.palette.background.hover};
    }
    &:hover > .horizontal-link-item {
        color: ${(p) => p.theme.palette.text.accent.green};
    }
    &:hover > ${ActiveIndicator} {
        display: none;
    }
    &:hover > .horizontal-link-item > span > svg {
        transform: rotate(-180deg);
        transition: transform 150ms ease;
    }
    &:hover > ${SubMenu} {
        display: block;
        animation: ${swingInTopFwd} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
            both;
    }
    &:hover > .horizontal-link-item::before {
        width: ${(p) => (p.hassubmenu ? "0%" : "100%")};
    }
    .horizontal-link-item::before {
        content: "";
        display: block;
        height: 6px;
        background-color: ${(p) => p.theme.palette.accent.green};
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        transition: all ease-in-out 250ms;
    }
    ${(props) =>
        props.hassubmenu &&
        css`
            &:after {
                content: "";
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transition: opacity 0.15s ease-in-out;
                opacity: 0;
                visibility: hidden;
                background-color: rgba(0, 0, 0, 0.5);
                pointer-events: none;
            }

            &:hover:after {
                z-index: 1;
                opacity: 1;
                visibility: visible;
                position: fixed;
            }
        `}
`;

export default function HorizontalNavTab({
    selectedType,
    itemProps,
    restricted,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { route: { id, label, path, subNavItems } = {} } = itemProps;
    const { unAthorizeReportMarket, unAthorizeReportFinancial } = useSelector(
        (state) => ({
            unAthorizeReportMarket: state.market.root.unAthorizeReport,
            unAthorizeReportFinancial: state.reports.unAthorizeReport,
        })
    );
    const newFilterdNav = useSelector(restrictedNewMenuMarket_auth);

    useEffect(() => {
        if (subNavItems.length > 0 && restricted !== null) {
            dispatch(restrictedMenuList(subNavItems, restricted, id));
        }
    }, [selectedType]);

    // eslint-disable-next-line eqeqeq
    const newDataList = id == "market" ? newFilterdNav : subNavItems;
    return (
        <ListItem
            key={id}
            active={_.includes(pathname, path)}
            hassubmenu={newDataList.length > 0 ? 1 : undefined}
            className={
                (!unAthorizeReportFinancial && id === "finance") ||
                (!unAthorizeReportMarket && id === "market")
                    ? classes.disableTabs
                    : ""
            }
        >
            <Link
                className="horizontal-link-item"
                to={newDataList.length > 0 ? newDataList[0].path : path}
            >
                <span>
                    {label}
                    {newDataList.length > 0 && (
                        <Svg SvgComponent={ChevronDownIcon} />
                    )}
                </span>
            </Link>
            {_.includes(pathname, path) && <ActiveIndicator />}
            {newDataList.length > 0 && (
                <SubMenu>
                    <SubMenuNav>
                        {_.map(newDataList, (subNavItem) => {
                            return (
                                <VerticalNavTab
                                    key={subNavItem.id}
                                    itemProps={_.assign(
                                        {},
                                        { route: subNavItem }
                                    )}
                                />
                            );
                        })}
                    </SubMenuNav>
                </SubMenu>
            )}
        </ListItem>
    );
}

HorizontalNavTab.propTypes = {
    itemProps: PropTypes.shape({
        route: PropTypes.shape({
            id: PropTypes.string,
            path: PropTypes.string,
            label: PropTypes.string,
            subNavItems: PropTypes.array,
        }),
    }),
};
