import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer
      position="top-center"
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
      limit={1}
      {...rest}
    />
  </div>
);

export default styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
    width: auto;
  }
  .Toastify__toast {
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    padding: 16px;
    font-family: ${(p) => p.theme.typography.fontFamily};
  }
  .Toastify__toast--error {
    background: ${(p) => p.theme.palette.error.main};
    color: ${(p) => p.theme.palette.common.white};
  }
  .Toastify__toast--warning {
    background: ${(p) => p.theme.palette.warning.main};
    color: ${(p) => p.theme.palette.common.white};
  }
  .Toastify__toast--success {
    background: ${(p) => p.theme.palette.success.main};
    color: ${(p) => p.theme.palette.common.white};
  }
  .Toastify__toast--info {
    background: ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.common.white};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
