/**
 * Utility that takes pageName and data as input and returns transformed headers, rows table data for pageName
 * Market Reports exclusively call this util to generate required excel data that is to be exported
 */

import _ from "lodash";
import { pageNames } from "constants/pageNames";
import {
    getIdentifyHeaders,
    getRecommendHeaders,
    getQuantifyHeaders,
    getMEAHeaders,
    getBOHeaders
} from "./getMarketHeaders";

export const getMarketTableData = (pageName, data) => {
    
    if (pageName.match(new RegExp(pageNames.MARKET_SUMMARY))) {
        return getSummaryTableData(pageName, data);
    }
    
    if (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW))) {
        return getIdentifyTableData(pageName, data);
    }

    if (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW_MEA_TABLE))) {
        return getMEATableData(pageName, data);
    }

    if (pageName.match(new RegExp(pageNames.MARKET_BRANCH_OPTIMIZATION_TABLE))) {
        return getBOTableData(pageName, data);
    }

    if (pageName.match(new RegExp(pageNames.MARKET_DATAREVIEW_MAP))) {
        let rows = [];
        let headers = [];
        _.map(data, (item) => {
            headers = _.keys(item);
            let row = {};
            _.map(_.keys(item), (key) => {
                row = _.assign(row, {
                    [key]: item[key],
                });
            });
            rows = _.concat(rows, row);
        });

        return {
            pageName,
            headers: headers,
            rows: rows,
        };
    }

    if (pageName.match(new RegExp(pageNames.MARKET_RECOMMENDATION))) {
        return getRecommendTableData(pageName, data);
    }

    if (pageName.match(new RegExp(pageNames.MARKET_QUANTIFY))) {
        return getQuantifyTableData(pageName, data);
    }

    if (pageName.match(new RegExp(pageNames.MARKET_EXECUTIVE_SUMMARY_MATRIX))) {
        return getMatrixTableData(pageName, data);
    }
};

export const getSummaryTableData = (pageName, data) => {   

    if (data.dashboardName === "STRENGTH_AND_WEAKNESS_DASHBOARD") {       
        
        let rows = [];
        let headers = [];
        _.map(data.strength, (item) => {
            headers = _.keys(item);
            let row = {};
            _.map(_.keys(item), (key) => {
                row = _.assign(row, {
                    [key]: item[key],
                });
            });
            row["TypeOfData"] = "Strength";
            rows = _.concat(rows, row);
        });

        _.map(data.weakness, (item) => {            
            let row = {};
            _.map(_.keys(item), (key) => {
                row = _.assign(row, {
                    [key]: item[key],
                });
            });
            row["TypeOfData"] = "Weakness";
            rows = _.concat(rows, row);
        });

        headers[headers.length-1] = "TypeOfData";

        return {
            pageName:"StrengthWeakness",
            headers: headers,
            rows: rows,
        };

    } else {
        const contentLabels = [
            { id: "institutionName", label: "Institution" },
            { id: "numberOfOffices", label: "Number of Offices" },
            { id: "location", label: "City/State" },
            { id: "callLabel", label: "Call Label" },
            { id: "competitionType", label: "Competition Type" },
            { id: "marketType", label: "Market Type" },
        ];

        function getContentLabelValue(summary, label) {
            if (label === "callLabel") return summary?.callValue;
            if (label === "location") {
                const city = summary?.city;
                const state = summary?.state;

                if (city && state) return `${city}, ${state}`;
                if (city && !state) return city;
                if (!city && state) return state;
                return null;
            }
            return summary[label];
        }

        let headers = null,
            rows = [];
        if (!_.isEmpty(data)) {
            _.map(contentLabels, (contentLabel) => {
                const value = getContentLabelValue(data, contentLabel.id);
                const row = {
                    headerCol:
                        contentLabel.id === "callLabel"
                            ? data?.callLabel
                            : contentLabel.label,
                    valueCol: value,
                };
                rows = _.concat(rows, row);
            });
        }

        return { pageName:"Summary", headers, rows };
    }
    
};

export const getIdentifyTableData = (pageName, { marketData }) => {
    let headers = [];
    let rows = [];
    if (marketData && marketData.length > 0) {
        headers = _.concat(
            _.filter(
                getIdentifyHeaders(marketData),
                (header) => !!header.label
            ),
            [
                _.concat(
                    [],
                    _.map(headers, (header) => header.label)
                ),
            ]
        );

        _.map(marketData, (dataItem) => {
            let row = {};
            _.map(headers, (header) => {
                row = _.assign(row, {
                    [header.label]: dataItem[header.id],
                });
            });
            rows = _.concat(rows, row);
        });

        rows = _.filter(rows, (e) => e);

        headers = _.concat(
            [],
            _.map(headers, (header) => header.label)
        );
    }

    return { pageName, headers, rows };
};

export const getRecommendTableData = (pageName, data) => {
    let headers = [],
        rows = [];

    if (data && data.length > 0) {
        _.forEach(data, function (recommendationItem) {
            const { branchId, countyId, countyName, name } = recommendationItem;

            if (branchId === null && countyId !== null)
                recommendationItem["branchName"] = countyName;

            if (branchId === null && countyId === null)
                recommendationItem["branchName"] = name;
        });
        headers = _.concat(
            _.filter(
                getRecommendHeaders(data),
                (header) =>
                    !!header.label &&
                    header.label !== null &&
                    header.label !== "null"
            ),
            [
                _.concat(
                    [],
                    _.map(headers, (header) => header.label)
                ),
            ]
        );
        _.map(data, (dataItem) => {
            let row = {};
            _.map(headers, (header) => {
                row = _.assign(row, {
                    [header.label]: dataItem[header.id],
                });
            });
            rows = _.concat(rows, row);
        });

        rows = _.filter(rows, (e) => e);

        headers = _.concat(
            [],
            _.map(headers, (header) => header.label)
        );
    }

    return { pageName, headers, rows };
};

export function getQuantifyTableData(pageName, data) {
    let headers = [],
        rows = [];

    if (data && data.length > 0) {
        headers = _.concat(
            _.filter(getQuantifyHeaders(data), (header) => !!header.label),
            [
                _.concat(
                    [],
                    _.map(headers, (header) => header.label)
                ),
            ]
        );
        _.map(data, (dataItem) => {
            let row = {};
            _.map(headers, (header) => {
                row = _.assign(row, {
                    [header.label]: dataItem[header.id],
                });
            });
            rows = _.concat(rows, row);
        });

        rows = _.filter(rows, (e) => e);

        headers = _.concat(
            [],
            _.map(headers, (header) => header.label)
        );
    }

    return { pageName, headers, rows };
}

export function getMatrixTableData(pageName, data) {
    let headers = [],
        rows = [];

    headers = [
        { id: "name", label: "Institution" },
        { id: "value0", label: data[0].header0 },
        { id: "value1", label: data[0].header1 },
        { id: "value2", label: data[0].header2 },
        { id: "value3", label: data[0].header3 },
        { id: "value4", label: data[0].header4 },
    ];

    if (data && data.length > 0) {
        _.map(data, (dataItem) => {
            let row = {};
            _.map(headers, (header) => {
                row = _.assign(row, {
                    [header.label]: dataItem[header.id],
                });
            });
            rows = _.concat(rows, row);
        });

        rows = _.filter(rows, (e) => e);

        headers = _.concat(
            [],
            _.map(headers, (header) => header.label)
        );
    }

    return { pageName, headers, rows };
}

export function getMEATableData(pageName, data) {
    let headers = [],
        rows = [];

    if (data && data.length > 0) {
        headers = _.concat(
            _.filter(getMEAHeaders(data), (header) => !!header.label),
            [
                _.concat(
                    [],
                    _.map(headers, (header) => header.label)
                ),
            ]
        );
        _.map(data, (dataItem) => {
            let row = {};
            _.map(headers, (header) => {
                row = _.assign(row, {
                    [header.label]: dataItem[header.id],
                });
            });
            rows = _.concat(rows, row);
        });

        rows = _.filter(rows, (e) => e);

        headers = _.concat(
            [],
            _.map(headers, (header) => header.label)
        );
    }

    return { pageName, headers, rows };
}

export function getBOTableData(pageName, data) {
    let headers = [],
        rows = [];

    if (data && data.length > 0) {
        headers = _.concat(
            _.filter(getBOHeaders(data), (header) => !!header.label),
            [
                _.concat(
                    [],
                    _.map(headers, (header) => header.label)
                ),
            ]
        );
        _.map(data, (dataItem) => {
            let row = {};
            _.map(headers, (header) => {
                row = _.assign(row, {
                    [header.label]: dataItem[header.id],
                });
            });
            rows = _.concat(rows, row);
        });

        rows = _.filter(rows, (e) => e);

        headers = _.concat(
            [],
            _.map(headers, (header) => header.label)
        );
    }

    return { pageName, headers, rows };
}