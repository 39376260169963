/**
 * Utility to return endpoints for each route that has to be saved as part of report
 * This endpoint will later be utilized to generate excel reports
 */
import { RATIOS } from "constants/market";

const { VITE_API_BASE_URL } = import.meta.env;
export function getReportDMAParam(reportDMA) {
    return reportDMA ? `dma=${reportDMA}` : "dma=CUSTOM";
}

function getBrancyByNameParam(branchBy) {
    return branchBy ? `showBranchBy=NAME` : 'showBranchBy=ADDRESS';
}

function getRegionParam(region) {
    return region ? `region=${region}` : "region=REGION";
}

function getListTypeParam(listType) {
    return listType ? `listType=${listType}` : "listType=";
}

function getTabRatioIdParam(tabRatioId, branchView, distance) {
    if (tabRatioId === RATIOS.GROWTH_RATIO_ID) {
        return branchView ? `expansionArea=${branchView === "R" ? "B" : branchView}` : "";
    }
    if (tabRatioId === RATIOS.EXPANSION_RATIO_ID) {
        return distance
        ? `expansionArea=BYDISTANCE&expansionAreaInDistance=distance`:`expansionArea=S`;
    }
    return "";
}

export function getRecommendSortOrderParam(tabRatioId, sortColumnDetailsRecommendx) {

    if(sortColumnDetailsRecommendx){
        return `sortColumnNumber=${sortColumnDetailsRecommendx.sortNumber}&columnSortOrder=${sortColumnDetailsRecommendx.sortOrder}`;
    } else {
        let sortColumnNumber = 4;
        let columnSortOrder = "DESCENDING";

        if (
            tabRatioId === RATIOS.GROWTH_RATIO_ID ||
            tabRatioId === RATIOS.EXPANSION_RATIO_ID
        ) {
            sortColumnNumber = 6;
            columnSortOrder = "DESCENDING";
        }
        return `sortColumnNumber=${sortColumnNumber}&columnSortOrder=${columnSortOrder}`;
    }

};

export function getQuantifySortOrderParam(sortColumnNumber = 4, columnSortOrder = "DESCENDING") {    

    return `sortColumnNumber=${sortColumnNumber}&columnSortOrder=${columnSortOrder}`;

}

export function getDataReviewTableSortOrderParam(sortColumnNumber = 2, columnSortOrder = "DESCENDING") {

    return `sortColumnNumber=${sortColumnNumber}&columnSortOrder=${columnSortOrder}`;

}

export function getMatrixTableSortOrderParam(sortColumnNumber = 2, columnSortOrder = "DESCENDING") {

    return `sortColumnNumber=${sortColumnNumber}&columnSortOrder=${columnSortOrder}`;

}

export function getReportEndpoints(path, selected = {}, mapFilters, selectedRoot, branchMatrixListType, sort, moduleFilters, sortColumnDetailsQuantify, sortColumnDetailsMatrix, sortColumnDetailsRecommendGrowth, sortColumnDetailsRecommendExpansion, sortColumnDetailsRecommendRationalize, sortColumnDetailsIdentify) {
        
     if (path === "FINANCIAL") {
        const endpoints = [];
        
        endpoints.push(`${VITE_API_BASE_URL}/api/v1/${selected?.bankAnalyst}/products/financial/reports/${selected?.reportId}/summary?accountingType=${selected?.accountingType}&reportingPeriod=${selected?.reportingPeriod}&sort=${selected?.sortedValue || 'DEFAULT'}`);

        endpoints.push(`${VITE_API_BASE_URL}/api/v1/${selected?.bankAnalyst}/products/financial/reports/${selected?.reportId}/peerGroups/${selected?.peerGroupId}&sort=${selected?.sortedValue || 'DEFAULT'}`);

        endpoints.push(`${VITE_API_BASE_URL}/api/v1/${selected?.bankAnalyst}/products/financial/reports/${selected?.reportId}/peerGroups/${selected?.peerGroupId}/dashboards/performance?accountingType=${selected?.accountingType}&reportingPeriod=${selected?.reportingPeriod}&sort=${selected?.sortedValue || 'DEFAULT'}`);
        return {
            enable: true,
            endpoints: endpoints,
        };
    }
     if (path === "FINANCIAL_RATIOS" || path === 'Financial Ratios PeerTable') {
        const endpoints = [];

         endpoints.push(
                `${VITE_API_BASE_URL}/api/v1/${selected?.bankAnalyst}/products/financial/reports/${selected?.reportId}/peerGroups/${selected?.peerGroupId}/ratioHierarchy?accountingType=${selected?.accountingType}&reportingPeriod=${selected?.reportingPeriod}`,
            );

        endpoints.push(
                `${VITE_API_BASE_URL}/api/v1/${selected?.bankAnalyst}/products/financial/reports/${selected?.reportId}/peerGroups/${selected?.peerGroupId}/ratioHierarchy/${selected?.ratioId}?accountingType=${selected?.accountingType}&reportingPeriod=${selected?.reportingPeriod}`,
            );

        return {
            enable: true,
            endpoints: endpoints,
        };
    }


    if (path === "MARKET_SUMMARY") {
        const endpoints = [];

        endpoints.push(
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/summary`,
            );

        endpoints.push(
            `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/dashboard/strengthAndWeakness?_DMA_`,
        );

        return {
            enable: true,
            endpoints: endpoints,
        };
    }

    if (path === "MARKET_DATAREVIEW_TABLE") {

        return {
            enable: true,
            endpoints: [
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                    selected?.ratioId
                }?_DMA_&${getRegionParam(selected?.region)}&${getListTypeParam(
                    selected?.listType
                )}&${getBrancyByNameParam(
                    selectedRoot?.showBranchByName
                )}&${getDataReviewTableSortOrderParam(sortColumnDetailsIdentify?.sortNumber, sortColumnDetailsIdentify?.sortOrder)}`,
            ],
        };

    }

    if (path === "MARKET_DATAREVIEW_MAP") {
        const endpoints = [];

        endpoints.push(
            `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                selectedRoot?.ratioId
            }?_DMA_&${getRegionParam(selectedRoot?.region)}&${getListTypeParam(
                selectedRoot?.listType
            )}`,
        );

        if (mapFilters && mapFilters.branches)
            endpoints.push(
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                    selectedRoot?.ratioId
                }/map/branches?_DMA_&${getRegionParam(selectedRoot?.region)}&${getListTypeParam(
                    selectedRoot?.listType
                )}`
            );

        if (mapFilters && mapFilters.radiusDMA)
            endpoints.push(
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                    selectedRoot?.ratioId
                }/map/radiusAndDMA?_DMA_&${getRegionParam(selectedRoot?.region)}&${getListTypeParam(
                    selectedRoot?.listType
                )}`
            );

        if (mapFilters && mapFilters.competitors)
            endpoints.push(
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                    selectedRoot?.ratioId
                }/map/competitors?_DMA_&${getRegionParam(selectedRoot?.region)}&${getListTypeParam(
                    selectedRoot?.listType
                )}`
            );

        if (mapFilters && mapFilters.blockGroups)
            endpoints.push(
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                    selectedRoot?.ratioId
                }/map/blockGroupsAndZip?_DMA_&${getRegionParam(selectedRoot?.region)}&${getListTypeParam(
                    selectedRoot?.listType
                )}`
            );

        return {
            enable: true,
            endpoints: endpoints,
        };
    }

    if (path === "MARKET_RECOMMENDATION") {
        const endpoints = [];
        if(selected?.tabRatioId === RATIOS.GROWTH_RATIO_ID){
            endpoints.push(
                    `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                        selected?.ratioId
                    }/recommendation?_DMA_&${getRegionParam(
                        selected?.region
                    )}&${getTabRatioIdParam(
                        selected?.tabRatioId,
                        selected?.branchView,
                        selected?.distance
                    )}&${getBrancyByNameParam(
                        selected?.showBranchByName
                    )}&${getRecommendSortOrderParam(selected?.tabRatioId, sortColumnDetailsRecommendGrowth)}`,
            );
        };

        if(selected?.tabRatioId === RATIOS.EXPANSION_RATIO_ID){
            endpoints.push(
                    `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                        selected?.ratioId
                    }/recommendation?_DMA_&${getRegionParam(
                        selected?.region
                    )}&${getTabRatioIdParam(
                        selected?.tabRatioId,
                        selected?.branchView,
                        selected?.distance
                    )}&${getBrancyByNameParam(
                        selected?.showBranchByName
                    )}&${getRecommendSortOrderParam(selected?.tabRatioId, sortColumnDetailsRecommendExpansion)}`,
            );
        }

        if(selected?.tabRatioId === RATIOS.RATIONALISATION_RATIO_ID){
            endpoints.push(
                    `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/ratioHierarchy/${
                        selected?.ratioId
                    }/recommendation?_DMA_&${getRegionParam(
                        selected?.region
                    )}&${getTabRatioIdParam(
                        selected?.tabRatioId,
                        selected?.branchView,
                        selected?.distance
                    )}&${getBrancyByNameParam(
                        selected?.showBranchByName
                    )}&${getRecommendSortOrderParam(selected?.tabRatioId, sortColumnDetailsRecommendRationalize)}`,
            );
        };
        
        return {
            enable: true,
            endpoints: endpoints
        }
    }

    if (path === "MARKET_QUANTIFY") {
        return {
            enable: true,
            endpoints: [
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/quantification?_DMA_&${getRegionParam(selected?.region)}&${getBrancyByNameParam(
                    selectedRoot?.showBranchByName
                )}&${getListTypeParam(
                    selected?.listType
                )}&${getQuantifySortOrderParam(sortColumnDetailsQuantify?.sortNumber, sortColumnDetailsQuantify?.sortOrder)}`,
            ],
        };
    }

    if (path === "MARKET_EXECUTIVE_SUMMARY_MATRIX") {

        return {
            enable: true,
            endpoints: [
                `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/branchMatrix?_DMA_&listType=${branchMatrixListType ? branchMatrixListType : '' }&${getRegionParam(selected?.region)}&${getBrancyByNameParam(
                    selectedRoot?.showBranchByName
                )}&${getMatrixTableSortOrderParam(sortColumnDetailsMatrix?.sortNumber, sortColumnDetailsMatrix?.sortOrder)}`,
            ],
        };
        
    }

    if (moduleFilters) {

        if(path === "MARKET_DATAREVIEW_MEA_TABLE")
            return {
                enable: true,
                endpoints: [
                    `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/expansion/driverId/${moduleFilters.activeMEATab}?_DMA_&sortColumnNumber=${selectedRoot.sort?.sortColumnNumber}&columnSortOrder=${selectedRoot.sort?.columnSortOrder.toUpperCase()}`,
                ],
            };

        if(path === "MARKET_BRANCH_OPTIMIZATION_TABLE")
            return {
                enable: true,
                endpoints: [
                    `${VITE_API_BASE_URL}/api/v1/ba/products/market/reports/_REPORTID_/branches/_BRANCHID_/brop/rank/${moduleFilters.activeBOTab}?_DMA_&sortColumnNumber=${selectedRoot.sort?.sortColumnNumber}&columnSortOrder=${selectedRoot.sort?.columnSortOrder.toUpperCase()}`,
                ],
            };

    }

    return {
        enable: false,
        endpoints: [],
    };
}
