export const PATHS = {
    CONTACT: "/contact",
    FORGOT_PASSWORD: "/forgotPassword",
    TEMPLATE: "/templateView",
};

export const PATHS_BA = {
    AUTH: "/",
    HOME: "/ba/main",
    FINANCIAL: "/ba/financial/",
    FINANCIAL_RATIOS: "/ba/financial/ratios",
    FINANCIAL_REPORTS: "/ba/financial/reports",
    FINANCIAL_PEERSELECTION: "/ba/financial/peerSelection",
    FINANCIAL_PEERSCOSTUMPEERS: "/ba/financial/peerSelection/customePeers",
    FINANCIAL_PEERSPREVIEW: "/ba/financial/peerSelection/peerPreview",
    FINANCIA_CUSTOMREPORTS: "/ba/financial/customReports",
    MARKET: "/ba/market",
    MARKET_SUMMARY: "/ba/market/summary",
    MARKET_DATAREVIEW: "/ba/market/datareview",
    MARKET_REPORTS: "/ba/market/reports",
    MARKET_QUANTIFY: "/ba/market/quantify",
    MARKET_RECOMMENDATION: "/ba/market/recommendation",
    MARKET_PROSPECTLIST: "/ba/market/prospectlist",
    MARKET_PROSPECTDETAIL: "/ba/market/prospectDetail",
    MARKET_BUDGETING: "/ba/market/budgeting",
    MARKET_CUSTOMDMA: "/ba/market/customdma",
    MARKET_DATAREVIEW_TABLE: "/ba/market/datareview/table",
    MARKET_DATAREVIEW_MAP: "/ba/market/datareview/map",
    MARKET_DATAREVIEW_MEA_TABLE: "/ba/market/datareview/mea/table",
    MARKET_MEA_MAP: "/ba/market/datareview/mea/map",   
    MARKET_BRANCH_OPTIMIZATION_TABLE: "/ba/market/datareview/bo/table",
    MARKET_BO_MAP: "/ba/market/datareview/bo/map",   
    MARKET_EXECUTIVE_SUMMARY_MATRIX: "/ba/market/executive/summary/matrix",
    MARKET_FILE_ENHANCEMENT: "/ba/market/file/enhancement",
    RISK: "/ba/risk",
    PLAY: "/play",
};

// CU Analyst
export const PATHS_CUA = {
    AUTH: "/",
    HOME: "/cua/main",
    FINANCIAL: "/cua/financial/",
    FINANCIAL_RATIOS: "/cua/financial/ratios",
    FINANCIAL_REPORTS: "/cua/financial/reports",
    FINANCIAL_PEERSELECTION: "/cua/financial/peerSelection",
    FINANCIAL_PEERSCOSTUMPEERS: "/cua/financial/peerSelection/customePeers",
    FINANCIAL_PEERSPREVIEW: "/cua/financial/peerSelection/peerPreview",
    FINANCIA_CUSTOMREPORTS: "/cua/financial/customReports",
    MARKET: "/cua/market",
    MARKET_SUMMARY: "/cua/market/summary",
    MARKET_DATAREVIEW: "/cua/market/datareview",
    MARKET_REPORTS: "/cua/market/reports",
    MARKET_QUANTIFY: "/cua/market/quantify",
    MARKET_RECOMMENDATION: "/cua/market/recommendation",
    MARKET_PROSPECTLIST: "/cua/market/prospectlist",
    MARKET_PROSPECTDETAIL: "/cua/market/prospectDetail",
    MARKET_BUDGETING: "/cua/market/budgeting",
    MARKET_CUSTOMDMA: "/cua/market/customdma",
    MARKET_DATAREVIEW_TABLE: "/cua/market/datareview/table",
    MARKET_DATAREVIEW_MAP: "/cua/market/datareview/map",
    MARKET_DATAREVIEW_MEA_TABLE: "/cua/market/datareview/mea/table",    
    MARKET_MEA_MAP: "/cua/market/datareview/mea/map",   
    MARKET_BRANCH_OPTIMIZATION_TABLE: "/cua/market/datareview/bo/table",
    MARKET_BO_MAP: "/cua/market/datareview/bo/map",   
    MARKET_EXECUTIVE_SUMMARY_MATRIX: "/cua/market/executive/summary/matrix",
    MARKET_FILE_ENHANCEMENT: "/cua/market/file/enhancement",
    RISK: "/cua/risk",
};
