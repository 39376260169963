import { createSlice } from '@reduxjs/toolkit';

export const ratiosSlice = createSlice({
  name: 'ratios',
  initialState: {
    ratios: true,
    PeerTableData:[],
    selectedLegends: [],
    RatioFirstRow:{},
    RatioSecondRow:{},
    ratioFirstRowCua:{},
    ratioSecondRowCua:{},
    finalData: []
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setPeerTableData: (state, { payload }) => {
      state.PeerTableData = payload;
    },
    setSelectedLegends: (state, { payload }) => {
      state.selectedLegends = [...payload];
    },
    setRatioFirstRow: (state, { payload }) => {
      state.RatioFirstRow = payload;
    },
    setRatioSecondRow: (state, { payload }) => {
      state.RatioSecondRow = payload;
    },
    setRatioFirstRowCua: (state, { payload }) => {
      state.ratioFirstRowCua = payload;
    },
    setRatioSecondRowCua: (state, { payload }) => {
      state.ratioSecondRowCua = payload;
    },
    setFinalData: (state, {payload}) => {
      state.finalData = payload;
    }
  }
});

export const { increment, decrement, incrementByAmount, setPeerTableData, setSelectedLegends, setRatioFirstRow, setRatioSecondRow, setRatioFirstRowCua, setRatioSecondRowCua, setFinalData } = ratiosSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.counter.value;
export const peerTableData = ({ratios}) => ratios.PeerTableData;
export const selected_Legends = ({ratios}) => ratios.selectedLegends;
export const selected_RatioFirstRow = ({ratios}) => ratios.RatioFirstRow;
export const selected_RatioSecondRow = ({ratios}) => ratios.RatioSecondRow;
export const selected_RatioFirstRowCua = ({ratios}) => ratios.ratioFirstRowCua;
export const selected_RatioSecondRowCua = ({ratios}) => ratios.ratioSecondRowCua;
export const getFinalData = ({ratios}) => ratios.finalData;

export default ratiosSlice.reducer;
