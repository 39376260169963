import { createSlice } from '@reduxjs/toolkit';
import https from 'utils/http';
let sortingValueForPageRefresh = sessionStorage.getItem('sortValue1')

const initialState = {
  performanceDashBoard: {},
  loading: false,
  sortValue: sortingValueForPageRefresh || 'DEFAULT',
  cuaSortValue: sortingValueForPageRefresh || 'DEFAULT'
};
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setPerformanceDashBoard: (state, { payload }) => {
      state.performanceDashBoard = payload;
      state.loading = false;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSortValue: (state, { payload }) => {
      state.sortValue = payload;
    },
    setCUASortValue: (state, { payload }) => {
      state.cuaSortValue = payload;
    }
  }
});

export const { setPerformanceDashBoard, setLoading, setSortValue, setCUASortValue } = dashboardSlice.actions;

export const fetchPerformanceDashBoard = (
  reportId,
  peerGroupId,
  reportingPeriod,
  accountingType,
  sortKey
) => (dispatch, getState) => {
  const { bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/dashboards/performance`,
      {
        params: {
          accountingType,
          reportingPeriod,
          sort:sortKey
        }
      }
    )
    .then(({ data }) => {
      if (data.rows.length) {
        dispatch(setPerformanceDashBoard(data.rows));
      }
    })
    .catch((error) =>{
       dispatch(setLoading(false));
      });
};

export const fetchPerformanceDashBoardSort = (
  reportId,
  peerGroupId,
  reportingPeriod,
  accountingType,
  sortKey
) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { bankAnalyst } = getState().reports;
  return https
    .get(
      `${import.meta.env.VITE_API_BASE_URL}/api/v1/${bankAnalyst}/products/financial/reports/${reportId}/peerGroups/${peerGroupId}/dashboards/performance`,
      {
        params: {
          accountingType,
          reportingPeriod,
          sort:sortKey
        }
      }
    )
    .then(({ data }) => {
      if (data.rows.length) {
        dispatch(setPerformanceDashBoard(data.rows));
      }
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

export const performance_Dashboard = ({ dashboard }) => dashboard.performanceDashBoard;
export const dashboardLoading = ({ dashboard }) => dashboard.loading;
export const sort_value = ({ dashboard }) => dashboard.sortValue;
export const cua_sort_value = ({ dashboard }) => dashboard.cuaSortValue;

export default dashboardSlice.reducer;
