export const getRecommendLoading = (state) =>
    state.market.recommendation?.loading;

export const getRecommendTableHeaders = (state) =>
    state.market.recommendation?.transformed?.headers;

export const getRecommendTableData = (state) =>
    state.market.recommendation?.recommendationData;

export const getResourceFocusList = (state) =>
    state.market.recommendation?.resourceFocusList;

export const getRecommendBranchViewList = (state) =>
    state.market.recommendation?.branchViewList;

export const getDataSortedByHeader = (state) =>
    state.market.recommendation?.dataSortedByHeader;

export const getSortedForFirstTime = (state) =>
    state.market.recommendation?.sortedForFirstTime;

export const getSortColumnDetailsRecommend = (state) =>
    state.market.recommendation?.sortColumnDetailsRecommend;

export const getSortedForFirstTimeRationalize = (state) =>
    state.market.recommendation?.sortedFirstTimeRationalize;

export const getSortedForFirstTimeGrowth = (state) =>
    state.market.recommendation?.sortedFirstTimeGrowth;

export const getSortedForFirstTimeExpansion = (state) =>
    state.market.recommendation?.sortedFirstTimeExpansion;
    
export const getSortColumnDetailsRecommendRationalize = (state) => 
    state.market.recommendation?.sortColumnDetailsRecommendRationalize;

export const getSortColumnDetailsRecommendExpansion = (state) => 
    state.market.recommendation?.sortColumnDetailsRecommendExpansion; 

export const getSortColumnDetailsRecommendGrowth = (state) => 
    state.market.recommendation?.sortColumnDetailsRecommendGrowth; 