/* eslint-disable eqeqeq */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
    setBankAnalyst,
    setReportsListByGlobalSearch,
} from "routes/ReportsRoute/ReportsSlice";

import {
    setReportName,
    setReportPages,
} from "routes/addToReportMenu/addToReportsSlice";
import { useDispatch, useSelector } from "react-redux";
import baLogo from "../../../../assets/icons/logo-BankAnalyst.svg";
import cuaLogo from "../../../../assets/icons/logo-CUAnalyst.svg";
import { PATHS_BA, PATHS_CUA } from "constants/paths";
import generateKey from "utils/generateKey";
import { activeSelectedProduct } from "routes/AuthenticationRoute/AuthenticationSlice";
import { selectedBankAnalyst } from "routes/ReportsRoute/ReportsSlice";
const LogoContainer = styled.div`
  grid-area: HeaderLeft;
  display: flex;
  align-items: center;
  position: relative;
  & ul {
      position: absolute;
      list-style: none;
      background: #fff;
      top:31px;
      padding:0px;
      cursor: pointer;
      & li {
        padding:5px 10px 10px 0px;
      }
  },
`;

const ItemWrapper = styled.div`
    margin: 0 4px;
    display: flex;
    align-items: center;

    .app-title {
        margin: 0;
        padding: 0;
        font-size: ${(p) => p.theme.typography.pxToRem(24)};
        color: ${(p) => p.theme.palette.text.accent.blueDark};
    }

    svg {
        margin-left: -20px;
        fill: ${(p) => p.theme.palette.text.accent.blueDark};
    }
`;

const TabbedLogoWrapper = styled.div`
    display: flex;
    .logo-item {
        position: relative;
        cursor: pointer;
    }
    .active {
        background: #143057;
        height: 6px;
        width: 180px;
        position: absolute;
        left: 0px;
        bottom: -11px;
        transition: 1s;
        text-shadow: 0px -2px 0px rgba(0, 0, 0, 1),
            0 0 5px rgba(255, 255, 255, 0.8),
            0 -4px 15px rgba(255, 255, 255, 0.5);
        transition: margin 0.2s linear;
        &.cua {
            margin-left: 220px;
        }
    }
`;

export default function Logo({ showIcon, ...rest }) {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const preferredProduct = useSelector(activeSelectedProduct);
    const selectedType = useSelector(selectedBankAnalyst);
    const [showMenu, setShowMenu] = useState(false);
    const switchProduct = [
        {
            value: PATHS_BA.HOME,
            name: "ba",
            item: (
                <img src={baLogo} alt="primary-logo" style={{ height: 40 }} />
            ),
        },
        {
            value: PATHS_CUA.HOME,
            item: (
                <img src={cuaLogo} alt="primary-logo" style={{ height: 40 }} />
            ),
            name: "cua",
        },
    ];

    const [selectProduct, setSelectedProduct] = useState("");

    const resetData = {
        reportName: null,
        reportId: null,
        peerGroupId: null,
        ratioTab: null,
    };

    const onChange = (item) => {
        dispatch(setBankAnalyst(item.name));
        //  dispatch(fetchReportsList());
        setSelectedProduct(item);
        setShowMenu(!showMenu);
        history.push(item.value);
        dispatch(setReportsListByGlobalSearch([]));
        dispatch(setReportPages([]));
        dispatch(setReportName(resetData));
    };

    useEffect(() => {
        let productToSet;
        if (selectedType == 0 || selectedType == null) {
            if (preferredProduct === "BANKANALYST") {
                productToSet = switchProduct[0];
            } else {
                productToSet = switchProduct[1];
            }
        } else {
            if (selectedType == "ba") {
                productToSet = switchProduct[0];
            } else {
                productToSet = switchProduct[1];
            }
        }

        setSelectedProduct(productToSet);
        if (pathname === PATHS_BA.HOME || pathname === PATHS_CUA.HOME)
            onChange(productToSet, selectedType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferredProduct]);

    return (
        <LogoContainer {...rest}>
            {pathname === PATHS_BA.HOME || pathname === PATHS_CUA.HOME ? (
                <TabbedLogoWrapper>
                    {switchProduct.map((product, index) => {
                        return (
                            <div
                                style={{ marginRight: "2rem" }}
                                key={generateKey()}
                            >
                                <div
                                    key={generateKey()}
                                    className={"logo-item"}
                                    onClick={() => onChange(product)}
                                >
                                    {product.item}
                                    {product.name === selectProduct.name && (
                                        <div className={"active"} />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    <div className={`active ${selectProduct.name}`} />
                </TabbedLogoWrapper>
            ) : (
                <>
                    {showIcon && (
                        <ItemWrapper>{selectProduct.item}</ItemWrapper>
                    )}
                </>
            )}
        </LogoContainer>
    );
}

Logo.propTypes = {
    showIcon: PropTypes.bool,
    showTitle: PropTypes.bool,
};

Logo.defaultProps = {
    showIcon: true,
    showTitle: false,
};
