import { PATHS_BA, PATHS_CUA, PATHS } from "constants/paths";

export default function redirectTPeersDashboard(location, history) {
   if (location.pathname === PATHS_BA.FINANCIAL_PEERSCOSTUMPEERS || location.pathname === PATHS_BA.FINANCIAL_PEERSPREVIEW) {
                history.push(`${PATHS_BA.FINANCIAL_PEERSELECTION}`);
        }
   if (location.pathname === PATHS_CUA.FINANCIAL_PEERSCOSTUMPEERS || location.pathname === PATHS_CUA.FINANCIAL_PEERSPREVIEW) {
                history.push(`${PATHS_CUA.FINANCIAL_PEERSELECTION}`);
        }
 if (location.pathname === PATHS.TEMPLATE) {
                history.push(`${PATHS_BA.FINANCIA_CUSTOMREPORTS}`);
        }

}