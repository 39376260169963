import toast from "components/@bank-ui/core/Toast/Toast";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import https from "utils/http";
import {
    getMarketCurrentModule,
    getMarketEndpointFilters,
    getMarketReportName,
    getMarketReportPages,
    getMarketSelectedFilters,
} from "./selectors";
const { VITE_API_BASE_URL } = import.meta.env;
const initialState = {
    reportName: null,
    pages: [],
};

const marketAddToReportsSlice = createSlice({
    name: "marketAddToReports",
    initialState,
    reducers: {
        setReportName(state, { payload }) {
            return {
                ...state,
                reportName: payload,
            };
        },
        setReportPages(state, { payload }) {
            return {
                ...state,
                pages: payload,
            };
        },
        resetMarketAddToReports() {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setReportName,
    setReportPages,
    resetMarketAddToReports,
} = marketAddToReportsSlice.actions;

export function addPageToReport({ page }) {
    return async (dispatch, getState) => {
        const pages = getMarketReportPages(getState());
        const distance = getMarketEndpointFilters(getState())?.distance;
        // page.pageName = "Page " + (pages.filter((p) => p.pageName.match(new RegExp(page.pageName))).length + 1) + ". " + page.pageName ;        
        page.pageId = pages.length + 1;
        const pageToModify = page?.endpoints[0];
        if(pageToModify && pageToModify.includes("=distance")){
            const newPageEndpoint = page?.endpoints[0].replace("=distance", `=${distance}`);
            page.endpoints[0] = newPageEndpoint
        }
        dispatch(setReportPages(_.concat(pages, page)));
    };
}

export function saveNewReport() {
    return async (dispatch, getState) => {
        const currentModule = getMarketCurrentModule(getState());
        const reportName = getMarketReportName(getState());
        const pages = getMarketReportPages(getState());
        const { reportGrouping, reportBranches } = getMarketSelectedFilters(
            getState()
        );

        let newPages = pages.map(({ pageName, endpoints })=> {
            return {
                pageName,
                endpoints
            }
        }); 

        const payload = {
            templateName: reportName,
            templateDescription: reportName,
            pageCount: pages.length,
            templateValue: JSON.stringify({
                templateName: reportName,
                pages:newPages,
            }),
        };

        https
            .post(
                `${VITE_API_BASE_URL}/api/v1/${currentModule}/products/market/reports/${reportGrouping}/branches/${reportBranches}/downloadTemplates`,
                payload
            )
            .then((response) => {
                dispatch(resetMarketAddToReports());
                toast.success({
                    header: "Save successful",
                    message: "Template is saved successfully",
                });
            })
            .catch((error) => errorHandler(getErrorMessage(error)));
    };
}

export default marketAddToReportsSlice.reducer;
