import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import Svg from "../Svg";
import { blue } from "@mui/material/colors";
import { getMarketSelectedFilters } from "routes/MarketRoute/selectors";
import { PATHS_BA, PATHS_CUA } from "constants/paths";
import { useSelector } from "react-redux";
import { getAuthenticatedUser } from "routes/AuthenticationRoute/AuthenticationSlice";

const ActiveIndicator = styled.div`
    content: "";
    display: block;
    height: 100%;
    width: 6px;
    background-color: ${(p) => p.theme.palette.accent.blueDark};
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all ease-in-out 250ms;
`;

const VerticalListItem = styled.li`
    display: ${(p) => (p.visibility ? "inline-block" : "none")};
    position: relative;

    margin: 0;
    cursor: pointer;

    .vertical-link-item {
        text-decoration: none;
        color: ${(p) => {
            return !p.active
                ? p.theme.palette.text.accent.disabled
                : p.active === 1
                ? p.theme.palette.text.accent.blueDark
                : p.theme.palette.text.accent.blue;
        }};
        overflow: hidden;
        font-size: 16px;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 16px;

        .icon-container {
            width: 36px;
            svg {
                margin-right: 8px;
                height: 18px;
                width: 18px;
                transition: transform 150ms ease;
                fill: #143057};
                g {
                    fill: #143057
                }
            }
        }

        .label {
            width: 216px;
            white-space: break-spaces;
            word-break: break-word;
        }
    }

    &:hover {
        background: ${blue[50]};

        .icon-container {
            svg {
                fill: ${(p) => p.theme.palette.text.accent.green};
            }
            g {
                fill: ${(p) => p.theme.palette.text.accent.green};
            }
        }
    }

    &:hover > .vertical-link-item {
        color: ${(p) => p.theme.palette.text.accent.green};
    }

    &:hover > .vertical-link-item::before {
        height: 100%;
    }

    &:hover > ${ActiveIndicator} {
        display: none;
    }

    .vertical-link-item::before {
        content: "";
        display: block;
        width: 6px;
        background-color: ${(p) => p.theme.palette.accent.green};
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0%;
        transition: all ease-in-out 250ms;
    }
`;

export default function VerticalNavTab({ itemProps }) {
    const { pathname } = useLocation();
    const { route: { path, label, icon, subNavItems } = {} } = itemProps;
    const {
        report,
        role: { role },
    } = useSelector((state) => ({
        report: getMarketSelectedFilters(state),
        role: getAuthenticatedUser(state),
    }));

    function getLinkStatus() {
        if (
            report.mea &&
            (_.includes(path, PATHS_BA.MARKET_CUSTOMDMA) ||
                _.includes(path, PATHS_CUA.MARKET_CUSTOMDMA))
        )
            return 0;
        return _.includes(pathname, path) ? 1 : 2;
    }

    function getProspectListStatus() {
        let accessList = JSON.parse(
            sessionStorage.getItem("userObject")
        )?.accessList;
        let moduleSpecificList =
            pathname.includes("cua") && accessList
                ? accessList[1].modules.find(
                      (object) => object.shortName === "MARKET"
                  )
                : accessList[0].modules.find(
                      (object) => object.shortName === "MARKET"
                  );

        let found =
            moduleSpecificList &&
            moduleSpecificList?.restrictedFeatures.find(
                (eachFeature) => eachFeature.feature === "ORDER_PROSPECT_LISTS"
            );
        if (
            (_.includes(path, "/cua/market/prospectlist") ||
                _.includes(path, "/ba/market/prospectlist")) &&
            found &&
            found.accessible === false
        )
            return 0;
        return 4;
    }

    return (
        <VerticalListItem
            active={getLinkStatus()}
            visibility={getProspectListStatus()}
        >
            <Link
                className="vertical-link-item"
                to={subNavItems.length > 0 ? subNavItems[0].path : path}
                onClick={(event) => {
                    if (!getLinkStatus()) event.preventDefault();
                }}
            >
                <span className="icon-container">
                    {icon && <Svg SvgComponent={icon} />}
                </span>
                <span className="label">{label}</span>
            </Link>
            {_.includes(pathname, path) && <ActiveIndicator />}
        </VerticalListItem>
    );
}

VerticalNavTab.propTypes = {
    itemProps: PropTypes.shape({
        route: PropTypes.shape({
            id: PropTypes.string,
            path: PropTypes.string,
            label: PropTypes.string,
            icon: PropTypes.object,
            subNavItems: PropTypes.array,
        }),
    }),
};
