export const getMatrixLoading = (state) => 
    state.market.branchMatrix.loading;

export const getMatrixListTypes = (state) =>
    state.market.branchMatrix.listTypes;

export const getMatrixTableHeaders = (state) =>
    state.market.branchMatrix.tableHeaders;

export const getMatrixData = (state) =>
    state.market.branchMatrix.matrixData;

export const getMatrixSelectedListType = (state) => 
    state.market.branchMatrix.selectedListType;

export const getDataSortedByHeader = (state) =>
    state.market.branchMatrix?.dataSortedByHeader;

export const getSortedForFirstTime = (state) =>
    state.market.branchMatrix?.sortedForFirstTime

export const getSortColumnDetailsMatrix = (state) =>
    state.market.branchMatrix?.sortColumnDetailsMatrix
